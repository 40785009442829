import ims from '../../../imports';
import site from './site';

// STATE - default
let initialState = {
  dealerSites: null,
  statusGetDealerSites: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSites: function (state) {
    return state.dealerSites ?? {};
  },
  statusGetDealerSites: function (state) {
    return state.statusGetDealerSites;
  },
};

// ACTIONS
const actions = {
  getDealerSites: async function ({ commit, getters }, { dealerId, fields }) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSites === 'requested') return;
    commit('GET_DEALER_SITES_REQUEST');
    console.debug('GET_DEALER_SITES_REQUEST');
    try {
      const res = await ims.models.getDealerSites({ dealerId, fields });
      commit('GET_DEALER_SITES_SUCCESS', res.data);
      console.debug('GET_DEALER_SITES_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_DEALER_SITES_FAILURE');
      console.debug('GET_DEALER_SITES_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITES_REQUEST: function (state) {
    state.statusGetDealerSites = 'requested';
  },
  GET_DEALER_SITES_SUCCESS: function (state, data) {
    if (!state.dealerSites) state.dealerSites = {};
    data?.forEach((v) => {
      state.dealerSites[v.site_id] = {
        ...v,
        address: v.address1,
      };
    });
    state.statusGetDealerSites = 'successful';
  },
  GET_DEALER_SITES_FAILURE: function (state) {
    state.statusGetDealerSites = 'failed';
  },
};

export default {
  ...site,
  dealerSites: {
    state,
    getters,
    actions,
    mutations,
  },
};
