import ims from '../imports';

export default {
  loadDealer: async function () {
    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // LOAD - data
    const res = await ims.store.dispatch('getDealer', {
      dealerId,
    });

    // DONE
    return res;
  },
};
