import ims from '../../imports';
import dealer from './dealer';
import ip from './ip';
import metadata from './metadata';

export default {
  ...dealer,
  ...ip,
  ...metadata,
  getUserMe: function () {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/users/me`,
    });
  },
};
