import Quasar from 'quasar/src/vue-plugin.js';;
import 'quasar/dist/quasar.sass';
import '../0lib/1quesar_styles/quasar.sass';
import '../0lib/1quesar_styles/chekt-style.sass';
import '../0lib/1quesar_styles/animate.min.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css';
import '@quasar/extras/roboto-font/roboto-font.css';
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';;

console.debug('✅ 2loaders.quasar loaded');

export default ({ app }) => {
  // To be used on app.use(Quasar, { ... })
  app.use(Quasar, {
    config: {},
    plugins: {
      Dialog,
      Notify,
      Loading,
    },
  });
};
