import ims from '../../../../../imports';
import removed_device from './removed_device';

// STATE - default
let initialState = {
  dealerSiteRemovedDevices: null,
  statusGetDealerSiteRemovedDevices: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSiteRemovedDevices: function (state) {
    return state.dealerSiteRemovedDevices ?? {};
  },
  statusGetDealerSiteRemovedDevices: function (state) {
    return state.statusGetDealerSiteRemovedDevices;
  },
};

// ACTIONS
const actions = {
  getDealerSiteRemovedDevices: async function (
    { commit, getters },
    { dealerId, siteId }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSiteRemovedDevices === 'requested') return;
    commit('GET_DEALER_SITE_REMOVED_DEVICES_REQUEST');
    console.debug('GET_DEALER_SITE_REMOVED_DEVICES_REQUEST');
    try {
      const res = await ims.models.getDealerSiteRemovedDevices({
        dealerId,
        siteId,
      });
      commit('GET_DEALER_SITE_REMOVED_DEVICES_SUCCESS', res.data);
      console.debug('GET_DEALER_SITE_REMOVED_DEVICES_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_DEALER_SITE_REMOVED_DEVICES_FAILURE');
      console.debug('GET_DEALER_SITE_REMOVED_DEVICES_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITE_REMOVED_DEVICES_REQUEST: function (state) {
    state.statusGetDealerSiteRemovedDevices = 'requested';
  },
  GET_DEALER_SITE_REMOVED_DEVICES_SUCCESS: function (state, data) {
    state.dealerSiteRemovedDevices = data;
    state.statusGetDealerSiteRemovedDevices = 'successful';
  },
  GET_DEALER_SITE_REMOVED_DEVICES_FAILURE: function (state) {
    state.statusGetDealerSiteRemovedDevices = 'failed';
  },
};

export default {
  ...removed_device,
  dealerSiteRemovedDevices: {
    state,
    getters,
    actions,
    mutations,
  },
};
