export default {
  getQuery: function (key) {
    let value = '';
    const m = window.location.href.split('?');
    if (m.length < 2) return value;
    const mm = m[1].split('&');
    mm.forEach((v) => {
      const mmm = v.split('=');
      if (mmm.length < 2) return;
      if (mmm[0] !== key) return;
      value = mmm[1];
    });
    return value;
  },
};
