<template lang="pug">
div
</template>

<style>
</style>

<script>
import cloud_time from "../2loaders/cloud_time";
import helper from "../4services/2helper";

export default {
  created: function () {
    console.debug("✅ 7subscribers.command_center loaded");
    window.removeEventListener("c-command-center", this.handleCCEvents);
    window.addEventListener("c-command-center", this.handleCCEvents);
  },
  methods: {
    handleCCEvents: function (event) {
      switch (event?.detail?.status) {
        case "connect":
          this.$q.notify({
            message: `Realtime Event Receiver: connected: ${event?.detail?.siteId}`,
            color: "positive",
            icon: "info",
            timeout: 2000,
          });
          return;
        case "offline":
          this.$q.notify({
            message: `Realtime Event Receiver: disconnected: ${event?.detail?.siteId}`,
            color: "negative",
            icon: "warning",
            timeout: 2000,
          });
          return;
        case "error":
          this.$q.notify({
            message: `Realtime Event Receiver: error: ${JSON.stringify(
              event?.detail?.error
            )}: ${event?.detail?.siteId}`,
            color: "negative",
            icon: "warning",
            timeout: 5000,
          });
          return;
        case "message":
          this.onNewCCMessage(event?.detail?.topic, event?.detail?.message);
          return;
        default:
          return;
      }
    },
    onUnsupportedCCMessage: function (description) {
      this.$q.notify({
        message: `Realtime Event Receiver: not supported message of ${description}`,
        color: "warning",
        icon: "warning",
        timeout: 1000,
      });
      return;
    },
    onNewCCMessage: function (topic, message) {
      // e.g.
      // {
      //   "type": "notification",
      //   "subject": "event",
      //   "payload": {
      //     "device_id": "174041",
      //     "event_id": "38953522",
      //     "dealer_id": 2,
      //     "partner_id": 2,
      //     "event_timestamp": 1677843593,
      //     "event_type": "di"
      //   }
      // }

      // INIT
      const obj = JSON.parse(message);
      const messageType = obj?.type;
      const notificationType = obj?.subject;

      // CHECK - This only supports "notification" messages
      switch (messageType) {
        case "notification":
          break;
        default:
          return this.onUnsupportedCCMessage(messageType);
      }

      // CHECK - This only supports "event", "privacy" notification
      switch (notificationType) {
        case "event":
          this.onNewEvent(obj?.payload);
          break;
        case "privacy":
          this.onPrivacyStateChange(obj?.payload);
          break;
        default:
          return this.onUnsupportedCCMessage(notificationType);
      }
    },
    onNewEvent: function (obj) {
      // INIT
      const event_id = obj?.event_id;
      const device_id = obj?.device_id;
      const event_timestamp = obj?.event_timestamp;
      const now_timestamp = cloud_time.newDate().getTime() / 1000;

      // CHECK
      if (!event_id)
        return this.onUnsupportedCCMessage(`event_id: ${event_id}`);
      if (!device_id)
        return this.onUnsupportedCCMessage(`device_id: ${device_id}`);
      if (!event_timestamp)
        return this.onUnsupportedCCMessage(
          `event_timestamp: ${event_timestamp}`
        );

      // CHECK - update only 5 minutes ago event.
      //         bridge sometimes upload very old event,
      //         when there is internet connection issue.
      if (now_timestamp - event_timestamp > 60 * 5) return;

      // LOAD - a new event
      helper.loadEvent({ eventId: event_id });
      console.debug("✅ 7subscribers.command_center: new event", obj);
      this.$store.commit("INCREASE_NEW_EVENT_COUNTER");
    },
    onPrivacyStateChange: function (obj) {
      // INIT
      const device_id = obj?.device_id;
      const device_ids = obj?.device_ids;
      const enable = obj?.enable;

      // CHECK
      if (!device_id && !device_ids)
        return this.onUnsupportedCCMessage(
          `device_id: ${device_id}: device_ids: ${device_ids}`
        );
      if (enable === undefined || enable === null)
        return this.onUnsupportedCCMessage(`enable: ${enable}`);

      // RELOAD - device info
      // if (device_id) return this.loadDevices(device_id);
      // if (device_ids instanceof Array) return this.loadDevices(device_ids[0]);
      helper.loadDevices();
      this.$store.commit("INCREASE_PRIVACY_STATE_CHANGE_COUNTER");
    },
  },
};
</script>
