import ims from '../../../../../../imports';

export default {
  getDealerSiteRemovedDevice: function ({ dealerId, siteId, deviceId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/removed_devices/${deviceId}`,
    });
  },
};
