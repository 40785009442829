<template lang="pug">
q-input(filled, readonly, dense, v-model="date")
  template(v-slot:append)
    q-icon.cursor-pointer(name="event")
      q-popup-proxy(
        cover,
        transition-show="scale",
        transition-hide="scale",
        ref="popup-date"
      )
        q-date(v-model="date", :options="dateOptionsFn")
          .row.items-center.justify-end
            q-btn(@click="setToday", label="Today", color="primary", flat)
            q-btn(v-close-popup, label="Close", color="primary", flat)

Transition(
  appear,
  enter-active-class="animate__animated animate__fadeIn animate__fast"
)
  q-list.bg-black.text-white(bordered, separator)
    q-item.q-py-md(@click="onClickBack", clickable, v-ripple)
      q-item-section(avatar)
        q-icon(name="arrow_back")
      q-item-section
        q-item-label.text-overline SITE {{ siteId }}
        q-item-label.text-body2 {{ site?.name }}
        q-item-label.text-body2 {{ clock }}

q-list(bordered, separator)
  TransitionGroup(
    appear,
    enter-active-class="animate__animated animate__slideInRight animate__fast"
  )
    q-item(
      v-for="(id, i) in dealerSiteEventIds24hSortedDescending",
      :key="id",
      @click="onClickEvent(id)",
      :class="{ 'bg-blue-2': id === eventId }",
      clickable,
      v-ripple
    )
      q-item-section.q-py-sm
        //- event time
        q-item-label.text-subtitle2 {{ ims.tool.getLocaleDateTimeString(dealerSiteEvent24h(id)?.timestamp * 1000, site?.timezone) }}

        //- device name
        q-item-label.q-pt-sm(overline) {{ device(dealerSiteEvent24h(id)?.device_id)?.name || "no_name" }}

        //- tags
        q-item-label.flex.q-pt-sm
          q-badge(color="grey-3")
            q-icon(name="videocam", color="black")
            .text-black.q-ml-xs {{ dealerSiteEvent24h(id)?.device_id }}
          q-badge.q-ml-xs(color="grey-3")
            q-icon(
              name="psychology",
              :color="dealerSiteEvent24h(id)?.videoai_result === 'success' ? 'primary' : 'black'"
            )
            //- CASE 1 - videoAI is enabled
            template(
              v-if="dealerSiteEvent24h(id)?.meta?.videoai?.enabled > 0 || dealerSiteEvent24h(id)?.videoai_result !== ''"
            )
              //- CASE 1-1 - analyzed
              template(v-if="dealerSiteEvent24h(id)?.videoai_provider")
                .text-black.q-ml-xs {{ dealerSiteEvent24h(id)?.videoai_provider }}
              //- CASE 1-2 - still analyzing (let's give 2 minutes)
              template(
                v-else-if="ims.tool.timeSinceInSecond(dealerSiteEvent24h(id)?.timestamp) < 60 * 2"
              )
                .text-black.q-ml-xs.animate__animated.animate__bounce.animate__infinite analyzing...
              //- CASE 1-3 - too long to analyze (maybe failed)
              template(v-else)
                .text-black.q-ml-xs failed
            //- CASE 2 - videoAI is disabled
            template(v-else)
              .text-black.q-ml-xs disabled
          q-badge.q-ml-xs(color="grey-3")
            q-icon(name="checklist", color="black")
            .text-black.q-ml-xs {{ id }}
          q-badge.q-ml-xs(color="grey-3")
            .text-black.q-ml-xs {{ dealerSiteEvent24h(id)?.time_to_create }}s

        //- detected objects
        Transition(enter-active-class="animate__animated animate__bounceIn")
          q-item-label.flex.q-pt-sm(
            v-if="dealerSiteEvent24h(id)?.has_person || dealerSiteEvent24h(id)?.has_vehicle"
          )
            q-badge.text-bold(
              v-if="dealerSiteEvent24h(id)?.has_person",
              color="pink-12"
            )
              q-icon(name="directions_walk")
              .q-ml-xs Person
            q-badge.text-bold(
              v-if="dealerSiteEvent24h(id)?.has_vehicle",
              color="pink-12",
              :class="{ 'q-ml-xs': dealerSiteEvent24h(id)?.has_person }"
            )
              q-icon(name="directions_car")
              .q-ml-xs Vehicle

        //- thumbnails
        Transition(enter-active-class="animate__animated animate__bounceIn")
          q-item-label.flex.q-pt-sm(
            v-if="!!dealerSiteEvent24h(id)?.detection_summary?.person?.thumbnail || !!dealerSiteEvent24h(id)?.detection_summary?.vehicle?.thumbnail"
          )
            c_avatar_101(
              v-if="!!dealerSiteEvent24h(id)?.detection_summary?.person?.thumbnail",
              :src="`data:image/jpeg;base64,${dealerSiteEvent24h(id)?.detection_summary?.person?.thumbnail}`"
            )

            c_avatar_101(
              v-if="!!dealerSiteEvent24h(id)?.detection_summary?.vehicle?.thumbnail",
              :class="{ 'q-ml-xs': !!dealerSiteEvent24h(id)?.detection_summary?.person?.thumbnail }",
              :src="`data:image/jpeg;base64,${dealerSiteEvent24h(id)?.detection_summary?.vehicle?.thumbnail}`"
            )

.flex.flex-center.bg-black.q-pa-sm(v-if="loading")
  q-spinner(color="white", size="2em")
</template>

<style>
/* make it less bouncing */
@keyframes bounce {
  0%,
  20%,
  53%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0) scaleY(1.1);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -4px, 0) scaleY(1.05);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0) scaleY(0.95);
  }
  90% {
    transform: translate3d(0, -1px, 0) scaleY(1.02);
  }
}
</style>

<script>
import c_avatar_101 from "../img/c_avatar_101.vue";
import ims from "../imports";
export default {
  components: { c_avatar_101 },
  props: {
    hitTop: {
      type: Number,
      default: 0,
    },
    hitBottom: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ims: function () {
      return ims;
    },
    userMeDealer: function () {
      return this.$store.getters.userMeDealer;
    },
    dealerSites: function () {
      return this.$store.getters.dealerSites;
    },
    site: function () {
      return this.dealerSites[this.siteId];
    },
    dealerSiteDevices: function () {
      return this.$store.getters.dealerSiteDevices;
    },
    device: function () {
      return function (device_id) {
        return this.dealerSiteDevices[device_id];
      };
    },
    dealerSiteEvents24h: function () {
      return this.$store.getters.dealerSiteEvents24h;
    },
    dealerSiteEvent24h: function () {
      return this.$store.getters.dealerSiteEvent24h;
    },
    dealerSiteEventIds24hSortedDescending: function () {
      return this.$store.getters.dealerSiteEventIds24hSortedDescending;
    },
    newEventCounter: function () {
      return this.$store.getters.newEventCounter;
    },
    statusGetDealerSiteEvents24h: function () {
      return this.$store.getters.statusGetDealerSiteEvents24h;
    },
    loading: function () {
      return (
        this.willLoadData || this.statusGetDealerSiteEvents24h === "requested"
      );
    },
  },
  data: function () {
    return {
      willLoadData: false,
      loadingData$i: null,
      clock: "",
      clock$i: null,
      siteId: "",
      eventId: "",
      date: "",
    };
  },
  watch: {
    userMeDealer: function () {
      this.init();
    },
    "$route.query": function () {
      this.handleQuery();
    },
    date: function () {
      if (!this.date) return;
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, date: this.date },
      });
      this.startLoadingData();
    },
    hitTop: function () {
      this.loadData(false);
    },
    hitBottom: function () {
      this.willLoadData = true;
      setTimeout(() => {
        this.loadData(true);
        this.willLoadData = false;
      }, 1000 * 0.5);
    },
    siteId: function () {
      if (!this.siteId) return;
      ims.helper.connectCommandCenter(this.siteId);
    },
    newEventCounter: function () {
      if (this.date !== this.today()) return;
      this.loadData(false);
      setTimeout(() => this.loadData(false), 1000 * 15);
      setTimeout(() => this.loadData(false), 1000 * 30);
    },
  },
  mounted: function () {
    this.init();
    this.startClock();
  },
  beforeUnmount: function () {
    this.stopLoadingData();
    this.stopClock();
    ims.helper.disconnectCommandCenter();
  },
  methods: {
    init: async function () {
      this.handleQuery();
      await ims.helper.loadSite(this.siteId);
      await ims.helper.loadDevices(this.siteId);
    },
    //
    setToday: function () {
      this.date = this.today();
    },
    today: function () {
      return ims.tool.todayLocal(this.site?.timezone);
    },
    dateOptionsFn: function (date) {
      return date <= this.today();
    },
    closeDatePopup: function () {
      const ref = this.$refs["popup-date"];
      if (!ref) return;
      ref.hide();
    },
    //
    onClickBack: function () {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          date: this.date,
          site: undefined,
          event: undefined,
        },
      });
    },
    onClickEvent: function (eventId) {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, event: eventId },
      });
    },
    handleQuery: function () {
      this.siteId = this.$route.query["site"];
      this.eventId = this.$route.query["event"];
      this.date = this.$route.query["date"] || this.today();
    },
    //
    startClock: function () {
      this.stopClock();
      this.updateClock();
      this.clock$i = setInterval(() => this.updateClock(), 1000 * 1);
    },
    stopClock: function () {
      if (!this.clock$i) return;
      clearInterval(this.clock$i);
      this.clock$i = null;
    },
    updateClock: function () {
      const now = ims.cloud_time.newDate().getTime();
      const timezone = this.site?.timezone;
      this.clock = ims.tool.getLocaleDateTimeString(now, timezone);
    },
    // startLoadingData only when date changes
    startLoadingData: function () {
      this.stopLoadingData();
      this.loadData(false);
      this.loadingData$i = setInterval(() => {
        if (this.date !== this.today()) return;
        this.loadData(false);
      }, 1000 * 60 * 1);
    },
    stopLoadingData: function () {
      if (!this.loadingData$i) return;
      clearInterval(this.loadingData$i);
      this.loadingData$i = null;
    },
    loadData: function (getOlder) {
      if (!this.siteId) return;
      if (!this.date) return;
      ims.helper.loadSiteEvents24h({
        siteId: this.siteId,
        date: this.date,
        getOlder,
      });
    },
  },
};
</script>
