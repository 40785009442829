import ims from '../../../../../imports';
import event from './event';

export default {
  ...event,
  getDealerSiteEvents: function ({ dealerId, siteId, stime, etime, params }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/events/all/stime/${stime}/etime/${etime}`,
      params: {
        ...params,
        // expand: 'signedurl',
      },
    });
  },
  getDealerSiteEvents24h: function ({ dealerId, siteId, stime }) {
    const etime = Number(stime) + 60 * 60 * 24;
    return this.getDealerSiteEvents({ dealerId, siteId, stime, etime });
  },
  getDealerSiteEventsRecent: function ({
    dealerId,
    siteId,
    secondsBefore,
    lastEventId,
    eventTypes,
    eventStatus,
  }) {
    const now = Math.floor(ims.cloud_time.newDate().getTime() / 1000);
    const stime = now - secondsBefore;
    const etime = now + 60 * 60 * 1; // +1 hour
    const filter = [];
    if (eventTypes instanceof Array) {
      eventTypes.forEach((v) => filter.push(`device_event.type,${v},eq`));
    }
    if (eventStatus) filter.push(`device_event.status,${eventStatus},eq`);
    if (lastEventId) filter.push(`device_event.event_id,${lastEventId},gt`);
    const params = {
      limit: 25,
      dir: 'desc',
      sort: 'event_id',
      filter,
      init: 'yes',
    };
    return this.getDealerSiteEvents({ dealerId, siteId, stime, etime, params });
  },
};
