import ims from '../imports';

let prevSiteId = '';
let prevCustomerAccountNumber = '';
let prevAccountReferenceId = '';
let prevReceiverLinePrefix = '';

export default {
  loadSites: async function () {
    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // LOAD - data
    const fields = [
      'site.site_id',
      'site.dealer_id',
      'site.partner_id',
      'site.name',
      'site.timezone',
      'site.address1',
    ].join(',');
    const res = await ims.store.dispatch('getDealerSites', {
      dealerId,
      fields,
    });

    // DONE
    return res;
  },
  loadSite: async function (siteId) {
    // KEEP - prevSiteId for next round call (handy)
    if (siteId) prevSiteId = siteId;

    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // GET & CHECK - siteId - best effort
    if (!siteId) siteId = ims.store.getters.dealerSite?.site_id;
    if (!siteId) siteId = prevSiteId;
    if (!siteId) return;

    // LOAD - data
    const res = await ims.store.dispatch('getDealerSite', { dealerId, siteId });

    // UPDATE - for better debugging
    window.chekt_site_id = res?.site_id;

    // DONE
    return res;
  },
  loadAccount: async function ({
    customerAccountNumber,
    accountReferenceId,
    receiverLinePrefix,
  }) {
    // KEEP - prevIds for next round call (handy)
    if (customerAccountNumber)
      prevCustomerAccountNumber = customerAccountNumber;
    if (accountReferenceId) prevAccountReferenceId = accountReferenceId;
    if (receiverLinePrefix) prevReceiverLinePrefix = receiverLinePrefix;

    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // GET - ids - best effort
    if (!customerAccountNumber)
      customerAccountNumber = prevCustomerAccountNumber;
    if (!accountReferenceId) accountReferenceId = prevAccountReferenceId;
    if (!receiverLinePrefix) receiverLinePrefix = prevReceiverLinePrefix;

    // LOAD - data - by alternative method - best effort
    if (!customerAccountNumber && !accountReferenceId && !receiverLinePrefix) {
      return this.loadSite();
    }

    // LOAD - data
    const res = await ims.store.dispatch('getDealerAccount', {
      dealerId,
      customerAccountNumber,
      accountReferenceId,
      receiverLinePrefix,
    });

    // UPDATE - for better debugging
    window.chekt_site_id = res?.site_id;

    // DONE
    return res;
  },
};
