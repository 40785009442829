import ims from '../../../../../../../imports';

// STATE - default
let initialState = {
  statisticsVideoaiPartnerSiteDevices: null,
  statusGetStatisticsVideoaiPartnerSiteDevices: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  statisticsVideoaiPartnerSiteDevices: function (state) {
    return state.statisticsVideoaiPartnerSiteDevices ?? {};
  },
  statusGetStatisticsVideoaiPartnerSiteDevices: function (state) {
    return state.statusGetStatisticsVideoaiPartnerSiteDevices;
  },
};

// ACTIONS
const actions = {
  getStatisticsVideoaiPartnerSiteDevices: async function (
    { commit, getters },
    { partnerId, siteId, stat, tztype, interval, time, limit }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetStatisticsVideoaiPartnerSiteDevices === 'requested')
      return;
    commit('GET_STATISTICS_VIDEOAI_PARTNER_SITE_DEVICES_REQUEST');
    console.debug('GET_STATISTICS_VIDEOAI_PARTNER_SITE_DEVICES_REQUEST');
    try {
      const res = await ims.models.getStatisticsVideoaiPartnerSiteDevices({
        partnerId,
        siteId,
        stat,
        tztype,
        interval,
        time,
        limit,
      });
      commit('GET_STATISTICS_VIDEOAI_PARTNER_SITE_DEVICES_SUCCESS', res.data);
      console.debug('GET_STATISTICS_VIDEOAI_PARTNER_SITE_DEVICES_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_STATISTICS_VIDEOAI_PARTNER_SITE_DEVICES_FAILURE');
      console.debug('GET_STATISTICS_VIDEOAI_PARTNER_SITE_DEVICES_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_STATISTICS_VIDEOAI_PARTNER_SITE_DEVICES_REQUEST: function (state) {
    state.statusGetStatisticsVideoaiPartnerSiteDevices = 'requested';
  },
  GET_STATISTICS_VIDEOAI_PARTNER_SITE_DEVICES_SUCCESS: function (state, data) {
    state.statisticsVideoaiPartnerSiteDevices = data?.data;
    state.statusGetStatisticsVideoaiPartnerSiteDevices = 'successful';
  },
  GET_STATISTICS_VIDEOAI_PARTNER_SITE_DEVICES_FAILURE: function (state) {
    state.statusGetStatisticsVideoaiPartnerSiteDevices = 'failed';
  },
};

export default {
  statisticsVideoaiPartnerSiteDevices: {
    state,
    getters,
    actions,
    mutations,
  },
};
