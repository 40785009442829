import ims from '../../../imports';
import site from './site';

export default {
  ...site,
  getDealerSites: function ({ dealerId, fields }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites`,
      params: ims.tool.removeEmptyKey({
        fields,
      }),
    });
  },
};
