<template lang="pug">
div
</template>

<style>
</style>

<script>
export default {
  created: function () {
    console.debug("✅ 7subscribers.errors loaded");
    //
    window.removeEventListener("unhandledrejection", this.handleUR);
    window.addEventListener("unhandledrejection", this.handleUR);
    window.removeEventListener("error", this.handleUE);
    window.addEventListener("error", this.handleUE);
  },
  methods: {
    handleUR: function (event) {
      const error = event.reason;
      this.$q.notify({
        message: `UNHANDLED PROMISE REJECTION: ${error}`,
        color: "warning",
        icon: "warning",
      });
      // DO NOT DO THIS HERE - it is taken care by "2loaders.logger"
      // console.error("UNHANDLED PROMISE REJECTION", { error });
    },
    handleUE: function (event) {
      const error = event.error;
      this.$q.notify({
        message: `UNCAUGHT EXCEPTION: ${event.type}: ${error?.message}: ${error?.stack}`,
        multiLine: true,
        color: "negative",
        icon: "warning",
      });
      // DO NOT DO THIS HERE - it is taken care by "2loaders.logger"
      // console.error(`🔥 UNCAUGHT EXCEPTION`, { error });
    },
  },
};
</script>
