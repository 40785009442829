import ims from '../../../../../imports';

// STATE - default
let initialState = {
  statisticsVideoaiPartnerDealers: null,
  statisticsVideoaiPartnerDealersByStat: null,
  statusGetStatisticsVideoaiPartnerDealers: '',
  statusGetStatisticsVideoaiPartnerDealersByStat: null,
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  statisticsVideoaiPartnerDealers: function (state) {
    return state.statisticsVideoaiPartnerDealers ?? {};
  },
  statisticsVideoaiPartnerDealersByStat: function (state) {
    return state.statisticsVideoaiPartnerDealersByStat ?? {};
  },
  statisticsVideoaiPartnerDealersRequest: function (state, getters) {
    return getters.statisticsVideoaiPartnerDealersByStat['request'];
  },
  statisticsVideoaiPartnerDealersFiltered: function (state, getters) {
    return getters.statisticsVideoaiPartnerDealersByStat['filtered'];
  },
  statusGetStatisticsVideoaiPartnerDealers: function (state) {
    return state.statusGetStatisticsVideoaiPartnerDealers;
  },
  statusGetStatisticsVideoaiPartnerDealersByStat: function (state) {
    return function (stat) {
      if (!state.statusGetStatisticsVideoaiPartnerDealersByStat) return '';
      return state.statusGetStatisticsVideoaiPartnerDealersByStat[stat];
    };
  },
};

// ACTIONS
const actions = {
  getStatisticsVideoaiPartnerDealers: async function (
    { commit, getters },
    { partnerId, stat, tztype, interval, time, limit }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (
      getters.statusGetStatisticsVideoaiPartnerDealersByStat(stat) ===
      'requested'
    )
      return;
    commit('GET_STATISTICS_VIDEOAI_PARTNER_DEALERS_REQUEST', { stat });
    console.debug('GET_STATISTICS_VIDEOAI_PARTNER_DEALERS_REQUEST');
    try {
      const res = await ims.models.getStatisticsVideoaiPartnerDealers({
        partnerId,
        stat,
        tztype,
        interval,
        time,
        limit,
      });
      commit('GET_STATISTICS_VIDEOAI_PARTNER_DEALERS_SUCCESS', {
        data: res.data,
        stat,
      });
      console.debug('GET_STATISTICS_VIDEOAI_PARTNER_DEALERS_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_STATISTICS_VIDEOAI_PARTNER_DEALERS_FAILURE', { stat });
      console.debug('GET_STATISTICS_VIDEOAI_PARTNER_DEALERS_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_STATISTICS_VIDEOAI_PARTNER_DEALERS_REQUEST: function (state, { stat }) {
    if (!state.statusGetStatisticsVideoaiPartnerDealersByStat) {
      state.statusGetStatisticsVideoaiPartnerDealersByStat = {};
    }
    state.statusGetStatisticsVideoaiPartnerDealersByStat[stat] = 'requested';
    state.statusGetStatisticsVideoaiPartnerDealers = 'requested';
  },
  GET_STATISTICS_VIDEOAI_PARTNER_DEALERS_SUCCESS: function (
    state,
    { data, stat }
  ) {
    // UPDATE - statisticsVideoaiPartnerDealers
    state.statisticsVideoaiPartnerDealers = data?.data;
    // UPDATE - statisticsVideoaiPartnerDealersByStat
    if (!state.statisticsVideoaiPartnerDealersByStat) {
      state.statisticsVideoaiPartnerDealersByStat = {};
    }
    state.statisticsVideoaiPartnerDealersByStat[stat] = data?.data;
    // UPDATE - status
    state.statusGetStatisticsVideoaiPartnerDealersByStat[stat] = 'successful';
    state.statusGetStatisticsVideoaiPartnerDealers = 'successful';
  },
  GET_STATISTICS_VIDEOAI_PARTNER_DEALERS_FAILURE: function (state, { stat }) {
    state.statusGetStatisticsVideoaiPartnerDealersByStat[stat] = 'failed';
    state.statusGetStatisticsVideoaiPartnerDealers = 'failed';
  },
};

export default {
  statisticsVideoaiPartnerDealers: {
    state,
    getters,
    actions,
    mutations,
  },
};
