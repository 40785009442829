import ims from '../../../../../imports';
import verification from './verification';

export default {
  ...verification,
  getDealerSiteVerifications: function ({ dealerId, siteId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/verifications`,
      params: {
        limit: 20,
        dir: 'desc',
        sort: 'verification_id',
      },
    });
  },
};
