const localConfigKey = 'local_config';
const localConfig = JSON.parse(localStorage.getItem(localConfigKey));

export default {
  localConfig: localConfig || {},
  updateLocalConfig: function (key, value) {
    let lc = JSON.parse(localStorage.getItem(localConfigKey));
    if (!lc) lc = {};
    lc[key] = value;
    localStorage.setItem(localConfigKey, JSON.stringify(lc));
    this.localConfig = lc;
  },
};
