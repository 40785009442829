import ims from '../../../../../../../../../imports';

export default {
  createDealerSiteDeviceEventPsap: function ({
    dealerId,
    siteId,
    deviceId,
    eventId,
    timestamp,
    shareType,
    setDownloadCount,
  }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'post',
      url: `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/events/${eventId}/psaps`,
      data: ims.tool.removeEmptyKey({
        timestamp,
        share_type: shareType,
        set_download_count: setDownloadCount,
      }),
    });
  },
};
