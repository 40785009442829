import ims from '../../imports';

export default {
  findUser: function ({ email }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/users/find`,
      params: ims.tool.removeEmptyKey({
        // encodeURIComponent(email),
        email,
      }),
    });
  },
};
