import ims from '../imports';

export default {
  loadTTSs: function () {
    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // GET & CHECK - siteId - best effort
    let siteId = ims.store.getters.dealerSite?.site_id;
    if (!siteId) siteId = ims.store.getters.dealerAccounts?.site_id;
    if (!siteId) return;

    // LOAD - data
    return ims.store.dispatch('getDealerSiteTTSs', { dealerId, siteId });
  },
};
