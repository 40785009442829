<template lang="pug">
q-input(filled, readonly, dense, v-model="date")
  template(v-slot:append)
    q-icon.cursor-pointer(name="event")
      q-popup-proxy(
        cover,
        transition-show="scale",
        transition-hide="scale",
        ref="popup-date"
      )
        q-date(v-model="date", :options="dateOptionsFn")
          .row.items-center.justify-end
            q-btn(@click="setToday", label="Today", color="primary", flat)
            q-btn(v-close-popup, label="Close", color="primary", flat)

Transition(
  appear,
  enter-active-class="animate__animated animate__fadeIn animate__fast"
)
  q-list.bg-black.text-white(bordered, separator)
    q-item.q-py-md(v-ripple)
      q-item-section(avatar)
        q-icon(name="business")
      q-item-section
        q-item-label.text-overline DEALER {{ this.userMeDealer?.dealer_id }}
        q-item-label.text-body2 {{ userMeDealer?.company_name }}

q-list(bordered, separator)
  TransitionGroup(
    appear,
    enter-active-class="animate__animated animate__slideInRight animate__fast"
  )
    q-item(
      v-for="(v, k) in statisticsVideoaiSitesRequest",
      :key="k",
      clickable,
      v-ripple,
      @click="onClickSite(k)"
    )
      q-item-section.q-py-sm
        q-item-label.text-subtitle2 {{ dealerSite(k)?.name }}

        q-item-label.q-pb-sm(caption) {{ dealerSite(k)?.address }}

        q-item-label.bg-pink-1(
          style="height: 10px; outline: 1px solid #333; position: relative",
          :style="{ width: v / 5 + 'px' }"
        )
          .bg-pink-12(
            style="height: 10px; position: absolute",
            :style="{ width: getFilteredStat(k) / 5 + 'px' }"
          )
          .q-ml-xs(
            style="font-size: 9px; position: absolute; top: -3px; right: -4px; transform: translateX(100%)"
          ) {{ getFilteredStat(k) }}/{{ v }}

        q-item-label.q-pt-sm
          q-badge(color="grey-3")
            q-icon(name="gite", color="black")
            .text-black.q-ml-xs {{ k }}

          q-badge.q-ml-xs(color="grey-3")
            q-icon(name="public", color="black")
            .text-black.q-ml-xs {{ dealerSite(k)?.timezone }}

          q-badge.q-ml-xs(color="grey-3")
            q-icon(name="schedule", color="black")
            .text-black.q-ml-xs {{ ims.tool.getTimezoneOffsetStr(dealerSite(k)?.timezone) }}
</template>

<style>
</style>

<script>
import ims from "../imports";
export default {
  computed: {
    ims: function () {
      return ims;
    },
    userMeDealer: function () {
      return this.$store.getters.userMeDealer;
    },
    dealer: function () {
      return this.$store.getters.dealer;
    },
    dealerSite: function () {
      return function (site_id) {
        return this.dealerSites[site_id];
      };
    },
    dealerSites: function () {
      return this.$store.getters.dealerSites;
    },
    statisticsVideoaiDealerSitesRequest: function () {
      return this.$store.getters.statisticsVideoaiDealerSitesRequest;
    },
    statisticsVideoaiDealerSitesFiltered: function () {
      return this.$store.getters.statisticsVideoaiDealerSitesFiltered;
    },
    statisticsVideoaiPartnerSitesRequest: function () {
      return this.$store.getters.statisticsVideoaiPartnerSitesRequest;
    },
    statisticsVideoaiPartnerSitesFiltered: function () {
      return this.$store.getters.statisticsVideoaiPartnerSitesFiltered;
    },
    statisticsVideoaiSitesRequest: function () {
      return {
        ...this.statisticsVideoaiDealerSitesRequest,
        ...this.statisticsVideoaiPartnerSitesRequest,
      };
    },
    statisticsVideoaiSitesFiltered: function () {
      return {
        ...this.statisticsVideoaiDealerSitesFiltered,
        ...this.statisticsVideoaiPartnerSitesFiltered,
      };
    },
  },
  data: function () {
    return {
      loadingStatistics$i: null,
      date: "",
    };
  },
  watch: {
    userMeDealer: function () {
      this.init();
    },
    date: function () {
      if (!this.date) return;
      this.startLoadingStatistics();
    },
  },
  mounted: function () {
    this.init();
  },
  beforeUnmount: function () {
    this.stopLoadingStatistics();
  },
  methods: {
    init: async function () {
      this.handleQuery();
      await ims.helper.loadDealer();
      await ims.helper.loadSites();
      this.startLoadingStatistics();
    },
    //
    getFilteredStat: function (site_id) {
      return this.statisticsVideoaiSitesFiltered?.[site_id] || 0;
    },
    //
    setToday: function () {
      this.date = this.today();
    },
    today: function () {
      return ims.tool.todayMyLocal();
    },
    dateOptionsFn: function (date) {
      return date <= this.today();
    },
    closeDatePopup: function () {
      const ref = this.$refs["popup-date"];
      if (!ref) return;
      ref.hide();
    },
    //
    onClickSite: function (siteId) {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, site: siteId, date: this.date },
      });
    },
    handleQuery: function () {
      this.date = this.$route.query["date"] || this.today();
    },
    //
    startLoadingStatistics: function () {
      this.stopLoadingStatistics();
      this.loadData();
      this.loadingStatistics$i = setInterval(() => {
        if (this.date !== this.today()) return;
        this.loadData();
      }, 1000 * 30);
    },
    stopLoadingStatistics: function () {
      if (!this.loadingStatistics$i) return;
      clearInterval(this.loadingStatistics$i);
      this.loadingStatistics$i = null;
    },
    loadData: function () {
      ims.helper.loadStatisticsVideoaiDealerSites("request", this.date);
      ims.helper.loadStatisticsVideoaiDealerSites("filtered", this.date);

      // CASE 1 - SI, CS, WCS.s
      if (this.dealer?.type !== 2) return;

      // CASE 2 - WCS.m dealer - type is 2
      ims.helper.loadStatisticsVideoaiPartnerSites("request", this.date);
      ims.helper.loadStatisticsVideoaiPartnerSites("filtered", this.date);
    },
  },
};
</script>
