import ims from '../../../../../../imports';

export default {
  getDealerSiteTTS: function ({ dealerId, siteId, ttsId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/ttss/${ttsId}`,
    });
  },
};
