import ims from '../imports';

export default {
  loadUser: async function (tsSessionDealerId) {
    // LOAD - data
    await ims.store.dispatch('getUserMeIp');
    const res = await ims.store.dispatch('getUserMeDealer', {
      tsSessionDealerId,
    });

    // UPDATE - for better debugging
    window.chekt_dealer_id = res?.dealer_id;
    window.chekt_user_id = res?.user_id;
    window.chekt_email = res?.email;

    // DONE
    return res;
  },
};
