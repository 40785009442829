<template lang="pug">
q-btn.c-btn(
  :class="{ 'c-btn-active': active }",
  stack,
  unelevated,
  size="sm",
  padding="xs",
  :ripple="false",
  style="width: 68px; height: 50px"
)
</template>

<style scoped>
.c-btn {
  background-color: hsla(202, 0%, 30%, 1);
  color: white;
}
.c-btn-active {
  background-color: hsla(202, 100%, 70%, 1);
  color: hsla(193, 100%, 0%, 0.7);
}
</style>

<script>
export default {
  props: {
    active: Boolean,
  },
};
</script>
