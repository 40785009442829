import dialogLoader from './dialog';
import btn_101 from './btn/btn_101.vue';
import c_event_video_101 from './event_video/c_event_video_101.vue';
import c_list_dealer_sites_videoai from './list/c_list_dealer_sites_videoai.vue';
import c_list_site_events_videoai from './list/c_list_site_events_videoai.vue';
import tab_101 from './tab/tab_101.vue';

export default {
  install: function (app) {
    dialogLoader({ app });
    // REGISTER - Components
    app.component('c-btn-101', btn_101);
    app.component('c_event_video_101', c_event_video_101);
    app.component('c_list_dealer_sites_videoai', c_list_dealer_sites_videoai);
    app.component('c_list_site_events_videoai', c_list_site_events_videoai);
    app.component('c-tab-101', tab_101);
  },
};
