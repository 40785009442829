<template lang="pug">
q-tab.c-tab(:ripple="false")
</template>

<style>
.c-tab {
  background-color: hsla(202, 0%, 30%, 0.1);
  color: hsla(193, 100%, 0%, 0.7);
  width: 60px;
  height: 50px;
}
.c-tab-active {
  background-color: hsla(202, 100%, 70%, 1);
  color: hsla(193, 100%, 0%, 0.7);
}
.q-tab__icon {
  width: 1em;
  height: 1em;
  font-size: 1.2em;
}
.q-tab__label {
  font-size: 10px;
}
</style>

<script>
export default {};
</script>
