<template lang="pug">
svg.absolute(
  viewBox="0 0 16 9",
  style="width: 100%; height: 100%; top: 0; left: 0"
)
  defs
    filter#solid(x="0", y="0", width="1", height="1")
      feFlood(flood-color="#ffc107")
      feComposite(in="SourceGraphic", operator="xor")

  //- bounding boxes
  g(v-for="(v, i) in boundingBoxes")
    polygon(
      v-if="v.captured_in_area_filters",
      :points="v.points",
      style="stroke: #ff4081; stroke-width: 0.03; fill: rgba(255, 64, 129, 0.2)"
    )
    polygon(
      v-else,
      :points="v.points",
      style="stroke: #21ba45; stroke-width: 0.03; fill: rgba(33, 186, 69, 0.2)"
    )

  //- labels
  g(v-for="(v, i) in boundingBoxes")
    //- text background
    text(:x="v.text.x", :y="v.text.y", filter="url(#solid)", font-size="0.26") {{ v.text.value }}
    //- text
    text(:x="v.text.x", :y="v.text.y", fill="black", font-size="0.26") {{ v.text.value }}
</template>

<style>
</style>

<script>
export default {
  components: {},
  props: {
    eventId: {
      type: String,
      default: "",
    },
    currentFrame: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    annotation: function () {
      return this.$store.getters.dealerSiteEventAnnotation(this.eventId);
    },
  },
  data: function () {
    return {
      boundingBoxes: [],
    };
  },
  watch: {
    annotation: function () {
      this.updateBoundingBoxes();
    },
    currentFrame: function () {
      this.updateBoundingBoxes();
    },
  },
  mounted: function () {
    this.updateBoundingBoxes();
  },
  methods: {
    updateBoundingBoxes: function () {
      this.boundingBoxes = [];
      const frames_detections = this.annotation?.frames_detections;
      const frame_detections = frames_detections?.[String(this.currentFrame)];
      if (!frame_detections) return;
      for (let i = 0; i < frame_detections.length; i++) {
        const v = frame_detections[i];
        const captured_in_area_filters = !!v.thumbnail;
        if (!captured_in_area_filters) continue;
        const points = [
          `${v.top_x * 16},${v.top_y * 9}`,
          `${v.bottom_x * 16},${v.top_y * 9}`,
          `${v.bottom_x * 16},${v.bottom_y * 9}`,
          `${v.top_x * 16},${v.bottom_y * 9}`,
        ];
        const top_x = v.top_x * 16;
        const top_y = v.top_y * 9;
        this.boundingBoxes.push({
          detectionIndex: i,
          frameIndex: this.currentFrame,
          captured_in_area_filters: !!v.thumbnail,
          points: points.join(" "),
          text: {
            x: top_x,
            y: top_y < 0 ? top_y + 0.26 : top_y - 0.1,
            value: `${v.entity_name} (${(v.confidence * 100 * 1.0).toFixed(
              0
            )}%)`,
          },
        });
      }
    },
  },
};
</script>
