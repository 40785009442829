<template lang="pug">
.bg-black.rounded-borders.no-scroll.flex.flex-center(
  style="width: 48px; height: 48px"
)
  img(:src="src", style="height: 100%")
</template>

<style>
</style>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
  },
};
</script>
