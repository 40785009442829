import command_center from './command_center';
import contact from './contact';
import dealer from './dealer';
import device from './device';
import event from './event';
import event_annotation from './event_annotation';
import event_record from './event_record';
import site from './site';
import statistics from './statistics';
import tts from './tts';
import url from './url';
import user from './user';

console.debug('✅ 4services.2helper loaded');

export default {
  ...command_center,
  ...contact,
  ...dealer,
  ...device,
  ...event,
  ...event_annotation,
  ...event_record,
  ...site,
  ...statistics,
  ...tts,
  ...url,
  ...user,
};
