<template lang="pug">
.flex.row.no-wrap.scroll(style="width: 100%; height: 85px")
  .flex.column.flex-center.no-wrap.bg-black.text-white(
    v-for="(v, i) in annotation?.frames_detections?.[String(currentFrame)]"
  )
    //- CASE 1 - privacy mode
    template(v-if="event?.is_privacy > 0")
      template(v-if="v.entity_id === 'person'")
        c_avatar_102(size="50px", :src="ims.tool.imagePlaceHolderPerson({})")
      template(v-else-if="v.entity_id === 'vehicle'")
        c_avatar_102(size="50px", :src="ims.tool.imagePlaceHolderVehicle({})")
      template(v-else)
        c_avatar_102(size="50px", :src="ims.tool.imagePlaceHolder({})")
      .text-caption.text-center(style="width: 100%") {{ (v.confidence * 100 * 1.0).toFixed(0) }}%

    //- CASE 2 - NOT privacy mode
    template(v-else)
      //- ONLY - when there is thumbnail
      template(v-if="v.thumbnail")
        c_avatar_102(
          size="50px",
          :src="`data:image/jpeg;base64,${v.thumbnail}`"
        )
        .text-caption.text-center(style="width: 100%") {{ (v.confidence * 100 * 1.0).toFixed(0) }}%
</template>

<style>
</style>

<script>
import ims from "../../imports";
import c_avatar_102 from "../../img/c_avatar_102.vue";
export default {
  components: {
    c_avatar_102,
  },
  props: {
    eventId: {
      type: String,
      default: "",
    },
    currentFrame: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    ims: function () {
      return ims;
    },
    annotation: function () {
      return this.$store.getters.dealerSiteEventAnnotation(this.eventId);
    },
    event: function () {
      return this.$store.getters.dealerSiteEvent24h(this.eventId);
    },
  },
};
</script>
