import auth0js from 'auth0-js';
import tool from '../0lib/0tool';
import config from '../1config';

// /////////////////////////////////
// INIT - vars
const tokenKey = 'chekt::id_token';
// /////////////////////////////////

// /////////////////////////////////
// INIT - auth0
const auth0 = new auth0js.WebAuth({
  domain: config.auth0_auth.domain,
  clientID: config.auth0_auth.client_id,
  redirectUri: window.origin + '/callback' + window.location.search,
  responseType: 'token id_token',
  scope: 'openid',
});
const login = function () {
  return auth0.authorize({ login_hint: undefined });
};
const logout = function () {
  removeToken();
  return auth0.logout({
    returnTo: window.location.origin + window.location.search,
    clientID: config.auth0_auth.client_id,
  });
};
const checkSession = function () {
  return new Promise((resolve, reject) => {
    auth0.checkSession({}, (err, authResult) => {
      if (err) return reject(err);
      resolve(authResult);
    });
  });
};
const parseHash = function () {
  if (!window.location.pathname.includes('/callback')) return;
  return new Promise((resolve, reject) => {
    auth0.parseHash(
      {
        __enableIdPInitiatedLogin: true,
      },
      (err, authResult) => {
        if (err) return reject(err);
        resolve(authResult);
      }
    );
  });
};
// /////////////////////////////////

// /////////////////////////////////
// INIT - authObj
const authObj = {
  idToken: '',
  idTokenExpiresAt: 0,
  isAuthenticated: false,
  isIdTokenExpired: function () {
    const now = new Date().getTime();
    return authObj.idTokenExpiresAt < now;
  },
  reset: function () {
    this.idToken = '';
    this.idTokenExpiresAt = 0;
    this.isAuthenticated = false;
  },
  getAccessToken: function () {
    return this.idToken;
  },
  login,
  logout,
};

const updateToken = function (idToken, exp) {
  // UPDATE
  authObj.idToken = idToken;
  authObj.idTokenExpiresAt = Number(exp) * 1000;
  if (!authObj.isIdTokenExpired()) authObj.isAuthenticated = true;
  // logging
  const now = new Date().getTime();
  console.debug(
    `✅ 2loaders.auth0_auth: idToken is valid for next ${(
      (authObj.idTokenExpiresAt - now) /
      1000 /
      60
    ).toFixed(2)} minutes`
  );
  setTimeout(() => {
    window.dispatchEvent(
      new CustomEvent('c-auth0-auth', {
        detail: { status: 'updateToken', expiresAt: authObj.idTokenExpiresAt },
      })
    );
  }, 100);
};

const loadToken = function () {
  try {
    const idToken = localStorage.getItem(tokenKey);
    if (!idToken) return;
    const decoded = tool.decodeJWTToken(idToken);
    updateToken(idToken, decoded.exp);
    console.debug('✅ 2loaders.auth0_auth: loadToken: done');
  } catch (err) {
    console.debug('✅ 2loaders.auth0_auth: loadToken: err:', err);
  }
};

const storeToken = function (idToken) {
  const decoded = tool.decodeJWTToken(idToken);
  updateToken(idToken, decoded.exp);
  localStorage.setItem(tokenKey, idToken);
};

const removeToken = function () {
  localStorage.removeItem(tokenKey);
};

const checkQueryParams = async function () {
  if (!window.location.pathname.includes('/callback')) return;
  try {
    const { idToken } = await parseHash();
    storeToken(idToken);
  } catch (err) {
    console.warn('✅ 2loaders.auth0_auth: checkQueryParams: err:', err);
  }
  window.location.replace(window.origin);
};

const updateIDToken = async function () {
  try {
    if (!authObj.isIdTokenExpired()) return;
    // CASE - need to refresh id_token
    const { idToken } = await checkSession();
    storeToken(idToken);
  } catch (err) {
    console.warn('✅ 2loaders.auth0_auth: updateIDToken: err:', err);
    if (err.error === 'login_required') return login();
    await tool.sleep(1000 * 2);
    updateIDToken();
  }
};

const startRefreshingIDToken = function () {
  console.debug('✅ 2loaders.auth0_auth: startRefreshingIDToken excuted');
  setTimeout(() => updateIDToken(), 1000);
  setInterval(async () => {
    await updateIDToken();
  }, 1000 * 10);
};
// /////////////////////////////////

console.debug('✅ 2loaders.auth0_auth loaded');

// /////////////////////////////////
// ACTION
// /////////////////////////////////
// CASE 1 - logging-in - when redirected from auth0 login page
//          this will reload page
checkQueryParams();
//
// CASE 2 - checking session - not redirected
loadToken(); // load id_token from localStorage at the begining
startRefreshingIDToken(); // start refreshing id_token
// /////////////////////////////////

export default authObj;
