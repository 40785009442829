import ims from '../../../imports';

export default {
  getUserMeDealer: function ({ tsSessionDealerId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/users/me/dealer`,
      params: ims.tool.removeEmptyKey({
        dealer_id: tsSessionDealerId,
      }),
    });
  },
};
