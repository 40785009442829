import ims from '../../../../../../imports';

export default {
  getDealerSitePsap: function ({ dealerId, siteId, psapId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/psaps/${psapId}`,
    });
  },
};
