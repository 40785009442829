import ims from '../../../../../../imports';

export default {
  getDealerSiteSamanthaUrl: function ({ dealerId, siteId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/events/classification/url`,
    });
  },
};
