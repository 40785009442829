<template lang="pug">
.relative-position.shadow-3.rounded-borders.no-scroll.bg-grey-10(v-if="eventId")
  //- header
  c_event_video_101_header(
    :eventId="eventId",
    :media="media",
    @media="media = $event",
    @zoom="zoom = $event",
    @show-annotations="showAnnotations = $event",
    @show-detection-areas="showDetectionAreas = $event",
    @toggle-play="togglePlay()",
    @download="download()"
  )

  //- snapshots video
  c_snapshot_video_101(
    v-if="media === 'snapshot'",
    :siteId="siteId",
    :eventId="eventId",
    :show-annotations="showAnnotations",
    :show-detection-areas="showDetectionAreas",
    :zoom="zoom",
    ref="c_snapshot_video_101"
  )

  //- mp4 video
  c_mp4_video_101(
    v-if="media === 'mp4'",
    :siteId="siteId",
    :eventId="eventId",
    :show-annotations="showAnnotations",
    :show-detection-areas="showDetectionAreas",
    :zoom="zoom",
    ref="c_mp4_video_101"
  )
</template>

<style>
</style>

<script>
import c_event_video_101_header from "./c_event_video_101_header.vue";
import c_snapshot_video_101 from "./snapshot_video/c_snapshot_video_101.vue";
import c_mp4_video_101 from "./mp4_video/c_mp4_video_101.vue";
export default {
  components: {
    c_event_video_101_header,
    c_snapshot_video_101,
    c_mp4_video_101,
  },
  props: {
    siteId: {
      type: String,
      default: "",
    },
    eventId: {
      type: String,
      default: "",
    },
  },
  computed: {
    dealerSiteEventRecords: function () {
      return this.$store.getters.dealerSiteEventRecords(this.eventId);
    },
  },
  data: function () {
    return {
      showAnnotations: true,
      showDetectionAreas: true,
      zoom: false,
      media: "mp4", // snapshot | mp4
      //
      hasSnapshotPrev: false,
      hasMP4Prev: false,
    };
  },
  watch: {
    dealerSiteEventRecords: function () {
      this.chooseAppropriateMedia();
    },
  },
  methods: {
    chooseAppropriateMedia: function () {
      // CHECK
      if (!this.dealerSiteEventRecords) return;

      // UPDATE - hasSnapshot, hasMP4
      let hasSnapshot = false;
      let hasMP4 = false;
      for (let i = 0; i < this.dealerSiteEventRecords.length; i++) {
        const record = this.dealerSiteEventRecords[i];
        const { format, completed } = record;
        if (format === "jpg" && completed === 1) {
          hasSnapshot = true;
          continue;
        }
        if (format === "mp4" && completed === 1) {
          hasMP4 = true;
          continue;
        }
      }

      // DECIDE - mp4 is preferred*
      switch (this.media) {
        case "mp4":
          if (!hasMP4 && hasSnapshot) {
            this.$q.notify({
              message: `Switching to Snapshots Player.`,
              caption: `No mp4 video found for the event: ${this.eventId}`,
              color: "amber",
              icon: "warning",
              timeout: 1000 * 5,
            });
            this.media = "snapshot";
          }
          break;
        case "snapshot":
          if (!hasSnapshot && hasMP4) {
            this.$q.notify({
              message: `Switching to MP4 Player.`,
              caption: `No snapshots found for the event: ${this.eventId}`,
              color: "amber",
              icon: "warning",
              timeout: 1000 * 5,
            });
            this.media = "mp4";
          }
          // *mp4 is preferred
          if (hasSnapshot && hasMP4 && !this.hasMP4Prev) {
            this.$q.notify({
              message: `Switching to MP4 Player.`,
              caption: `Just found a MP4 video for the event: ${this.eventId}!`,
              color: "primary",
              icon: "info",
              timeout: 1000 * 5,
            });
            this.media = "mp4";
          }
          break;
      }

      // UPDATE - prev - for later use
      this.hasSnapshotPrev = hasSnapshot;
      this.hasMP4Prev = hasMP4;
    },
    //
    togglePlay: function () {
      switch (this.media) {
        case "mp4":
          return this.$refs?.c_mp4_video_101?.togglePlay();
        case "snapshot":
          return this.$refs?.c_snapshot_video_101?.togglePlay();
      }
    },
    download: function () {
      switch (this.media) {
        case "mp4":
          return this.$refs?.c_mp4_video_101?.download();
        case "snapshot":
          return this.$refs?.c_snapshot_video_101?.download();
      }
    },
  },
};
</script>
