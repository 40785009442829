import ims from '../../../../../imports';

export default {
  getDealerSiteContacts: function ({ dealerId, siteId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/contacts`,
    });
  },
};
