import ims from '../../../imports';
import partner from './partner';

// STATE - default
let initialState = {
  dealerPartners: null,
  statusGetDealerPartners: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerPartners: function (state) {
    return state.dealerPartners ?? {};
  },
  statusGetDealerPartners: function (state) {
    return state.statusGetDealerPartners;
  },
};

// ACTIONS
const actions = {
  getDealerPartners: async function ({ commit, getters }, { dealerId }) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerPartners === 'requested') return;
    commit('GET_DEALER_PARTNERS_REQUEST');
    console.debug('GET_DEALER_PARTNERS_REQUEST');
    try {
      const res = await ims.models.getDealerPartners({ dealerId });
      commit('GET_DEALER_PARTNERS_SUCCESS', res.data);
      console.debug('GET_DEALER_PARTNERS_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_DEALER_PARTNERS_FAILURE');
      console.debug('GET_DEALER_PARTNERS_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_PARTNERS_REQUEST: function (state) {
    state.statusGetDealerPartners = 'requested';
  },
  GET_DEALER_PARTNERS_SUCCESS: function (state, data) {
    state.dealerPartners = data;
    state.statusGetDealerPartners = 'successful';
  },
  GET_DEALER_PARTNERS_FAILURE: function (state) {
    state.statusGetDealerPartners = 'failed';
  },
};

export default {
  ...partner,
  dealerPartners: {
    state,
    getters,
    actions,
    mutations,
  },
};
