import ims from '../../../../../../imports';

// STATE - default
let initialState = {
  dealerSiteRemovedDevice: null,
  statusGetDealerSiteRemovedDevice: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSiteRemovedDevice: function (state) {
    return state.dealerSiteRemovedDevice ?? {};
  },
  statusGetDealerSiteRemovedDevice: function (state) {
    return state.statusGetDealerSiteRemovedDevice;
  },
};

// ACTIONS
const actions = {
  getDealerSiteRemovedDevice: async function (
    { commit, getters },
    { dealerId, siteId, deviceId }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSiteRemovedDevice === 'requested') return;
    commit('GET_DEALER_SITE_REMOVED_DEVICE_REQUEST');
    console.debug('GET_DEALER_SITE_REMOVED_DEVICE_REQUEST');
    try {
      const res = await ims.models.getDealerSiteRemovedDevice({
        dealerId,
        siteId,
        deviceId,
      });
      commit('GET_DEALER_SITE_REMOVED_DEVICE_SUCCESS', res.data);
      console.debug('GET_DEALER_SITE_REMOVED_DEVICE_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_DEALER_SITE_REMOVED_DEVICE_FAILURE');
      console.debug('GET_DEALER_SITE_REMOVED_DEVICE_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITE_REMOVED_DEVICE_REQUEST: function (state) {
    state.statusGetDealerSiteRemovedDevice = 'requested';
  },
  GET_DEALER_SITE_REMOVED_DEVICE_SUCCESS: function (state, data) {
    state.dealerSiteRemovedDevice = data;
    state.statusGetDealerSiteRemovedDevice = 'successful';
  },
  GET_DEALER_SITE_REMOVED_DEVICE_FAILURE: function (state) {
    state.statusGetDealerSiteRemovedDevice = 'failed';
  },
};

export default {
  dealerSiteRemovedDevice: {
    state,
    getters,
    actions,
    mutations,
  },
};
