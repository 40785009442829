// FYI:
// - It sets window.logLevel
//   in "1config.system.updateEnvironmentalVariablesGlobally"
const level = window.logLevel || 'debug';
const project_id = window?.chekt_project_id;
const app_start_time = new Date();

// INIT - uploadErrorReport
const uploadErrorReport = async function ({ message, error, data }) {
  // SLEEP - At least after 5 seconds from app_start_time
  //         enough time to collect all useful information
  const delay = app_start_time.getTime() + 1000 * 5 - new Date().getTime();
  if (delay > 0) {
    await new Promise((resolve) => {
      setTimeout(() => resolve(), delay);
    });
  }

  // INIT - ids
  const dealer_id = window?.chekt_dealer_id;
  const site_id = window?.chekt_site_id;
  const user_id = window?.chekt_user_id;
  const email = window?.chekt_email;
  const id_token = localStorage.getItem('chekt::id_token');

  // CHECK - if "localhost"
  //         let's not report any error to the cloud
  if (window.location.hostname.includes('localhost')) return;

  // INIT - payload
  const payload = {
    log_type: 'error',
    project_id,
    // location
    location_hostname: location?.hostname,
    location_href: location?.href,
    // message
    message,
    error,
    data,
    // ids
    dealer_id,
    site_id,
    user_id,
    email,
  };
  if (payload.dealer_id) payload.dealer_id = String(payload.dealer_id);
  if (payload.site_id) payload.site_id = String(payload.site_id);
  if (payload.user_id) payload.user_id = String(payload.user_id);
  if (payload.email) payload.email = String(payload.email);

  // INIT - url_prefix
  let url_prefix = 'https://logging.chektdev.com/api/v1';
  if (window.env === 'prod') {
    url_prefix = 'https://logging.chekt.com/api/v1';
  }

  // FETCH
  try {
    const res = await fetch(`${url_prefix}/users/${user_id}/logs`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${id_token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    console.warn('🫡 error reported!', res);
  } catch (err) {
    console.debug(err);
  }
  return;
};

// INIT - logger
const logger = {
  ...console,
  ///////////////////////////////////////////////////////
  // ONLY IN DEV environment ////////////////////////////
  ///////////////////////////////////////////////////////
  debug: level === 'debug' ? console.debug : () => {}, //
  ///////////////////////////////////////////////////////

  ////////////////////////
  // THE SAME ////////////
  ////////////////////////
  trace: console.trace, //
  info: console.info, ////
  log: console.log, //////
  warn: console.warn, ////
  ////////////////////////

  ////////////////////////////////////////////////////////////////////////////////
  // REPORTING to logging.chekt.com //////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  // [Usage]                                                                    //
  // console.error("something bad happened", { error })                         //
  // console.error("something bad happened", { error, some, data, you, want })  //
  ////////////////////////////////////////////////////////////////////////////////
  error: function (message, obj) {
    ///////////////////////////////////////////////////////
    // CASE 1-1 - no error obj
    if (obj?.constructor !== Object) {
      console.trace(`1🔥 ${message}`, obj);
      uploadErrorReport({ message });
      return;
    }
    ///////////////////////////////////////////////////////

    ///////////////////////////////////////////////////////
    // CASE 1-2 - no error obj
    const { error } = obj;
    // if (error?.constructor !== Object) { // DO NOT DO THIS
    //                                         error.constructor is not Object
    //                                         could by Error, TypeError or something else
    //                                         but! error always have message.
    if (!error?.message) {
      console.trace(`2🔥 ${message}`, obj);
      uploadErrorReport({ message, data: obj });
      return;
    }
    delete obj.error;
    ///////////////////////////////////////////////////////

    ///////////////////////////////////////////////////////
    // CASE 2 - has error obj
    if (!error.stack) error.stack = Error().stack;
    if (!error.isAxiosError) {
      console.trace(`3🔥 ${message}`, error, obj);
      uploadErrorReport({
        message,
        error: JSON.parse(
          JSON.stringify(error, Object.getOwnPropertyNames(error))
        ),
        data: obj,
      });
      return;
    }

    ///////////////////////////////////////////////////////

    ///////////////////////////////////////////////////////
    // CASE 3 - Axios Error
    console.trace(`4🔥 ${message}`, obj);
    uploadErrorReport({ message, error: error.toJSON(), data: obj });
    ///////////////////////////////////////////////////////
  },
  ////////////////////////////////////////////////////////////////////////////////
};

// REPLACE - original logger
window.console = logger;

// INIT - EventListener - unhandledrejection
const handleUR = function (event) {
  // PLEASE consider to add try/catch to the origin of this error if this catches anything.
  // - this doesn't give any good stack trace since this function began coming from an eventListener.
  // - not really good for debugging!
  const error = event.reason;
  console.error('UNHANDLED PROMISE REJECTION', { error });

  // Prevent the default handling (such as outputting the error to the console)
  event.preventDefault();
};

// INIT - EventListener - error
const handleUE = function (event) {
  // PLEASE consider to add try/catch to the origin of this error if this catches anything.
  // - this doesn't give any good stack trace since this function began coming from an eventListener.
  // - not really good for debugging!
  const error = event.error;
  console.error(`🔥 UNCAUGHT EXCEPTION`, { error });

  // Cancel
  // (Unlike other events, the error event is canceled by returning true)
  // the error won't appear in the console, but the current script will still stop executing.
  event.preventDefault();
  return true;
};

// REGISTER - EventListeners - as the bottom line
window.addEventListener('unhandledrejection', handleUR);
window.addEventListener('error', handleUE);

// GOOD to go
logger.info('🐷 logger loaded: %s', level);

export default logger;
