import '../1config';
import './logger';
import './auth0_auth';
import './cloud_time';
import vueLoader from './vue.js';

export default () => {
  const app = vueLoader();
  return app;
};
