import ims from '../../imports';
import accounts from './accounts';
import partners from './partners';
import sites from './sites';
import ttss from './ttss';

// STATE - default
let initialState = {
  dealer: null,
  statusGetDealer: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealer: function (state) {
    return state.dealer ?? {};
  },
  statusGetDealer: function (state) {
    return state.statusGetDealer;
  },
};

// ACTIONS
const actions = {
  getDealer: async function ({ commit, getters }, { dealerId }) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealer === 'requested') return;
    commit('GET_DEALER_REQUEST');
    console.debug('GET_DEALER_REQUEST');
    try {
      const res = await ims.models.getDealer({ dealerId });
      commit('GET_DEALER_SUCCESS', res.data?.[0]);
      console.debug('GET_DEALER_SUCCESS');
      return res.data?.[0];
    } catch (err) {
      commit('GET_DEALER_FAILURE');
      console.debug('GET_DEALER_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_REQUEST: function (state) {
    state.statusGetDealer = 'requested';
  },
  GET_DEALER_SUCCESS: function (state, data) {
    state.dealer = data;
    state.statusGetDealer = 'successful';
  },
  GET_DEALER_FAILURE: function (state) {
    state.statusGetDealer = 'failed';
  },
};

export default {
  ...accounts,
  ...partners,
  ...sites,
  ...ttss,
  dealer: {
    state,
    getters,
    actions,
    mutations,
  },
};
