import { createApp } from 'vue';
import vueApp from './vue.vue';
const app = createApp(vueApp);

import quasarLoader from './quasar';
import components from '../0lib/2components';
import router from '../3routes/0index';
import store from '../4services/1store';

quasarLoader({ app });
app.use(components);
app.use(router);
app.use(store);

console.debug('✅ 2loaders.vue.js loaded');

export default () => {
  return app;
};
