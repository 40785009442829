import ims from '../../../../../../../imports';

// STATE - default
let initialState = {
  statisticsVideoaiDealerSiteDevices: null,
  statusGetStatisticsVideoaiDealerSiteDevices: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  statisticsVideoaiDealerSiteDevices: function (state) {
    return state.statisticsVideoaiDealerSiteDevices ?? {};
  },
  statusGetStatisticsVideoaiDealerSiteDevices: function (state) {
    return state.statusGetStatisticsVideoaiDealerSiteDevices;
  },
};

// ACTIONS
const actions = {
  getStatisticsVideoaiDealerSiteDevices: async function (
    { commit, getters },
    { dealerId, siteId, stat, tztype, interval, time, limit }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetStatisticsVideoaiDealerSiteDevices === 'requested')
      return;
    commit('GET_STATISTICS_VIDEOAI_DEALER_SITE_DEVICES_REQUEST');
    console.debug('GET_STATISTICS_VIDEOAI_DEALER_SITE_DEVICES_REQUEST');
    try {
      const res = await ims.models.getStatisticsVideoaiDealerSiteDevices({
        dealerId,
        siteId,
        stat,
        tztype,
        interval,
        time,
        limit,
      });
      commit('GET_STATISTICS_VIDEOAI_DEALER_SITE_DEVICES_SUCCESS', res.data);
      console.debug('GET_STATISTICS_VIDEOAI_DEALER_SITE_DEVICES_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_STATISTICS_VIDEOAI_DEALER_SITE_DEVICES_FAILURE');
      console.debug('GET_STATISTICS_VIDEOAI_DEALER_SITE_DEVICES_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_STATISTICS_VIDEOAI_DEALER_SITE_DEVICES_REQUEST: function (state) {
    state.statusGetStatisticsVideoaiDealerSiteDevices = 'requested';
  },
  GET_STATISTICS_VIDEOAI_DEALER_SITE_DEVICES_SUCCESS: function (state, data) {
    state.statisticsVideoaiDealerSiteDevices = data?.data;
    state.statusGetStatisticsVideoaiDealerSiteDevices = 'successful';
  },
  GET_STATISTICS_VIDEOAI_DEALER_SITE_DEVICES_FAILURE: function (state) {
    state.statusGetStatisticsVideoaiDealerSiteDevices = 'failed';
  },
};

export default {
  statisticsVideoaiDealerSiteDevices: {
    state,
    getters,
    actions,
    mutations,
  },
};
