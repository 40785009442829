import ims from '../../imports';
import accounts from './accounts';
import partners from './partners';
import sites from './sites';
import ttss from './ttss';

export default {
  ...accounts,
  ...partners,
  ...sites,
  ...ttss,
  getDealer: function ({ dealerId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}`,
    });
  },
};
