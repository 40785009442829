<template lang="pug">
div
</template>

<style>
</style>

<script>
import helper from "../4services/2helper";
export default {
  data: function () {
    return {
      startHandlingPath$i: null,
      loading: false,
      //
      siteId: "",
      customerAccountNumber: "",
      accountReferenceId: "",
      receiverLinePrefix: "",
      zoneNumber: "",
      deviceId: "",
      eventId: "",
    };
  },
  watch: {
    loading: function () {
      if (this.loading)
        return this.$q.loading.show({
          spinnerSize: 40,
          message: "Loading essential data is in progress...",
        });
      return this.$q.loading.hide();
    },
    "$route.path": function () {
      this.startHandlingPath();
    },
    "$route.params": function () {
      this.handleParams();
    },
    "$route.query": function () {
      this.handleQuery();
    },
  },
  mounted: function () {
    console.debug("✅ 7subscribers.pathes loaded");
  },
  beforeMount: function () {
    this.stopHandlingPath();
  },
  methods: {
    startHandlingPath: async function () {
      this.stopHandlingPath();
      this.loading = true;
      this.startHandlingPath$i = setInterval(async () => {
        try {
          await this.handlePath();
          this.stopHandlingPath();
          this.loading = false;
        } catch (err) {
          this.$q.notify({
            message: `handlePath failure: ${err.message}`,
            color: "negative",
            icon: "warning",
            timeout: 1000 * 30,
          });
        }
      }, 1000 * 1);
    },
    stopHandlingPath: async function () {
      if (this.startHandlingPath$i) clearInterval(this.startHandlingPath$i);
      this.startHandlingPath$i = null;
    },
    handlePath: async function () {
      console.info("✅ 7subscribers.pathes: handlePath", this.$route.path);
      if (String(this.$route.path).includes("/monitoring")) {
        await helper.loadUser(this.$route.query?.tsSessionDealerId);
        await helper.loadDealer();
        await this.handleQueryMonitoring();
        this.$store.commit("START_UX_MONITORING");
        return;
      }
      if (String(this.$route.path).includes("/videoai")) {
        await helper.loadUser(this.$route.query?.tsSessionDealerId);
        await helper.loadDealer();
        this.$store.commit("START_UX_MONITORING");
        return;
      }
    },
    handleParams: function () {
      console.debug("✅ 7subscribers.pathes: handleParams", this.$route.params);
    },
    handleQuery: function () {
      console.debug("✅ 7subscribers.pathes: handleQuery", this.$route.query);
    },
    handleQueryMonitoring: async function () {
      console.info("✅ 7subscribers.pathes: handleQueryMonitoring");
      this.parseQueryMonitoring();
      await this.processQueryMonitoringSite();
      await this.processQueryMonitoringDevice();
      await this.processQueryMonitoringEvent();
    },
    parseQueryMonitoring: function () {
      Object.entries(this.$route.query).forEach((m) => {
        switch (String(m[0]).toLowerCase()) {
          // [Site ID]: Priority 1
          case "site":
            this.siteId = m[1];
            break;
          // [Account Reference ID]: Priority 2-1
          case "serial_no": // BOLD
          case "serialno": // BOLD
          case "massite": // MasterMind
          case "uid": // others
            this.accountReferenceId = m[1];
            break;
          // [Receiver Line Prefix]: it only works with `serialno`
          case "rldes": // BOLD account number prefix
            this.receiverLinePrefix = m[1];
            break;
          // [Account Number]: Priority 2-2
          // - if a site has both [Account Number] and [Account Reference ID]
          // - only [Account Reference ID] works ([Account Number] doesn't work)
          case "txid": // BOLD
          case "act": // others
            this.customerAccountNumber = m[1];
            break;
          // [Zone Number]:
          case "zone":
            this.zoneNumber = m[1];
            break;
          // [Event ID]:
          case "event":
            this.eventId = m[1];
            break;
          default:
            break;
        }
      });
    },
    processQueryMonitoringSite: function () {
      // CASE 1 - site ID - priority 1
      if (this.siteId) {
        return helper.loadSite(this.siteId);
      }

      // CASE 2 - reference ID - priority 2
      if (
        this.customerAccountNumber ||
        this.accountReferenceId ||
        this.receiverLinePrefix
      ) {
        return helper.loadAccount({
          customerAccountNumber: this.customerAccountNumber,
          accountReferenceId: this.accountReferenceId,
          receiverLinePrefix: this.receiverLinePrefix,
        });
      }

      // CASE 3 - no ID
      this.$q.notify({
        message: `NO SITE TO SHOW`,
        color: "negative",
        icon: "warning",
        timeout: 1000 * 10,
      });
    },
    processQueryMonitoringDevice: async function () {
      // CASE 1 - no zone number to analyze
      if (!this.zoneNumber) return;

      // LOAD - devices before using dealerSiteDeviceOfZone
      await helper.loadDevices();

      // UPDATE - deviceId from zoneNumber
      const device = this.$store.getters.dealerSiteDeviceOfZone(
        this.zoneNumber
      );
      this.deviceId = device?.device_id;
    },
    processQueryMonitoringEvent: async function () {
      // LOAD - devices before doing openEventApp
      await helper.loadEventsRecent();
      let description = "";

      // CASE 1 - eventId provided
      if (this.eventId) {
        description = "CASE 1 - eventId provided";
        return this.openEventApp(this.eventId, description);
      }

      // CASE 2 - no eventId to open - normal (not bad)
      if (!this.deviceId) return;

      // CASE 3 - eventId not provided - but has zoneNumber - need to decide appropriate eventId
      if (!this.eventId) {
        this.eventId = this.$store.getters.oldestUnviewedEventIdOfDeviceId(
          this.deviceId
        );
        if (this.eventId)
          description = "CASE 3-1 - oldestUnviewedEventIdOfDeviceId";
      }
      if (!this.eventId) {
        this.eventId = this.$store.getters.latestEventIdOfDeviceId(
          this.deviceId
        );
        if (this.eventId) description = "CASE 3-2 - latestEventIdOfDeviceId";
      }
      if (!this.eventId) return;

      // CASE 3 - found appropriate eventId
      return this.openEventApp(this.eventId, description);
    },
    openEventApp: function (eventId, description) {
      this.$q.notify({
        message: `OPEN event app (${description}): ${eventId}`,
        color: "black",
        icon: "info",
        timeout: 1000 * 30,
      });
    },
  },
};
</script>
