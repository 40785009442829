import ims from '../../../../../imports';
import site from './site';

export default {
  ...site,
  getStatisticsVideoaiPartnerSites: function ({
    partnerId,
    stat, // request, filtered
    tztype, // local, local-sum, utc
    interval, // 300, day, month
    time, // 2023-03-01T00%3A00%3A00%2B09%3A00
    stime,
    etime,
    limit,
  }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      baseURL: ims.config.api.prefix['v2'],
      method: 'get',
      url: `/statistics/partners/${partnerId}/sites`,
      params: ims.tool.removeEmptyKey({
        stat,
        tztype,
        interval,
        time,
        stime,
        etime,
        limit,
      }),
      timeout: 1000 * 10,
    });
  },
};
