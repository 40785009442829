<template lang="pug">
svg.absolute(
  viewBox="0 0 16 9",
  style="width: 100%; height: 100%; top: 0; left: 0"
)
  defs
    filter#solid(x="0", y="0", width="1", height="1")
      feFlood(flood-color="#ffc107")
      feComposite(in="SourceGraphic", operator="xor")

  //- bounding boxes
  g(v-for="entity_id in entity_ids")
    g(v-if="v(entity_id)", :class="animationClass(entity_id)")
      //- image
      image(
        v-if="v(entity_id).thumbnail",
        x="0",
        y="0",
        :width="v(entity_id).image_w > v(entity_id).image_h ? v(entity_id).image_w : v(entity_id).image_h",
        :height="v(entity_id).image_h > v(entity_id).image_w ? v(entity_id).image_h : v(entity_id).image_w",
        :xlink:href="`data:image/jpeg;base64,${v(entity_id).thumbnail}`",
        style="opacity: 0.7; transition: all 0.2s",
        :style="{ transform: `translate(${v(entity_id).image_w > v(entity_id).image_h ? v(entity_id).top_x : v(entity_id).center_x - v(entity_id).image_h / 2}px,${v(entity_id).image_h > v(entity_id).image_w ? v(entity_id).top_y : v(entity_id).center_y - v(entity_id).image_w / 2}px)` }"
      )

      //- box
      rect(
        v-if="v(entity_id).thumbnail",
        x="0",
        y="0",
        :width="v(entity_id).image_w",
        :height="v(entity_id).image_h",
        stroke="#ff4081",
        stroke-width="0.03",
        fill="rgba(255, 64, 129, 0)",
        :style="{ transform: `translate(${v(entity_id).top_x}px,${v(entity_id).top_y}px)` }",
        style="transition: all 0.2s"
      )

      //- labels
      //- text background
      text(
        x="0",
        y="0",
        filter="url(#solid)",
        font-size="0.26",
        :style="{ transform: `translate(${v(entity_id).text.x}px,${v(entity_id).text.y}px)` }",
        style="transition: all 0.2s"
      ) {{ v(entity_id).text.value }}
      //- text
      text(
        x="0",
        y="0",
        fill="black",
        font-size="0.26",
        :style="{ transform: `translate(${v(entity_id).text.x}px,${v(entity_id).text.y}px)` }",
        style="transition: all 0.2s"
      ) {{ v(entity_id).text.value }}
</template>

<style>
</style>

<script>
export default {
  components: {},
  props: {
    eventId: {
      type: String,
      default: "",
    },
    play: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    annotation: function () {
      return this.$store.getters.dealerSiteEventAnnotation(this.eventId);
    },
    entity_ids: function () {
      return ["vehicle", "person"];
    },
  },
  data: function () {
    return {
      fps: 4,
      entityIdsBoundingBoxes: { person: [], vehicle: [] },
      animation$i: null,
      index: 0,
    };
  },
  watch: {
    annotation: function () {
      this.updateBoundingBoxes();
    },
  },
  mounted: function () {
    this.updateBoundingBoxes();
    this.startAnimation();
  },
  beforeUnmount: function () {
    this.stopAnimation();
  },
  methods: {
    v: function (entity_id) {
      if (!this.entityIdsBoundingBoxes[entity_id][this.index]) {
        const lastIndex = this.entityIdsBoundingBoxes[entity_id].length - 1;
        return this.entityIdsBoundingBoxes[entity_id][lastIndex];
      }
      return this.entityIdsBoundingBoxes[entity_id][this.index];
    },
    animationClass: function (entity_id) {
      if (this.entityIdsBoundingBoxes[entity_id].length !== 1) return null;
      return {
        animate__animated: true,
        animate__fadeIn: true,
        animate__infinite: true,
        animate__slow: true,
      };
    },
    //
    startAnimation: function () {
      this.stopAnimation();
      this.animation$i = setInterval(() => this.updateIndex(), 1000 / this.fps);
    },
    stopAnimation: function () {
      if (!this.animation$i) return;
      clearInterval(this.animation$i);
      this.animation$i = null;
    },
    updateIndex: function () {
      if (!this.play) return;
      this.index++;

      // GET - longerLength
      let longerLength = 0;
      this.entity_ids.forEach((entity_id) => {
        const v = this.entityIdsBoundingBoxes[entity_id];
        if (v.length > longerLength) longerLength = v.length;
      });

      // UPDATE - index
      if (this.index > longerLength - 1) {
        this.index = 0;
      }
    },
    //
    initEntityIdsBoundingBoxes: function () {
      let entityIdsBoundingBoxes = {};
      this.entity_ids.forEach((entity_id) => {
        entityIdsBoundingBoxes[entity_id] = [];
      });
      this.entityIdsBoundingBoxes = entityIdsBoundingBoxes;
    },
    //
    updateBoundingBoxes: function () {
      this.initEntityIdsBoundingBoxes();
      const frames_detections = this.annotation?.frames_detections;
      if (!frames_detections) return;
      Object.values(frames_detections).forEach((frame_detections) => {
        this.entity_ids.forEach((entity_id) => {
          const v = this.filterEntityIdWithHigherPriority(
            frame_detections,
            entity_id
          );
          if (!v) return;

          // UPDATE - points
          const points = [
            `${v.top_x * 16},${v.top_y * 9}`,
            `${v.bottom_x * 16},${v.top_y * 9}`,
            `${v.bottom_x * 16},${v.bottom_y * 9}`,
            `${v.top_x * 16},${v.bottom_y * 9}`,
          ];

          // UPDATE - coordinates, size
          const image_w = (v.bottom_x - v.top_x) * 16;
          const image_h = (v.bottom_y - v.top_y) * 9;
          const top_x = v.top_x * 16;
          const top_y = v.top_y * 9;

          // PUSH - a valid bounding box
          this.entityIdsBoundingBoxes[entity_id].push({
            thumbnail: v.thumbnail,
            points: points.join(" "),
            image_w,
            image_h,
            top_x,
            top_y,
            center_x: (v.top_x + (v.bottom_x - v.top_x) / 2) * 16,
            center_y: (v.top_y + (v.bottom_y - v.top_y) / 2) * 9,
            text: {
              x: top_x,
              y: top_y < 0 ? top_y + 0.26 : top_y - 0.1,
              value: `${v.entity_name} (${(v.confidence * 100 * 1.0).toFixed(
                0
              )}%)`,
            },
          });
        });
      });
    },
    //
    filterEntityIdWithHigherPriority: function (frame_detections, entity_id) {
      let detection = null;
      for (let i = 0; i < frame_detections.length; i++) {
        const v = frame_detections[i];
        // FILTER - only 'person'
        if (v?.entity_id !== entity_id) continue;

        // IF - has area_filter - and not captured
        const captured_in_area_filters = !!v.thumbnail;
        if (!captured_in_area_filters) continue;

        // priority 0 - anything (if only one object detected)
        if (!detection) {
          detection = v;
          continue;
        }

        // priority 1 - motion
        if (detection.motion && !v.motion) continue;
        if (!detection.motion && v.motion) {
          detection = v;
          continue;
        }

        // priority 2 - confidence
        if (detection.confidence < v.confidence) {
          detection = v;
          continue;
        }
      }
      return detection;
    },
  },
};
</script>
