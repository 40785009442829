<template lang="pug">
div
</template>

<style>
</style>

<script>
import config from "../1config";
export default {
  watch: {
    "$store.getters.statusGetUserMeDealer": function (status) {
      switch (status) {
        case "successful":
          // [These below need to be initialized by 7subscribers.pathes]
          // helper.loadSite();      <== don't do this here
          // helper.loadAccount({}); <== don't do this here
          return;
        case "failed":
          return;
      }
    },
    "$store.getters.statusGetDealerSite": async function (status) {
      switch (status) {
        case "successful":
          // [These below need to be initialized upon request (e.g. c_list_site_events_videoai)]
          // helper.connectCommandCenter();

          // [These below need to be initialized by 7subscribers.pathes]
          // helper.loadDevices();        <== don't do this here
          // helper.loadEventsRecent();   <== don't do this here

          // [These below need to be initialized upon request, not need to do it before it is necessary]
          // await helper.loadContacts(); <== don't do this here
          // await helper.loadTTSs();     <== don't do this here
          // helper.loadRemovedDevices(); <== don't do this here // least important (there could be 100+ removed_devices)
          return;
        case "failed":
          return;
      }
    },
    "$store.getters.statusGetDealerSiteDevices": function (status) {
      if (config.env !== "dev") return;
      if (status !== "successful") return;
      return this.$q.notify({
        message: `Devices loaded.`,
        color: "primary",
        icon: "devices",
        timeout: 2000,
      });
    },
    "$store.getters.statusGetDealerSiteEventsRecent": function (status) {
      switch (status) {
        case "successful":
          return this.$q.notify({
            message: `Events loaded.`,
            color: "primary",
            icon: "dynamic_feed",
            timeout: 2000,
          });
        case "failed":
          return this.$q.notify({
            message: "Loading events failed...",
            color: "negative",
            icon: "warning",
          });
      }
    },
    "$store.getters.statusGetDealerSiteEvents24h": function (status) {
      switch (status) {
        case "successful":
          return this.$q.notify({
            message: `Event(s) loaded...`,
            color: "black",
            icon: "dynamic_feed",
            timeout: 500,
          });
        case "failed":
          return this.$q.notify({
            message: "Loading event(s) failed...",
            color: "negative",
            icon: "warning",
          });
      }
    },
    "$store.getters.statusGetStatisticsVideoaiDealerSites": function (status) {
      switch (status) {
        case "successful":
          return this.$q.notify({
            message: `VideoAI statistics of my sites loaded...`,
            color: "black",
            icon: "dynamic_feed",
            timeout: 1200,
          });
        case "failed":
          return this.$q.notify({
            message: "Loading videoAI statistics failed...",
            color: "negative",
            icon: "warning",
          });
      }
    },
    "$store.getters.statusGetStatisticsVideoaiPartnerSites": function (status) {
      switch (status) {
        case "successful":
          return this.$q.notify({
            message: `VideoAI statistics of partners' sites loaded...`,
            color: "black",
            icon: "dynamic_feed",
            timeout: 1200,
          });
        case "failed":
          return this.$q.notify({
            message: "Loading videoAI statistics failed...",
            color: "negative",
            icon: "warning",
          });
      }
    },
    "$store.getters.statusGetDealerSiteContacts": function (status) {
      if (config.env !== "dev") return;
      if (status !== "successful") return;
      return this.$q.notify({
        message: `Contacts loaded.`,
        color: "primary",
        icon: "contacts",
        timeout: 2000,
      });
    },
    "$store.getters.statusGetDealerSiteTTSs": function (status) {
      if (config.env !== "dev") return;
      if (status !== "successful") return;
      return this.$q.notify({
        message: `TTSs loaded.`,
        color: "primary",
        icon: "record_voice_over",
        timeout: 2000,
      });
    },
    "$store.getters.statusGetDealerSiteRemovedDevices": function (status) {
      if (config.env !== "dev") return;
      if (status !== "successful") return;
      return this.$q.notify({
        message: `RemovedDevices loaded.`,
        color: "primary",
        icon: "folder_delete",
        timeout: 2000,
      });
    },
  },
  mounted: function () {
    console.debug("✅ 7subscribers.apis loaded");
  },
};
</script>
