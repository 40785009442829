import ims from '../../../../../imports';
import site from './site';

// STATE - default
let initialState = {
  statisticsVideoaiDealerSites: null,
  statisticsVideoaiDealerSitesByStat: null,
  statusGetStatisticsVideoaiDealerSites: '',
  statusGetStatisticsVideoaiDealerSitesByStat: null,
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  statisticsVideoaiDealerSites: function (state) {
    return state.statisticsVideoaiDealerSites ?? {};
  },
  statisticsVideoaiDealerSitesByStat: function (state) {
    return state.statisticsVideoaiDealerSitesByStat ?? {};
  },
  statisticsVideoaiDealerSitesRequest: function (state, getters) {
    return getters.statisticsVideoaiDealerSitesByStat['request'];
  },
  statisticsVideoaiDealerSitesFiltered: function (state, getters) {
    return getters.statisticsVideoaiDealerSitesByStat['filtered'];
  },
  statusGetStatisticsVideoaiDealerSites: function (state) {
    return state.statusGetStatisticsVideoaiDealerSites;
  },
  statusGetStatisticsVideoaiDealerSitesByStat: function (state) {
    return function (stat) {
      if (!state.statusGetStatisticsVideoaiDealerSitesByStat) return '';
      return state.statusGetStatisticsVideoaiDealerSitesByStat[stat];
    };
  },
};

// ACTIONS
const actions = {
  getStatisticsVideoaiDealerSites: async function (
    { commit, getters },
    { dealerId, stat, tztype, interval, time, limit }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (
      getters.statusGetStatisticsVideoaiDealerSitesByStat(stat) === 'requested'
    )
      return;
    commit('GET_STATISTICS_VIDEOAI_DEALER_SITES_REQUEST', { stat });
    console.debug('GET_STATISTICS_VIDEOAI_DEALER_SITES_REQUEST');
    try {
      const res = await ims.models.getStatisticsVideoaiDealerSites({
        dealerId,
        stat,
        tztype,
        interval,
        time,
        limit,
      });
      commit('GET_STATISTICS_VIDEOAI_DEALER_SITES_SUCCESS', {
        data: res.data,
        stat,
      });
      console.debug('GET_STATISTICS_VIDEOAI_DEALER_SITES_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_STATISTICS_VIDEOAI_DEALER_SITES_FAILURE', { stat });
      console.debug('GET_STATISTICS_VIDEOAI_DEALER_SITES_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_STATISTICS_VIDEOAI_DEALER_SITES_REQUEST: function (state, { stat }) {
    if (!state.statusGetStatisticsVideoaiDealerSitesByStat) {
      state.statusGetStatisticsVideoaiDealerSitesByStat = {};
    }
    state.statusGetStatisticsVideoaiDealerSitesByStat[stat] = 'requested';
    state.statusGetStatisticsVideoaiDealerSites = 'requested';
  },
  GET_STATISTICS_VIDEOAI_DEALER_SITES_SUCCESS: function (
    state,
    { data, stat }
  ) {
    // UPDATE - statisticsVideoaiDealerSites
    state.statisticsVideoaiDealerSites = data?.data;
    // UPDATE - statisticsVideoaiDealerSitesByStat
    if (!state.statisticsVideoaiDealerSitesByStat) {
      state.statisticsVideoaiDealerSitesByStat = {};
    }
    state.statisticsVideoaiDealerSitesByStat[stat] = data?.data;
    // UPDATE - status
    state.statusGetStatisticsVideoaiDealerSitesByStat[stat] = 'successful';
    state.statusGetStatisticsVideoaiDealerSites = 'successful';
  },
  GET_STATISTICS_VIDEOAI_DEALER_SITES_FAILURE: function (state, { stat }) {
    state.statusGetStatisticsVideoaiDealerSitesByStat[stat] = 'failed';
    state.statusGetStatisticsVideoaiDealerSites = 'failed';
  },
};

export default {
  ...site,
  statisticsVideoaiDealerSites: {
    state,
    getters,
    actions,
    mutations,
  },
};
