import ims from '../../../../../imports';
import tts from './tts';

export default {
  ...tts,
  getDealerSiteTTSs: function ({ dealerId, siteId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/ttss`,
    });
  },
};
