import ims from '../../../imports';

export default {
  getUserMeIp: function () {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/users/me/ip`,
    });
  },
};
