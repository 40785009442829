import ims from '../imports';

export default {
  getURLObjectURLSync: function (url) {
    // CHECK - objectURL
    const objectURL = ims.store.getters.urlObjectURL(url);

    // CASE 1 - Yes, there is an objectURL for the url
    if (objectURL) return objectURL;

    // CASE 2 - No, objectURL for the url
    // LOAD - in background
    ims.store.dispatch('getURLObjectURL', { url });
    return 'loading';
  },
  loadURLObjectURL: function (url) {
    // CHECK - objectURL
    const objectURL = ims.store.getters.urlObjectURL(url);

    // CASE 1 - Yes, there is an objectURL for the url
    if (objectURL) return objectURL;

    // CASE 2 - No, objectURL for the url
    return ims.store.dispatch('getURLObjectURL', { url });
  },
};
