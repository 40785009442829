import ims from '../../../imports';
import partner from './partner';

export default {
  ...partner,
  getDealerPartners: function ({ dealerId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/partners`,
    });
  },
};
