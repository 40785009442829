<template lang="pug">
//- image markers
.absolute(
  v-for="(v, i) in markers",
  :style="{ left: `${(v.frameIndex / (totalFrames - 1)) * 100}%`, transform: v.frameIndex == 0 ? 'translateX(0)' : v.frameIndex == totalFrames - 1 ? 'translateX(-100%)' : 'translateX(-50%)' }",
  style="height: 50px; top: 0; user-select: none"
)
  c_avatar_102(:src="v.thumbnail", size="50px")
</template>

<style>
</style>

<script>
import ims from "../../imports";
import c_avatar_102 from "../../img/c_avatar_102.vue";
export default {
  components: {
    c_avatar_102,
  },
  props: {
    siteId: {
      type: String,
      default: "",
    },
    eventId: {
      type: String,
      default: "",
    },
    totalFrames: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    dealerSiteDevices: function () {
      return this.$store.getters.dealerSiteDevices;
    },
    device: function () {
      return this.dealerSiteDevices[this.event?.device_id];
    },
    event: function () {
      return this.$store.getters.dealerSiteEvent24h(this.eventId);
    },
    annotation: function () {
      return this.$store.getters.dealerSiteEventAnnotation(this.eventId);
    },
  },
  data: function () {
    return {
      markers: [],
    };
  },
  watch: {
    event: function () {
      this.loadData();
    },
    annotation: function () {
      this.updateMarkers();
    },
  },
  mounted: function () {
    this.loadData();
  },
  methods: {
    loadData: function () {
      // CHECK
      if (!this.siteId) return;
      if (!this.eventId) return;

      // GET - event annotation
      if (this.event?.videoai_result !== "success") return;
      return ims.helper.loadSiteEventAnnotation(this.siteId, this.eventId);
    },
    updateMarkers: function () {
      this.markers = [];
      if (!this.annotation?.frames_detections) return;
      Object.entries(this.annotation?.frames_detections).forEach((v) => {
        const frameIndex = v[0];
        const detections = v[1];
        let detection = null;
        detections?.forEach((vv) => {
          // IF - has area_filter - and not captured
          if (this.device?.videoai_area_filter_meta) {
            if (vv.captured_area_filters?.length < 1) return;
          }

          // priority 0 - anything (if only one object detected)
          if (!detection) {
            detection = {
              entity_id: vv.entity_id,
              confidence: vv.confidence,
              thumbnail: vv.thumbnail,
            };
            return;
          }
          // priority 1 - person
          if (detection.entity_id === "person" && vv.entity_id === "vehicle")
            return;
          if (detection.entity_id === "vehicle" && vv.entity_id === "person") {
            detection = {
              entity_id: vv.entity_id,
              confidence: vv.confidence,
              thumbnail: vv.thumbnail,
            };
            return;
          }
          // priority 2 - confidence
          if (detection.confidence < vv.confidence) {
            detection = {
              entity_id: vv.entity_id,
              confidence: vv.confidence,
              thumbnail: vv.thumbnail,
            };
            return;
          }
        });
        if (!detection) return;

        // CASE 1 - in privacy mode
        if (this.event?.is_privacy > 0) {
          switch (detection.entity_id) {
            case "person":
              return this.markers.push({
                frameIndex,
                thumbnail: ims.tool.imagePlaceHolderPerson({}),
              });
            case "vehicle":
              return this.markers.push({
                frameIndex,
                thumbnail: ims.tool.imagePlaceHolderVehicle({}),
              });
          }
          return;
        }
        // CASE 2 - NOT in privacy mode
        // CHECK - if thumbnail exists
        // captured_area_filters
        if (detection.thumbnail)
          this.markers.push({
            frameIndex,
            thumbnail: `data:image/jpeg;base64,${detection.thumbnail}`,
          });
      });
    },
  },
};
</script>
