import ims from '../../../../../../../../../imports';

export default {
  createDealerSiteDeviceEventWatch: function ({
    dealerId,
    siteId,
    deviceId,
    eventId,
  }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/events/${eventId}/watch`,
    });
  },
};
