export default {
  isObject: function (a) {
    return !!a && a.constructor === Object;
  },
  removeEmptyKey: function (obj) {
    if (!this.isObject(obj)) {
      throw new TypeError(
        `Expected a type object, got ${obj === null ? obj : typeof obj}`
      );
    }
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '')
        return;
      delete obj[key];
    });
    return obj;
  },
  flatifyObject: function (obj) {
    if (!this.isObject(obj)) {
      throw new TypeError(
        `Expected a type object, got ${obj === null ? obj : typeof obj}`
      );
    }
    const flattened = {};
    for (const key in obj) {
      if (!Object.hasOwnProperty.call(obj, key)) {
        continue;
      }
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        const flatObject = this.flatifyObject(obj[key]);
        for (const flatObjectKey in flatObject) {
          if (!Object.hasOwnProperty.call(flatObject, flatObjectKey)) {
            continue;
          }
          flattened[key + '.' + flatObjectKey] = flatObject[flatObjectKey];
        }
        continue;
      }
      flattened[key] = obj[key];
    }
    return flattened;
  },
};
