import ims from '../../../imports';

export default {
  getDealerAccounts: function ({
    dealerId,
    customerAccountNumber,
    receiverLinePrefix,
    accountReferenceId,
  }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/accounts`,
      params: ims.tool.removeEmptyKey({
        account_number: customerAccountNumber,
        rldes: receiverLinePrefix,
        uid: accountReferenceId,
      }),
    });
  },
};
