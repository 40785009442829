import ims from '../../../../../../../imports';
import record from './record';

export default {
  ...record,
  getDealerSiteEventRecords: function ({ dealerId, siteId, eventId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/events/${eventId}/records`,
    });
  },
};
