import axios from 'axios';
import tool from '../0lib/0tool';
import config from '../1config';
import auth0_auth from '../2loaders/auth0_auth';
import cloud_time from '../2loaders/cloud_time';

export default {
  axios,
  tool,
  config,
  auth0_auth,
  cloud_time,
  getDefaultAxiosOptions: function () {
    return {
      headers: {
        Authorization: 'Bearer ' + auth0_auth.getAccessToken(),
      },
      baseURL: config.api.prefix['v1.1'],
      responseType: 'json',
      timeout: 1000 * 30,
    };
  },
  getDefaultCKBAPIv2Body: function ({ macAddress, method, path }) {
    return {
      headers: {
        method,
        path: `ckbapiv2${path}`,
        mqttResponseTopic: `devices/${macAddress}/0/ckbapiv2/res/${Math.floor(
          Math.random() * 10000
        )}`,
      },
      body: {},
    };
  },
};
