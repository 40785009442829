import ims from '../../../../../../../imports';

export default {
  getDealerSiteVerificationHistory: function ({
    dealerId,
    siteId,
    verificationId,
  }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/verifications/${verificationId}/history`,
      params: {
        limit: 20,
        dir: 'desc',
        sort: 'verification_id',
      },
    });
  },
};
