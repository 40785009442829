<template lang="pug">
q-dialog(ref="dialog")
  .bg-white
    q-toolbar
      .text-subtitle2.text-uppercase Dialog
      q-space
      q-btn(dense, flat, icon="close", v-close-popup)

    .q-pa-md
      p.text-h6 Notification
      .text-body2.
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Rerum repellendus sit voluptate voluptas eveniet porro.
        Rerum blanditiis perferendis totam,
        ea at omnis vel numquam exercitationem aut,
        natus minima, porro labore.

    q-toolbar
      q-space
      q-btn(flat, color="primary", label="ok", v-on:click="onClickOkay")
      q-btn(flat, label="cancel", v-close-popup)
</template>

<style>
</style>

<script>
export default {
  emits: ["ok"],
  methods: {
    onClickOkay: function () {
      this.$emit("ok");
      this.hide();
    },
    hide: function () {
      const ref = this.$refs["dialog"];
      if (!(ref instanceof Object)) return;
      ref.hide();
    },
  },
};
</script>
