import ims from '../../../../../imports';
import site from './site';

// STATE - default
let initialState = {
  statisticsVideoaiPartnerSites: null,
  statisticsVideoaiPartnerSitesByStat: null,
  statusGetStatisticsVideoaiPartnerSites: '',
  statusGetStatisticsVideoaiPartnerSitesByStat: null,
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  statisticsVideoaiPartnerSites: function (state) {
    return state.statisticsVideoaiPartnerSites ?? {};
  },
  statisticsVideoaiPartnerSitesByStat: function (state) {
    return state.statisticsVideoaiPartnerSitesByStat ?? {};
  },
  statisticsVideoaiPartnerSitesRequest: function (state, getters) {
    return getters.statisticsVideoaiPartnerSitesByStat['request'];
  },
  statisticsVideoaiPartnerSitesFiltered: function (state, getters) {
    return getters.statisticsVideoaiPartnerSitesByStat['filtered'];
  },
  statusGetStatisticsVideoaiPartnerSites: function (state) {
    return state.statusGetStatisticsVideoaiPartnerSites;
  },
  statusGetStatisticsVideoaiPartnerSitesByStat: function (state) {
    return function (stat) {
      if (!state.statusGetStatisticsVideoaiPartnerSitesByStat) return '';
      return state.statusGetStatisticsVideoaiPartnerSitesByStat[stat];
    };
  },
};

// ACTIONS
const actions = {
  getStatisticsVideoaiPartnerSites: async function (
    { commit, getters },
    { partnerId, stat, tztype, interval, time, limit }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (
      getters.statusGetStatisticsVideoaiPartnerSitesByStat(stat) === 'requested'
    )
      return;
    commit('GET_STATISTICS_VIDEOAI_PARTNER_SITES_REQUEST', { stat });
    console.debug('GET_STATISTICS_VIDEOAI_PARTNER_SITES_REQUEST');
    try {
      const res = await ims.models.getStatisticsVideoaiPartnerSites({
        partnerId,
        stat,
        tztype,
        interval,
        time,
        limit,
      });
      commit('GET_STATISTICS_VIDEOAI_PARTNER_SITES_SUCCESS', {
        data: res.data,
        stat,
      });
      console.debug('GET_STATISTICS_VIDEOAI_PARTNER_SITES_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_STATISTICS_VIDEOAI_PARTNER_SITES_FAILURE', { stat });
      console.debug('GET_STATISTICS_VIDEOAI_PARTNER_SITES_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_STATISTICS_VIDEOAI_PARTNER_SITES_REQUEST: function (state, { stat }) {
    if (!state.statusGetStatisticsVideoaiPartnerSitesByStat) {
      state.statusGetStatisticsVideoaiPartnerSitesByStat = {};
    }
    state.statusGetStatisticsVideoaiPartnerSitesByStat[stat] = 'requested';
    state.statusGetStatisticsVideoaiPartnerSites = 'requested';
  },
  GET_STATISTICS_VIDEOAI_PARTNER_SITES_SUCCESS: function (
    state,
    { data, stat }
  ) {
    // UPDATE - statisticsVideoaiPartnerSites
    state.statisticsVideoaiPartnerSites = data?.data;
    // UPDATE - statisticsVideoaiPartnerSitesByStat
    if (!state.statisticsVideoaiPartnerSitesByStat) {
      state.statisticsVideoaiPartnerSitesByStat = {};
    }
    state.statisticsVideoaiPartnerSitesByStat[stat] = data?.data;
    // UPDATE - status
    state.statusGetStatisticsVideoaiPartnerSitesByStat[stat] = 'successful';
    state.statusGetStatisticsVideoaiPartnerSites = 'successful';
  },
  GET_STATISTICS_VIDEOAI_PARTNER_SITES_FAILURE: function (state, { stat }) {
    state.statusGetStatisticsVideoaiPartnerSitesByStat[stat] = 'failed';
    state.statusGetStatisticsVideoaiPartnerSites = 'failed';
  },
};

export default {
  ...site,
  statisticsVideoaiPartnerSites: {
    state,
    getters,
    actions,
    mutations,
  },
};
