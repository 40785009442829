<template lang="pug">
div
</template>

<style>
</style>

<script>
import helper from "../4services/2helper";
export default {
  watch: {
    "$store.getters.uxMonitoringLayoutZoom": function (now, before) {
      this.$q.notify({
        message: `Zoom ${now < before ? "out" : "in"}`,
        color: `${now < before ? "dark" : "primary"}`,
        icon: `${now < before ? "zoom_out" : "zoom_in"}`,
        timeout: 200,
      });
    },
  },
  created: function () {
    console.debug("✅ 7subscribers.events loaded");
    window.removeEventListener(
      "c-monitoring-sound",
      this.handleMonitoringSound
    );
    window.addEventListener("c-monitoring-sound", this.handleMonitoringSound);
    window.removeEventListener("c-auth0-auth", this.handleAuth0Auth);
    window.addEventListener("c-auth0-auth", this.handleAuth0Auth);
  },
  methods: {
    handleMonitoringSound: function (event) {
      this.$q.notify({
        message: `Sound ${event?.detail?.status ? "on" : "off"}`,
        color: `${event?.detail?.status ? "primary" : "dark"}`,
        icon: `${event?.detail?.status ? "volume_up" : "volume_off"}`,
        timeout: 200,
      });
    },
    handleAuth0Auth: async function (event) {
      switch (event?.detail?.status) {
        case "updateToken":
          this.$q.notify({
            message: `This auth session is valid until ${new Date(
              event?.detail?.expiresAt
            ).toLocaleString()}`,
            color: "primary",
            icon: "info",
            timeout: 2000,
          });
          await helper.loadUser(this.$route.query?.tsSessionDealerId);
          await helper.loadDealer();
          return;
        default:
          return;
      }
    },
  },
};
</script>
