<template lang="pug">
//- video area wrapper
.no-scroll.text-white(
  @mousemove="onMouseMove",
  style="position: relative; width: 100%"
)
  //- zoom wrapper
  div(
    :style=`{
      transformOrigin: 'top left',
      transform: transform,
    }`,
    ref="c_zoom_wrapper",
    @click="togglePlay()"
  )
    //- snapshots video
    c_mp4_video_101_mp4(
      :siteId="siteId",
      :eventId="eventId",
      @current-frame="currentFrame = $event",
      @video="video = $event",
      @video-info="videoInfo = $event",
      @timeupdated="timeupdated = $event",
      @play="play = $event",
      ref="c_mp4_video_101_mp4"
    )

    //- detection areas
    c_mp4_video_101_detection_areas(
      v-if="showDetectionAreas",
      :eventId="eventId",
      style="opacity: 0.5"
    )

    //- bounding boxes
    c_mp4_video_101_bounding_boxes(
      v-if="showAnnotations",
      :eventId="eventId",
      :play="play"
    )

//- thumbnails
c_mp4_video_101_thumbnails(
  :siteId="siteId",
  :eventId="eventId",
  @click="togglePlay()"
)

//- slider
c_mp4_video_101_slider.shadow-3(
  :video="video",
  :videoInfo="videoInfo",
  :play="play",
  :timeupdated="timeupdated",
  :current-frame="currentFrame"
)
</template>

<style>
</style>

<script>
import ims from "../../imports";
import c_mp4_video_101_bounding_boxes from "./c_mp4_video_101_bounding_boxes.vue";
import c_mp4_video_101_detection_areas from "./c_mp4_video_101_detection_areas.vue";
import c_mp4_video_101_mp4 from "./c_mp4_video_101_mp4.vue";
import c_mp4_video_101_slider from "./c_mp4_video_101_slider.vue";
import c_mp4_video_101_thumbnails from "./c_mp4_video_101_thumbnails.vue";
export default {
  components: {
    c_mp4_video_101_bounding_boxes,
    c_mp4_video_101_detection_areas,
    c_mp4_video_101_mp4,
    c_mp4_video_101_slider,
    c_mp4_video_101_thumbnails,
  },
  props: {
    siteId: {
      type: String,
      default: "",
    },
    eventId: {
      type: String,
      default: "",
    },
    showAnnotations: {
      type: Boolean,
      default: true,
    },
    showDetectionAreas: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cursorStyle: function () {
      let cursor = "default";
      if (this.zoom) cursor = "zoom-in";
      return { cursor };
    },
  },
  data: function () {
    return {
      //
      video: null,
      videoInfo: null,
      currentFrame: -1,
      timeupdated: null,
      //
      play: true,
      //
      transform: "",
      transformCache: "",
    };
  },
  watch: {
    eventId: function () {
      this.init();
    },
    zoom: function () {
      if (!this.zoom) {
        this.transform = "";
        return;
      }
      this.transform = this.transformCache;
    },
  },
  mounted: function () {
    this.init();
  },
  methods: {
    init: function () {
      this.loadData();
    },
    //
    onMouseMove: function (e) {
      const c_zoom_wrapper = this.$refs?.c_zoom_wrapper;
      if (!c_zoom_wrapper) return;
      const rect = c_zoom_wrapper.offsetParent?.getBoundingClientRect();
      if (!rect) return;
      const x = e.clientX - rect.left ?? 0;
      let y = e.clientY - rect.top ?? 0;
      if (y > rect.height) y = rect.height;
      const scale = 4;
      this.transformCache = `scale(${scale}) translate(-${
        ((x / rect.width) * 100) / (1 + 1 / (scale - 1))
      }%, -${((y / rect.height) * 100) / (1 + 1 / (scale - 1))}%)`;
      if (!this.zoom) return;
      this.transform = this.transformCache;
    },
    //
    togglePlay: function () {
      const c_mp4_video_101_mp4 = this.$refs?.c_mp4_video_101_mp4;
      if (!c_mp4_video_101_mp4) return;
      c_mp4_video_101_mp4.togglePlay();
    },
    download: function () {
      const c_mp4_video_101_mp4 = this.$refs?.c_mp4_video_101_mp4;
      if (!c_mp4_video_101_mp4) return;
      c_mp4_video_101_mp4.download();
    },
    //
    loadData: async function () {
      // CHECK
      if (!this.siteId) return;
      if (!this.eventId) return;

      // GET - event
      const event = await ims.helper.loadEvent({
        siteId: this.siteId,
        eventId: this.eventId,
      });

      // PUSH - event data to event list for 24h monitoring (for videoai page)
      if (!event) return; // maybe already "requested"
      this.$store.commit("GET_DEALER_SITE_EVENTS_24H_SUCCESS", [event]);
    },
  },
};
</script>
