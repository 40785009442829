import config from '../../1config';
import { createStore } from 'vuex';
import dealers from './dealers';
import statistics from './statistics';
import urls from './urls';
import users from './users';
import ux from './ux';

const store = createStore({
  modules: {
    ...dealers,
    ...statistics,
    ...urls,
    ...users,
    ...ux,
  },
  strict: config.env === 'dev',
});

console.debug('✅ 4services.1store loaded');

export default store;
