import ims from '../imports';

export default {
  loadDevices: function (siteId) {
    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // GET & CHECK - siteId - best effort
    if (!siteId) siteId = ims.store.getters.dealerSite?.site_id;
    if (!siteId) siteId = ims.store.getters.dealerAccounts?.site_id;
    if (!siteId) siteId = ims.tool.getQuery('site');
    if (!siteId) return;

    // LOAD - data
    const fields = [
      'device.device_id',
      'device.site_id',
      'device.name',
      'device.is_online',
      'device.is_privacy',
      'device.privacy_disabled_timestamp',
      'device.videoai_enabled',
      'device.videoai_area_filter_meta',
      'device.zone_number',
      'device.mac',
      'device.type',
      'device_group.group_id',
      'device.mqtt_url',
    ].join(',');
    return ims.store.dispatch('getDealerSiteDevices', {
      dealerId,
      siteId,
      fields,
    });
  },
  loadRemovedDevices: function (siteId) {
    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // GET & CHECK - siteId - best effort
    if (!siteId) siteId = ims.store.getters.dealerSite?.site_id;
    if (!siteId) siteId = ims.store.getters.dealerAccounts?.site_id;
    if (!siteId) return;

    // LOAD - data
    return ims.store.dispatch('getDealerSiteRemovedDevices', {
      dealerId,
      siteId,
    });
  },
};
