import ims from '../../../../../../imports';

// STATE - default
let initialState = {
  siteId: '',
  stime: '',
  dealerSiteDevicesNetlossEvents: null,
  statusGetDealerSiteDevicesNetlossEvents: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSiteDevicesNetlossEvents: function (state) {
    return state.dealerSiteDevicesNetlossEvents ?? {};
  },
  statusGetDealerSiteDevicesNetlossEvents: function (state) {
    return state.statusGetDealerSiteDevicesNetlossEvents ?? {};
  },
  statusGetDealerSiteDeviceNetlossEvents: function (state, getters) {
    return function (deviceId) {
      return getters.statusGetDealerSiteDevicesNetlossEvents(deviceId);
    };
  },
};

// ACTIONS
const actions = {
  getDealerSiteDeviceNetlossEvents: async function (
    { commit, getters },
    { dealerId, siteId, deviceId, stime }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (
      getters.statusGetDealerSiteDeviceNetlossEvents(deviceId) === 'requested'
    )
      return;
    commit('GET_DEALER_SITE_DEVICE_NETLOSS_EVENTS_REQUEST', {
      siteId,
      deviceId,
      stime,
    });
    console.debug('GET_DEALER_SITE_DEVICE_NETLOSS_EVENTS_REQUEST');
    try {
      // INIT - vars
      let netlossEvents = [];

      // GET 1 - device info to get the last online_updated_at
      const res1 = await ims.models.getDealerSiteDevice({
        dealerId,
        siteId,
        deviceId,
      });

      // UPDATE - is_online, is_online_update_time
      const { is_online, is_online_update_time } = res1.data;

      // PREPARE - lastNetlossEventOccurredAt
      const lastNetlossEventOccurredAt = Math.floor(
        new Date(is_online_update_time).getTime() / 1000
      );

      // ///////////////////////////////////////////////////////////
      // CASE 1 - if it doesn't need to retrieve real netlossEvents
      if (Number(stime) > lastNetlossEventOccurredAt) {
        // UPDATE - netlossEvents
        netlossEvents.push({
          type: 'netloss',
          status: is_online ? 'online' : 'offline',
          site_id: siteId,
          device_id: deviceId,
          created: is_online_update_time,
        });

        commit('GET_DEALER_SITE_DEVICE_NETLOSS_EVENTS_SUCCESS', {
          deviceId,
          data: netlossEvents,
        });
        console.debug('GET_DEALER_SITE_DEVICE_NETLOSS_EVENTS_SUCCESS');
        return netlossEvents;
      }

      // ///////////////////////////////////////////////////////////
      // CASE 2 - if it needs to retrieve real netlossEvents
      // GET 2 - events for 24 hours
      const filter = [
        'device_event.type,netloss,eq',
        `device_event.device_id,${deviceId},eq`,
      ];
      const params = {
        limit: 1000,
        dir: 'desc',
        sort: 'event_id',
        init: 'yes',
        filter,
        fields: [
          'device_event.event_id',
          'device_event.created',
          'device_event.status',
          'device_event.site_id',
          'device_event.device_id',
        ].join(','),
      };
      const res2 = await ims.models.getDealerSiteEvents({
        dealerId,
        siteId,
        stime,
        etime: Number(stime) + 60 * 60 * 24,
        params,
      });

      // UPDATE - firstEventId
      let firstEventId = null;
      if (ims._.isArray(res2.data)) {
        if (res2.data.length > 0) {
          firstEventId = res2.data[res2.data.length - 1].event_id;
        }
      }

      // GET 3 - just 1 event before the 24 hours
      if (firstEventId) {
        filter.push(`device_event.event_id,${firstEventId},lt`);
      }
      const res3 = await ims.models.getDealerSiteEvents({
        dealerId,
        siteId,
        etime: Number(stime) + 60 * 60 * 24,
        params: {
          ...params,
          limit: 1,
        },
      });

      // UPDATE - netlossEvents
      if (ims._.isArray(res2.data)) {
        netlossEvents = [...netlossEvents, ...res2.data];
      }
      if (ims._.isArray(res3.data)) {
        netlossEvents = [...netlossEvents, ...res3.data];
      }

      commit('GET_DEALER_SITE_DEVICE_NETLOSS_EVENTS_SUCCESS', {
        deviceId,
        data: netlossEvents,
      });
      console.debug('GET_DEALER_SITE_DEVICE_NETLOSS_EVENTS_SUCCESS');
      return netlossEvents;
    } catch (err) {
      commit('GET_DEALER_SITE_DEVICE_NETLOSS_EVENTS_FAILURE', { deviceId });
      console.debug('GET_DEALER_SITE_DEVICE_NETLOSS_EVENTS_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITE_DEVICE_NETLOSS_EVENTS_REQUEST: function (
    state,
    { siteId, deviceId, stime }
  ) {
    //
    if (state.siteId !== siteId || state.stime !== stime) {
      state.siteId = siteId;
      state.stime = stime;
      state.dealerSiteDevicesNetlossEvents = {};
    }
    //
    if (!state.dealerSiteDevicesNetlossEvents)
      state.dealerSiteDevicesNetlossEvents = {};
    if (!state.statusGetDealerSiteDevicesNetlossEvents)
      state.statusGetDealerSiteDevicesNetlossEvents = {};
    //
    state.statusGetDealerSiteDevicesNetlossEvents[deviceId] = 'requested';
  },
  GET_DEALER_SITE_DEVICE_NETLOSS_EVENTS_SUCCESS: function (
    state,
    { deviceId, data }
  ) {
    state.dealerSiteDevicesNetlossEvents[deviceId] = data;
    state.statusGetDealerSiteDevicesNetlossEvents[deviceId] = 'successful';
  },
  GET_DEALER_SITE_DEVICE_NETLOSS_EVENTS_FAILURE: function (
    state,
    { deviceId }
  ) {
    state.statusGetDealerSiteDevicesNetlossEvents[deviceId] = 'failed';
  },
};

export default {
  dealerSiteDevicesNetlossEvents: {
    state,
    getters,
    actions,
    mutations,
  },
};

// [
//   {
//     "type": "netloss",
//     "status": "online",
//     "site_id": 34019,
//     "device_id": "174258",
//     "event_id": "39096434",
//     "created": "2023-04-05T08:51:41.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "site_id": 34019,
//     "device_id": "174258",
//     "event_id": "39096411",
//     "created": "2023-04-05T08:48:41.000Z"
//   },
//   {
//     "type": "netloss",
//     "device_id": "174041",
//     "status": "online",
//     "site_id": 34019,
//     "event_id": "39095024",
//     "created": "2023-04-05T03:14:30.000Z"
//   },
//   {
//     "type": "netloss",
//     "site_id": 34019,
//     "status": "online",
//     "device_id": "174258",
//     "event_id": "39095023",
//     "created": "2023-04-05T03:13:30.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "174041",
//     "site_id": 34019,
//     "event_id": "39095019",
//     "created": "2023-04-05T03:11:30.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "174041",
//     "site_id": 34019,
//     "event_id": "39095000",
//     "created": "2023-04-05T03:10:55.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "174041",
//     "site_id": 34019,
//     "event_id": "39094999",
//     "created": "2023-04-05T03:10:55.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "174041",
//     "site_id": 34019,
//     "event_id": "39094998",
//     "created": "2023-04-05T03:10:55.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "173991",
//     "site_id": 34019,
//     "event_id": "39094997",
//     "created": "2023-04-05T03:09:20.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "site_id": 34019,
//     "device_id": "174258",
//     "event_id": "39094996",
//     "created": "2023-04-05T03:09:00.000Z"
//   },
//   {
//     "type": "netloss",
//     "device_id": "174041",
//     "status": "online",
//     "site_id": 34019,
//     "event_id": "39094986",
//     "created": "2023-04-05T03:05:04.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "174041",
//     "site_id": 34019,
//     "event_id": "39094947",
//     "created": "2023-04-05T02:57:51.000Z"
//   },
//   {
//     "type": "netloss",
//     "device_id": "173991",
//     "status": "online",
//     "site_id": 34019,
//     "event_id": "39094909",
//     "created": "2023-04-05T02:51:20.000Z"
//   },
//   {
//     "type": "netloss",
//     "site_id": 34019,
//     "status": "online",
//     "device_id": "174258",
//     "event_id": "39094897",
//     "created": "2023-04-05T02:50:02.000Z"
//   },
//   {
//     "type": "netloss",
//     "device_id": "174253",
//     "status": "online",
//     "site_id": 34019,
//     "event_id": "39094896",
//     "created": "2023-04-05T02:49:59.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "174253",
//     "site_id": 34019,
//     "event_id": "39094886",
//     "created": "2023-04-05T02:48:32.000Z"
//   },
//   {
//     "type": "netloss",
//     "device_id": "174253",
//     "status": "online",
//     "site_id": 34019,
//     "event_id": "39094883",
//     "created": "2023-04-05T02:48:03.000Z"
//   },
//   {
//     "type": "netloss",
//     "device_id": "173083",
//     "status": "online",
//     "site_id": 34019,
//     "event_id": "39094880",
//     "created": "2023-04-05T02:47:47.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "174253",
//     "site_id": 34019,
//     "event_id": "39094876",
//     "created": "2023-04-05T02:47:02.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "173083",
//     "site_id": 34019,
//     "event_id": "39094875",
//     "created": "2023-04-05T02:47:02.000Z"
//   },
//   {
//     "type": "netloss",
//     "device_id": "174253",
//     "status": "online",
//     "site_id": 34019,
//     "event_id": "39094871",
//     "created": "2023-04-05T02:45:54.000Z"
//   },
//   {
//     "type": "netloss",
//     "device_id": "173083",
//     "status": "online",
//     "site_id": 34019,
//     "event_id": "39094864",
//     "created": "2023-04-05T02:45:42.000Z"
//   },
//   {
//     "type": "netloss",
//     "device_id": "174041",
//     "status": "online",
//     "site_id": 34019,
//     "event_id": "39094854",
//     "created": "2023-04-05T02:43:12.000Z"
//   },
//   {
//     "type": "netloss",
//     "device_id": "174042",
//     "status": "online",
//     "site_id": 34019,
//     "event_id": "39094853",
//     "created": "2023-04-05T02:43:11.000Z"
//   },
//   {
//     "type": "netloss",
//     "device_id": "172962",
//     "status": "online",
//     "site_id": 34019,
//     "event_id": "39094850",
//     "created": "2023-04-05T02:43:03.000Z"
//   },
//   {
//     "type": "netloss",
//     "device_id": "172963",
//     "status": "online",
//     "site_id": 34019,
//     "event_id": "39094849",
//     "created": "2023-04-05T02:42:59.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "site_id": 34019,
//     "device_id": "174258",
//     "event_id": "39094835",
//     "created": "2023-04-05T02:41:21.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "174253",
//     "site_id": 34019,
//     "event_id": "39094834",
//     "created": "2023-04-05T02:41:21.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "173991",
//     "site_id": 34019,
//     "event_id": "39094833",
//     "created": "2023-04-05T02:41:21.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "173083",
//     "site_id": 34019,
//     "event_id": "39094832",
//     "created": "2023-04-05T02:41:21.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "172962",
//     "site_id": 34019,
//     "event_id": "39094828",
//     "created": "2023-04-05T02:41:03.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "174042",
//     "site_id": 34019,
//     "event_id": "39094827",
//     "created": "2023-04-05T02:41:00.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "174041",
//     "site_id": 34019,
//     "event_id": "39094826",
//     "created": "2023-04-05T02:41:00.000Z"
//   },
//   {
//     "type": "netloss",
//     "status": "offline",
//     "device_id": "172963",
//     "site_id": 34019,
//     "event_id": "39094825",
//     "created": "2023-04-05T02:41:00.000Z"
//   }
// ]
