import ims from '../../../../../imports';

export default {
  getDealerSiteCameras: function ({ dealerId, siteId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/devices/cameras`,
    });
  },
};
