import ims from '../../../../../imports';

// STATE - default
let initialState = {
  dealerSiteCameras: null,
  statusGetDealerSiteCameras: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSiteCameras: function (state) {
    return state.dealerSiteCameras ?? {};
  },
  statusGetDealerSiteCameras: function (state) {
    return state.statusGetDealerSiteCameras;
  },
};

// ACTIONS
const actions = {
  getDealerSiteCameras: async function (
    { commit, getters },
    { dealerId, siteId }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSiteCameras === 'requested') return;
    commit('GET_DEALER_SITE_CAMERAS_REQUEST');
    console.debug('GET_DEALER_SITE_CAMERAS_REQUEST');
    try {
      const res = await ims.models.getDealerSiteCameras({
        dealerId,
        siteId,
      });
      commit('GET_DEALER_SITE_CAMERAS_SUCCESS', res.data);
      console.debug('GET_DEALER_SITE_CAMERAS_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_DEALER_SITE_CAMERAS_FAILURE');
      console.debug('GET_DEALER_SITE_CAMERAS_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITE_CAMERAS_REQUEST: function (state) {
    state.statusGetDealerSiteCameras = 'requested';
  },
  GET_DEALER_SITE_CAMERAS_SUCCESS: function (state, data) {
    state.dealerSiteCameras = data;
    state.statusGetDealerSiteCameras = 'successful';
  },
  GET_DEALER_SITE_CAMERAS_FAILURE: function (state) {
    state.statusGetDealerSiteCameras = 'failed';
  },
};

export default {
  dealerSiteCameras: {
    state,
    getters,
    actions,
    mutations,
  },
};
