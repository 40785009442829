import ims from '../imports';

export default {
  connectCommandCenter: function (siteId) {
    // CHECK - proceed only when disconnected
    if (ims.command_center.siteId === siteId) {
      if (ims.command_center.connected) return;
    }

    // GET & CHECK - access_token - best effort
    const access_token = ims.store.getters.userMeDealer?.access_token;
    if (!access_token) return;

    // GET & CHECK - siteId - best effort
    if (!siteId) siteId = ims.store.getters.dealerSite?.site_id;
    if (!siteId) siteId = ims.store.getters.dealerAccounts?.site_id;
    if (!siteId) return;

    // CONNECT & SUBSCRIBE
    ims.command_center.connectAndSubscribe(access_token, siteId);
  },
  disconnectCommandCenter: function () {
    // DISCONNECT
    ims.command_center.disconnect();
  },
};
