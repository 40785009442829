import ims from '../../../../../../imports';

// STATE - default
let initialState = {
  dealerSiteTTS: null,
  statusGetDealerSiteTTS: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSiteTTS: function (state) {
    return state.dealerSiteTTS ?? {};
  },
  statusGetDealerSiteTTS: function (state) {
    return state.statusGetDealerSiteTTS;
  },
};

// ACTIONS
const actions = {
  getDealerSiteTTS: async function (
    { commit, getters },
    { dealerId, siteId, ttsId }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSiteTTS === 'requested') return;
    commit('GET_DEALER_SITE_TTS_REQUEST');
    console.debug('GET_DEALER_SITE_TTS_REQUEST');
    try {
      const res = await ims.models.getDealerSiteTTS({
        dealerId,
        siteId,
        ttsId,
      });
      commit('GET_DEALER_SITE_TTS_SUCCESS', res.data);
      console.debug('GET_DEALER_SITE_TTS_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_DEALER_SITE_TTS_FAILURE');
      console.debug('GET_DEALER_SITE_TTS_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITE_TTS_REQUEST: function (state) {
    state.statusGetDealerSiteTTS = 'requested';
  },
  GET_DEALER_SITE_TTS_SUCCESS: function (state, data) {
    state.dealerSiteTTS = data;
    state.statusGetDealerSiteTTS = 'successful';
  },
  GET_DEALER_SITE_TTS_FAILURE: function (state) {
    state.statusGetDealerSiteTTS = 'failed';
  },
};

export default {
  dealerSiteTTS: {
    state,
    getters,
    actions,
    mutations,
  },
};
