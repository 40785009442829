// STATE - default
let initialState = {
  newEventCounter: 0,
  privacyStateChangeCounter: 0,
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  newEventCounter: function (state) {
    return state.newEventCounter;
  },
  privacyStateChangeCounter: function (state) {
    return state.privacyStateChangeCounter;
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  INCREASE_NEW_EVENT_COUNTER: function (state) {
    state.newEventCounter++;
  },
  INCREASE_PRIVACY_STATE_CHANGE_COUNTER: function (state) {
    state.privacyStateChangeCounter++;
  },
};

export default {
  uxCommandCenter: {
    state,
    getters,
    mutations,
  },
};
