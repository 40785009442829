import ims from '../imports';

// STATE - default
let initialState = {
  urlsObjectURL: null,
  statusGetURLsObjectURL: null,
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  urlsObjectURL: function (state) {
    return state.urlsObjectURL ?? {};
  },
  urlObjectURL: function (state, getters) {
    return function (url) {
      return getters.urlsObjectURL[url];
    };
  },
  statusGetURLsObjectURL: function (state) {
    return state.statusGetURLsObjectURL ?? {};
  },
  statusGetURLObjectURL: function (state, getters) {
    return function (url) {
      return getters.statusGetURLsObjectURL[url];
    };
  },
};

// ACTIONS
const actions = {
  getURLObjectURL: async function ({ commit, getters }, { url }) {
    if (getters.urlObjectURL(url)) return getters.urlObjectURL(url);
    if (getters.statusGetURLObjectURL(url) === 'requested') return;
    commit('GET_URL_OBJECTURL_REQUEST', { url });
    try {
      const objectURL = await ims.tool.urlToObjectURL(url);
      commit('GET_URL_OBJECTURL_SUCCESS', { url, objectURL });
      return objectURL;
    } catch (err) {
      commit('GET_URL_OBJECTURL_FAILURE', { url });
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_URL_OBJECTURL_REQUEST: function (state, { url }) {
    if (!state.statusGetURLsObjectURL) state.statusGetURLsObjectURL = {};
    state.statusGetURLsObjectURL[url] = 'requested';
  },
  GET_URL_OBJECTURL_SUCCESS: function (state, { url, objectURL }) {
    if (!state.urlsObjectURL) state.urlsObjectURL = {};
    state.urlsObjectURL[url] = objectURL;
    state.statusGetURLsObjectURL[url] = 'successful';
  },
  GET_URL_OBJECTURL_FAILURE: function (state, { url }) {
    state.statusGetURLsObjectURL[url] = 'failed';
  },
};

export default {
  urlsObjectURL: {
    state,
    getters,
    actions,
    mutations,
  },
};
