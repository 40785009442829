import ims from '../../../../../../imports';
import events from './events';

// STATE - default
let initialState = {
  dealerSiteDevice: null,
  statusGetDealerSiteDevice: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSiteDevice: function (state) {
    return state.dealerSiteDevice ?? {};
  },
  statusGetDealerSiteDevice: function (state) {
    return state.statusGetDealerSiteDevice;
  },
};

// ACTIONS
const actions = {
  getDealerSiteDevice: async function (
    { commit, getters },
    { dealerId, siteId, deviceId }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSiteDevice === 'requested') return;
    commit('GET_DEALER_SITE_DEVICE_REQUEST');
    console.debug('GET_DEALER_SITE_DEVICE_REQUEST');
    try {
      const res = await ims.models.getDealerSiteDevice({
        dealerId,
        siteId,
        deviceId,
      });
      commit('GET_DEALER_SITE_DEVICE_SUCCESS', res.data);
      console.debug('GET_DEALER_SITE_DEVICE_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_DEALER_SITE_DEVICE_FAILURE');
      console.debug('GET_DEALER_SITE_DEVICE_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITE_DEVICE_REQUEST: function (state) {
    state.statusGetDealerSiteDevice = 'requested';
  },
  GET_DEALER_SITE_DEVICE_SUCCESS: function (state, data) {
    state.dealerSiteDevice = data;
    state.statusGetDealerSiteDevice = 'successful';
  },
  GET_DEALER_SITE_DEVICE_FAILURE: function (state) {
    state.statusGetDealerSiteDevice = 'failed';
  },
};

export default {
  ...events,
  dealerSiteDevice: {
    state,
    getters,
    actions,
    mutations,
  },
};
