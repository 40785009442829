<template lang="pug">
.bg-black.no-scroll.flex.flex-center(:style="{ width: size, height: size }")
  img(:src="src", style="height: 100%")
</template>

<style>
</style>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "48px",
    },
  },
};
</script>
