<template lang="pug">
apis
command_center
errors
events
pathes
</template>

<style>
</style>

<script>
import apis from "./apis.vue";
import command_center from "./command_center.vue";
import errors from "./errors.vue";
import events from "./events.vue";
import pathes from "./pathes.vue";

export default {
  components: {
    apis,
    command_center,
    errors,
    events,
    pathes,
  },
};
</script>
