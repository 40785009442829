import ims from '../../../../../imports';

export default {
  createDealerSiteLiveviewLog: function ({ dealerId, siteId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'post',
      url: `/dealers/${dealerId}/sites/${siteId}/liveview`,
      withCredentials: true,
    });
  },
};
