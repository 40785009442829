<template lang="pug">
.flex.row.no-wrap.scroll(style="width: 100%; height: 85px")
  template(v-for="(v, k) in annotation?.frames_detections")
    .flex.column.flex-center.no-wrap.bg-black.text-white(v-for="(vv, ii) in v")
      //- CASE 1 - privacy mode
      template(v-if="event?.is_privacy > 0")
        template(v-if="vv.entity_id === 'person'")
          c_avatar_102(size="50px", :src="ims.tool.imagePlaceHolderPerson({})")
        template(v-else-if="vv.entity_id === 'vehicle'")
          c_avatar_102(
            size="50px",
            :src="ims.tool.imagePlaceHolderVehicle({})"
          )
        template(v-else)
          c_avatar_102(size="50px", :src="ims.tool.imagePlaceHolder({})")
        .text-caption.text-center(style="width: 100%") {{ (vv.confidence * 100 * 1.0).toFixed(0) }}%

      //- CASE 2 - NOT privacy mode
      template(v-else)
        //- ONLY - when there is thumbnail
        template(v-if="vv.thumbnail")
          c_avatar_102(
            size="50px",
            :src="`data:image/jpeg;base64,${vv.thumbnail}`"
          )
          .text-caption.text-center(style="width: 100%") {{ (vv.confidence * 100 * 1.0).toFixed(0) }}%
</template>

<style>
</style>

<script>
import ims from "../../imports";
import c_avatar_102 from "../../img/c_avatar_102.vue";
export default {
  components: {
    c_avatar_102,
  },
  props: {
    siteId: {
      type: String,
      default: "",
    },
    eventId: {
      type: String,
      default: "",
    },
  },
  computed: {
    ims: function () {
      return ims;
    },
    annotation: function () {
      return this.$store.getters.dealerSiteEventAnnotation(this.eventId);
    },
    event: function () {
      return this.$store.getters.dealerSiteEvent24h(this.eventId);
    },
  },
  watch: {
    event: function () {
      this.loadData();
    },
  },
  mounted: function () {
    this.loadData();
  },
  methods: {
    loadData: function () {
      // CHECK
      if (!this.siteId) return;
      if (!this.eventId) return;

      // GET - event annotation
      if (this.event?.videoai_result !== "success") return;
      return ims.helper.loadSiteEventAnnotation(this.siteId, this.eventId);
    },
  },
};
</script>
