import ims from '../../../../../imports';

export default {
  sendDealerSitePSAPEmail: function ({
    dealerId,
    siteId,
    deviceId,
    eventId,
    from,
    to,
    link,
    shareType,
    timestamp,
  }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'post',
      url: `/dealers/${dealerId}/sites/${siteId}/psapsendemail`,
      data: ims.tool.removeEmptyKey({
        device_id: deviceId,
        event_id: eventId,
        from, // user email
        to, // user email
        link, // share link
        share_type: shareType,
        timestamp,
      }),
    });
  },
  sendDealerSitePSAPSms: function ({
    dealerId,
    siteId,
    deviceId,
    eventId,
    to,
    link,
    timestamp,
  }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'post',
      url: `/dealers/${dealerId}/sites/${siteId}/psapsendsms`,
      data: ims.tool.removeEmptyKey({
        device_id: deviceId,
        event_id: eventId,
        to, // user email
        link, // share link
        timestamp,
      }),
    });
  },
};
