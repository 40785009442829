export default {
  getEnv: function () {
    const currentDomain = String(window.location.hostname);
    let env = localStorage.getItem('chekt::env') || 'dev';
    // filter - PROD
    ['chekt.com', 'chekt.name', 'app.local'].forEach((v) => {
      if (!currentDomain.includes(v)) return;
      env = 'prod';
    });
    return env;
  },
  logLevel: {
    dev: 'debug',
    prod: 'info',
  },
  api: {
    dev: {
      prefix: {
        v1: 'https://api.chektdev.com/api/v1',
        'v1.1': 'https://api.chektdev.com/api/v1.1',
        v2: 'https://api.chektdev.com/api/v2',
      },
    },
    prod: {
      prefix: {
        v1: 'https://api.chekt.com/api/v1',
        'v1.1': 'https://api.chekt.com/api/v1.1',
        v2: 'https://api.chekt.com/api/v2',
      },
    },
  },
  command_center_url: {
    dev: 'mqtts://commandcenter.chektdev.com:443',
    prod: 'mqtts://commandcenter.chekt.com:443',
  },
  auth0_auth: {
    dev: {
      domain: 'auth.chektdev.com',
      client_id: 'yeJIohNks41mYAiaID29JoxsZESOpEQ8',
    },
    prod: {
      domain: 'auth.chekt.com',
      client_id: 'bHzjpwmWpjwdOukz2h7FM2u2ND5BPKFa',
    },
  },
  project_id: {
    dev: 'chekt-app-monitoring-nonprod',
    prod: 'chekt-app-monitoring-prod',
  },
  updateEnvironmentalVariablesGlobally: function () {
    // GET - env
    const env = this.getEnv();

    // UPDATE - only insensitive information
    window.env = env;
    window.logLevel = this.logLevel[env];
    window.chekt_project_id = this.project_id[env];
    window.app_start_time = new Date();

    // RETURN - env - for convenience
    return env;
  },
};
