import ims from '../../../../../imports';
import actions from './actions';

export default {
  ...actions,
  getDealerSiteAudioDevices: function ({ dealerId, siteId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/audios`,
    });
  },
};
