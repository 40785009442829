import ims from '../../../../../../imports';
import annotation from './annotation';
import records from './records';

// STATE - default
let initialState = {
  dealerSiteEvent: null,
  statusGetDealerSiteEvent: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSiteEvent: function (state) {
    return state.dealerSiteEvent ?? {};
  },
  statusGetDealerSiteEvent: function (state) {
    return state.statusGetDealerSiteEvent;
  },
};

// ACTIONS
const actions = {
  getDealerSiteEvent: async function (
    { commit, getters },
    { dealerId, siteId, eventId }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSiteEvent === 'requested') return;
    commit('GET_DEALER_SITE_EVENT_REQUEST');
    console.debug('GET_DEALER_SITE_EVENT_REQUEST: ', eventId);
    try {
      const res = await ims.models.getDealerSiteEvent({
        dealerId,
        siteId,
        eventId,
      });
      commit('GET_DEALER_SITE_EVENT_SUCCESS', res.data[0]);
      console.debug('GET_DEALER_SITE_EVENT_SUCCESS: ', eventId);
      return res.data[0];
    } catch (err) {
      commit('GET_DEALER_SITE_EVENT_FAILURE');
      console.debug('GET_DEALER_SITE_EVENT_FAILURE: ', eventId, err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITE_EVENT_REQUEST: function (state) {
    state.statusGetDealerSiteEvent = 'requested';
  },
  GET_DEALER_SITE_EVENT_SUCCESS: function (state, data) {
    state.dealerSiteEvent = data;
    state.statusGetDealerSiteEvent = 'successful';
  },
  GET_DEALER_SITE_EVENT_FAILURE: function (state) {
    state.statusGetDealerSiteEvent = 'failed';
  },
};

export default {
  ...annotation,
  ...records,
  dealerSiteEvent: {
    state,
    getters,
    actions,
    mutations,
  },
};
