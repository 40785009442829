import dealers from './dealers';
import statistics from './statistics';
import users from './users';

console.debug('✅ 5models loaded');

export default {
  ...dealers,
  ...statistics,
  ...users,
};
