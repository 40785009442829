<template lang="pug">
q-dialog(ref="dialog")
  .bg-grey-2.q-pa-sm(style="max-width: 300px; width: 300px")
    template(
      v-if="isTSSession && !permissions.hasPermissionToAccessMonitoringPortal"
    )
      .flex.column.flex-center.bg-white.q-pa-lg
        .text-body1 TS session to the dealer({{ tsSessionDealerId }}) is not allowed. Please request a TS session again or check permission of your account with the administrator.
    template(v-else)
      //- PROFILE
      .flex.column.flex-center.bg-white.q-pa-lg.shadow-1.rounded-borders
        q-spinner(v-if="loading")
        template(v-else)
          q-avatar.bg-primary(size="98px")
            .text-caption.text-white(v-if="isTSSession") TS Session
            img(v-else, :src="me.pictureUrl")
          .text-body1 {{ me.name }}
          .text-caption {{ me.email }}
          .text-caption {{ connection.ip }}
          .flex.flex-center.q-gutter-xs
            q-icon(name="business", size="1.2em")
            .text-body1 {{ dealer.name }}

      //- PERMISSIONS
      .flex.column.flex-center.bg-white.q-pa-sm.q-mt-sm.shadow-1.rounded-borders
        .q-gutter-xs
          .q-pa-sm.bg-grey-2.rounded-borders.flex.flex-center.text-weight-bold.text-uppercase(
            v-for="(v, k) in permissions",
            style="border: 1px solid #ddd; font-size: 11px"
          )
            .text-grey-10(style="width: calc(100% - 36px)") {{ ims.tool.camelToUpperCase(k) }}
            q-space
            q-spinner(v-if="loading")
            .q-px-sm.q-py-xs.bg-primary.text-white.rounded-borders(
              v-else,
              :class="{ 'bg-negative': !v }"
            ) {{ v ? "yes" : "no" }}

      //- BTN
      q-btn.q-mt-sm.full-width(
        label="Logout",
        no-caps,
        unelevated,
        color="negative",
        @click="onClickLogout",
        :loading="loading"
      )
</template>

<style>
</style>

<script>
import ims from "../imports";
export default {
  emits: ["ok"],
  computed: {
    ims: function () {
      return ims;
    },
    userMeDealer: function () {
      return this.$store.getters.userMeDealer;
    },
    userMeIp: function () {
      return this.$store.getters.userMeIp;
    },
    statusGetUserMeDealer: function () {
      return this.$store.getters.statusGetUserMeDealer;
    },
    statusGetUserMeIp: function () {
      return this.$store.getters.statusGetUserMeIp;
    },
    tsSessionDealerId: function () {
      return this.$route.query?.tsSessionDealerId;
    },
    isTSSession: function () {
      return !!this.tsSessionDealerId;
    },
    loading: function () {
      if (this.statusGetUserMeDealer === "requested") return true;
      if (this.statusGetUserMeIp === "requested") return true;
      return false;
    },
  },
  data: function () {
    return {
      me: {
        email: "",
        name: "",
        pictureUrl: "",
      },
      dealer: {
        id: "",
        name: "",
      },
      connection: {
        ip: "",
      },
      permissions: {
        isValidAccount: false,
        isValidDealer: false,
        isAllowedIpAddress: false,
        hasPermissionToAccessMonitoringPortal: false,
      },
    };
  },
  watch: {
    userMeDealer: function () {
      this.initVars();
    },
    userMeIp: function () {
      this.initVars();
    },
  },
  beforeMount: function () {
    this.initVars();
    this.init();
  },
  methods: {
    init: function () {
      this.$store.dispatch("getUserMeIp");
      this.$store.dispatch("getUserMeDealer", {
        tsSessionDealerId: this.tsSessionDealerId,
      });
    },
    initVars: function () {
      // me
      this.me.email = this.userMeDealer?.email;
      this.me.name = `${this.userMeDealer?.firstname} ${this.userMeDealer?.lastname}`;
      this.me.pictureUrl = this.userMeDealer?.provider_picture;
      // dealer
      this.dealer.id = this.userMeDealer?.dealer_id;
      this.dealer.name = this.userMeDealer?.company_name;
      // permissions
      this.permissions.isValidAccount = this.userMeDealer?.is_verify === 1;
      this.permissions.isValidDealer = !!this.userMeDealer?.created;
      this.permissions.isAllowedIpAddress = !this.userMeDealer?.is_ip_blocked;
      this.permissions.hasPermissionToAccessMonitoringPortal =
        this.userMeDealer?.app_access_permissions?.has_access_monitoring_app;
      // connection
      this.connection.ip = this.userMeIp?.ip;
      // ts sesssion - 'getUserMeDealer' doesn't respond, the user has no permission
      if (this.isTSSession) {
        this.permissions.hasPermissionToAccessMonitoringPortal ==
          !!this.userMeDealer?.dealer_id;
      }
    },
    onClickOkay: function () {
      this.$emit("ok");
      this.hide();
    },
    hide: function () {
      const ref = this.$refs["dialog"];
      if (!(ref instanceof Object)) return;
      ref.hide();
    },
    onClickLogout: function () {
      ims.auth0_auth.logout();
    },
  },
};
</script>
