<template lang="pug">
svg.absolute(
  viewBox="0 0 16 9",
  style="width: 100%; height: 100%; top: 0; left: 0"
)
  g(v-for="(v, i) in areas", :key="i")
    polygon(
      :points="v",
      style="stroke: #4da8f7; stroke-width: 0.04; fill: #4da8f777"
    )
</template>

<style>
</style>

<script>
export default {
  components: {},
  props: {
    eventId: {
      type: String,
      default: "",
    },
  },
  computed: {
    dealerSiteDevices: function () {
      return this.$store.getters.dealerSiteDevices;
    },
    device: function () {
      return this.dealerSiteDevices[this.event?.device_id];
    },
    event: function () {
      return this.$store.getters.dealerSiteEvent24h(this.eventId);
    },
    annotation: function () {
      return this.$store.getters.dealerSiteEventAnnotation(this.eventId);
    },
  },
  data: function () {
    return {
      areas: [],
    };
  },
  watch: {
    device: function () {
      this.updateAreas();
    },
    annotation: function () {
      this.updateAreas();
    },
  },
  mounted: function () {
    this.updateAreas();
  },
  methods: {
    updateAreas: function () {
      // INIT
      this.areas = [];
      let area_filters = null;

      if (this.annotation?.is_video_ai_filter_enabled !== null) {
        // IF - FW supports -  uploading area_filters of each event
        // APPLY - event level area filters (priority 1)
        if (this.annotation?.is_video_ai_filter_enabled === 1) {
          if (this.annotation?.area_filters)
            area_filters = this.annotation.area_filters;
        }
      } else {
        // IF - FW doesn't support -  uploading area_filters of each event
        // APPLY - device level area filters (priority 2)
        if (!area_filters) area_filters = this.device?.videoai_area_filter_meta;
      }
      if (!area_filters) return;

      // HAS - area filters
      area_filters.forEach((v) => {
        let area = [];
        v.points?.forEach((vv) => {
          if (vv.length != 2) return;
          area.push(`${vv[0] * 16}, ${vv[1] * 9}`);
        });
        this.areas.push(area.join(" "));
      });
    },
  },
};
</script>
