import mqtt from 'mqtt';
import config from '../1config';

const ccObj = {
  connected: false,
  topics: {},
  client: null,
  siteId: '',
  subscribe: function (topic) {
    if (!this.connected) return;
    this.client.subscribe(topic, { qos: 1 });
    this.topics[topic] = true;
  },
  unsubscribe: function (topic) {
    if (!this.connected) return;
    this.client.unsubscribe(topic, { qos: 1 });
    delete this.topics[topic];
  },
  subscribeSite: function (siteId) {
    if (!this.connected) return;
    this.subscribe(`chekt/sites/${siteId}`);
  },
  unsubscribeSite: function (siteId) {
    this.unsubscribe(`chekt/sites/${siteId}`);
  },
  unsubscribeAll: function () {
    Object.keys(this.topics).forEach((topic) => {
      this.unsubscribe(topic);
    });
  },
  addEventListenerConnect: function (siteId) {
    this.client.on(
      'connect',
      function () {
        this.connected = true;
        if (siteId) this.subscribeSite(siteId);
        window.dispatchEvent(
          new CustomEvent('c-command-center', {
            detail: { status: 'connect', siteId },
          })
        );
      }.bind(this)
    );
  },
  addEventListenerError: function (siteId) {
    this.client.on(
      'error',
      function (error) {
        this.connected = false;
        window.dispatchEvent(
          new CustomEvent('c-command-center', {
            detail: { status: 'error', error, siteId },
          })
        );
      }.bind(this)
    );
  },
  addEventListenerOffline: function (siteId) {
    this.client.on(
      'offline',
      function () {
        this.connected = false;
        window.dispatchEvent(
          new CustomEvent('c-command-center', {
            detail: { status: 'offline', siteId },
          })
        );
      }.bind(this)
    );
  },
  addEventListenerMessage: function (siteId) {
    this.client.on('message', function (topic, message) {
      window.dispatchEvent(
        new CustomEvent('c-command-center', {
          detail: {
            status: 'message',
            siteId,
            topic,
            message: message.toString(),
          },
        })
      );
    });
  },
  connect: function (password) {
    const client = mqtt.connect(config.command_center_url, {
      username: 'app',
      password,
      clientId: 'monitoring_web_' + Math.random().toString(16).substring(2, 10),
      keepalive: 50,
      reconnectPeriod: 1000 * 5,
    });
    this.client = client;
  },
  disconnect: function () {
    if (this.client) this.client.end();
    this.siteId = '';
    this.client = null;
  },
  connectAndSubscribe: function (password, siteId) {
    this.siteId = siteId;
    this.disconnect();
    this.connect(password);
    this.addEventListenerConnect(siteId);
    this.addEventListenerError(siteId);
    this.addEventListenerOffline(siteId);
    this.addEventListenerMessage(siteId);
  },
};

export default ccObj;
