import ims from '../../../../../../../../../imports';

export default {
  requestDealerSiteDeviceEventVerification: function ({
    dealerId,
    siteId,
    deviceId,
    eventId,
  }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'post',
      url: `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/events/${eventId}/verifications`,
    });
  },
};
