import ims from '../../../../imports';

export default {
  getDealerPartner: function ({ dealerId, partnerId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/partners/${partnerId}`,
    });
  },
};
