export default {
  parseJSON: function (str) {
    let res = null;
    try {
      res = JSON.parse(str);
    } catch (err) {
      console.log(err?.message);
    }
    return res;
  },
  stringifyJSON: function (obj) {
    let res = null;
    try {
      res = JSON.stringify(obj);
    } catch (err) {
      console.log(err?.message);
    }
    return res;
  },
};
