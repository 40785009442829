import moment from 'moment-timezone';

export default {
  timeSinceInSecond: function (timestamp) {
    const now = new Date().getTime();
    const old = new Date(timestamp * 1000).getTime();
    return (now - old) / 1000;
  },
  getLocaleDateTimeString: function (timestamp, timezone = 'UTC') {
    return moment(timestamp).tz(timezone).format('ddd, YYYY-MM-DDTHH:mm:ss Z');
  },
  // today date string for `q-date`
  todayMyLocal: function () {
    const now = new Date();
    const nowNormalized = now.getTime() - now.getTimezoneOffset() * 60 * 1000;
    return new Date(nowNormalized)
      .toISOString()
      .split('T')[0]
      .replaceAll('-', '/');
  },
  todayLocal: function (timezone = 'UTC') {
    return moment().tz(timezone).format().split('T')[0].replaceAll('-', '/');
  },
  //
  getTimezoneOffsetStr: function (timezone) {
    return moment.tz(timezone).format('Z');
  },
  getDateLocal: function (date, timezone) {
    date = date.replaceAll('/', '-');
    return moment.tz(date, timezone).hour(0).minute(0).second(0);
  },
  getDateTimestampLocal: function (date, timezone) {
    return this.getDateLocal(date, timezone).unix();
  },
  getDateKeyWithoutUTCOffset: function (date) {
    const dateKey = this.getDateLocal(date, 'UTC').format();
    return dateKey.replace(/[zZ]|[+-][0-9]{2}:[0-9]{2}/g, '');
  },
};
