import ims from '../../../../../imports';
import removed_device from './removed_device';

export default {
  ...removed_device,
  getDealerSiteRemovedDevices: function ({ dealerId, siteId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/removed_devices`,
    });
  },
};
