import monitoringRoutes from './monitoring/0index';
import videoaiRoutes from './videoai/0index';

const routes = [
  {
    path: '/',
    redirect: '/videoai',
  },
  ...monitoringRoutes,
  ...videoaiRoutes,
];

console.debug('✅ 3routes loaded');

import { createRouter, createWebHistory } from 'vue-router';
export default createRouter({
  history: createWebHistory(),
  routes,
});
