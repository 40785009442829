import ims from '../../../../../imports';
import event from './event';

// STATE - default
let initialState = {
  siteId: '',
  stime: '',
  dealerSiteEvents: null,
  dealerSiteEvents24h: null,
  dealerSiteEventsRecent: null,
  statusGetDealerSiteEvents: '',
  statusGetDealerSiteEvents24h: '',
  statusGetDealerSiteEventsRecent: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSiteEvents: function (state) {
    return state.dealerSiteEvents ?? {};
  },
  dealerSiteEvents24h: function (state) {
    return state.dealerSiteEvents24h ?? {};
  },
  dealerSiteEvent24h: function (state, getters) {
    return function (eventId) {
      return getters.dealerSiteEvents24h[eventId];
    };
  },
  dealerSiteEventIds24h: function (state, getters) {
    return Object.keys(getters.dealerSiteEvents24h);
  },
  dealerSiteEventIds24hSortedDescending: function (state, getters) {
    const events = getters.dealerSiteEvents24h;
    return getters.dealerSiteEventIds24h.sort(function (a, b) {
      if (events[a].timestamp < events[b].timestamp) return 1;
      if (events[a].timestamp > events[b].timestamp) return -1;
      return 0;
    });
  },
  firstDealerSiteEventId24h: function (state, getters) {
    const eventIds = getters.dealerSiteEventIds24h.sort();
    return eventIds[0];
  },
  lastDealerSiteEventId24hWithoutVideoAIResult: function (state, getters) {
    const eventIds = getters.dealerSiteEventIds24h.sort().reverse();
    const events = getters.dealerSiteEvents24h;
    let lastEventId = '';
    for (let i = 0; i < eventIds.length; i++) {
      const eventId = eventIds[i];
      const event = events[eventId];
      if (!event?.videoai_result) continue;
      lastEventId = eventId;
      break;
    }
    return lastEventId;
  },
  //
  dealerSiteEventsRecent: function (state) {
    return state.dealerSiteEventsRecent ?? {};
  },
  dealerSiteEventIdsRecent: function (state, getters) {
    return Object.keys(getters.dealerSiteEventsRecent);
  },
  dealerSiteEventIdsRecentSortedDescending: function (state, getters) {
    const events = getters.dealerSiteEventsRecent;
    return getters.dealerSiteEventIdsRecent.sort(function (a, b) {
      if (events[a].timestamp < events[b].timestamp) return 1;
      if (events[a].timestamp > events[b].timestamp) return -1;
      return 0;
    });
  },
  dealerSiteEventIdsRecentSortedAscending: function (state, getters) {
    const events = getters.dealerSiteEventsRecent;
    return getters.dealerSiteEventIdsRecent.sort(function (a, b) {
      if (events[a].timestamp < events[b].timestamp) return -1;
      if (events[a].timestamp > events[b].timestamp) return 1;
      return 0;
    });
  },
  oldestUnviewedEventIdOfDeviceId: function (state, getters) {
    return function (deviceId) {
      const events = getters.dealerSiteEventsRecent;
      const eventIds = getters.dealerSiteEventIdsRecentSortedAscending;
      for (let i = 0; i < eventIds.length; i++) {
        const eventId = eventIds[i];
        const event = events[eventId];
        if (event.device_id !== deviceId) continue;
        if (event.watch > 0) continue;
        return eventId;
      }
      return null;
    };
  },
  latestEventIdOfDeviceId: function (state, getters) {
    return function (deviceId) {
      const events = getters.dealerSiteEventsRecent;
      const eventIds = getters.dealerSiteEventIdsRecentSortedDescending;
      for (let i = 0; i < eventIds.length; i++) {
        const eventId = eventIds[i];
        const event = events[eventId];
        if (event.device_id !== deviceId) continue;
        return eventId;
      }
      return null;
    };
  },
  statusGetDealerSiteEvents: function (state) {
    return state.statusGetDealerSiteEvents;
  },
  statusGetDealerSiteEvents24h: function (state) {
    return state.statusGetDealerSiteEvents24h;
  },
  statusGetDealerSiteEventsRecent: function (state) {
    return state.statusGetDealerSiteEventsRecent;
  },
};

// ACTIONS
const actions = {
  getDealerSiteEvents: async function (
    { commit, getters },
    { dealerId, siteId, stime, etime, params }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSiteEvents === 'requested') return;
    commit('GET_DEALER_SITE_EVENTS_REQUEST', siteId);
    console.debug('GET_DEALER_SITE_EVENTS_REQUEST');
    try {
      const res = await ims.models.getDealerSiteEvents({
        dealerId,
        siteId,
        stime,
        etime,
        params,
      });
      commit('GET_DEALER_SITE_EVENTS_SUCCESS', res.data);
      console.debug('GET_DEALER_SITE_EVENTS_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_DEALER_SITE_EVENTS_FAILURE');
      console.debug('GET_DEALER_SITE_EVENTS_FAILURE', err);
      throw err;
    }
  },
  getDealerSiteEvents24h: async function (
    { commit, getters },
    { dealerId, siteId, stime, params, getOlder }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSiteEvents24h === 'requested') return;
    // RESET - dealerSiteEvents24h if siteId changed
    commit('GET_DEALER_SITE_EVENTS_24H_REQUEST', { siteId, stime });
    console.debug('GET_DEALER_SITE_EVENTS_24H_REQUEST');
    // FILTER - to download only new event
    if (params?.filter) {
      if (getOlder) {
        const firstEventId = getters.firstDealerSiteEventId24h;
        if (firstEventId) {
          params.filter.push(`device_event.event_id,${firstEventId},lt`);
        }
      }
      if (!getOlder) {
        const lastEventId =
          getters.lastDealerSiteEventId24hWithoutVideoAIResult;
        if (lastEventId) {
          params.filter.push(`device_event.event_id,${lastEventId},gt`);
        }
      }
    }
    try {
      const res = await ims.models.getDealerSiteEvents({
        dealerId,
        siteId,
        stime,
        etime: Number(stime) + 60 * 60 * 24,
        params,
      });
      commit('GET_DEALER_SITE_EVENTS_24H_SUCCESS', res.data);
      console.debug('GET_DEALER_SITE_EVENTS_24H_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_DEALER_SITE_EVENTS_24H_FAILURE');
      console.debug('GET_DEALER_SITE_EVENTS_24H_FAILURE', err);
      throw err;
    }
  },
  getDealerSiteEventsRecent: async function (
    { commit, getters },
    { dealerId, siteId, secondsBefore, lastEventId, eventTypes, eventStatus }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSiteEventsRecent === 'requested') return;
    commit('GET_DEALER_SITE_EVENTS_RECENT_REQUEST', siteId);
    console.debug('GET_DEALER_SITE_EVENTS_RECENT_REQUEST');
    try {
      const res = await ims.models.getDealerSiteEventsRecent({
        dealerId,
        siteId,
        secondsBefore,
        lastEventId,
        eventTypes,
        eventStatus,
      });
      commit('GET_DEALER_SITE_EVENTS_RECENT_SUCCESS', res.data);
      console.debug('GET_DEALER_SITE_EVENTS_RECENT_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_DEALER_SITE_EVENTS_RECENT_FAILURE');
      console.debug('GET_DEALER_SITE_EVENTS_RECENT_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITE_EVENTS_REQUEST: function (state, siteId) {
    if (state.siteId !== siteId) {
      state.siteId = siteId;
      state.dealerSiteEvents = {};
    }
    state.statusGetDealerSiteEvents = 'requested';
  },
  GET_DEALER_SITE_EVENTS_SUCCESS: function (state, data) {
    state.dealerSiteEvents = data;
    state.statusGetDealerSiteEvents = 'successful';
  },
  GET_DEALER_SITE_EVENTS_FAILURE: function (state) {
    state.statusGetDealerSiteEvents = 'failed';
  },
  GET_DEALER_SITE_EVENTS_24H_REQUEST: function (state, { siteId, stime }) {
    if (state.siteId !== siteId || state.stime !== stime) {
      state.siteId = siteId;
      state.stime = stime;
      state.dealerSiteEvents24h = {};
    }
    state.statusGetDealerSiteEvents24h = 'requested';
  },
  GET_DEALER_SITE_EVENTS_24H_SUCCESS: function (state, data) {
    if (!state.dealerSiteEvents24h) state.dealerSiteEvents24h = {};
    data.forEach((v) => {
      // PREPARE - time_to_create
      const created = new Date(v.create_timestamp * 1000).getTime();
      const event_time = new Date(v.event_time).getTime();
      let time_to_create = null;
      if (!isNaN(created) && !isNaN(event_time)) {
        time_to_create = Math.round((created - event_time) / 1000);
      }

      // PREPARE - metadata
      const meta = v.meta ? ims.tool.parseJSON(v.meta) : null;

      // PREPARE - AI detection result
      const detection_summary = v.detection_summary
        ? ims.tool.parseJSON(v.detection_summary)
        : null;
      const summary_entity_ids = v.summary_entity_ids
        ? ims.tool.parseJSON(v.summary_entity_ids)
        : null;
      const summary_entity_names = v.summary_entity_names
        ? ims.tool.parseJSON(v.summary_entity_names)
        : null;
      const has_person =
        summary_entity_ids?.static?.includes('person') ||
        summary_entity_ids?.motion?.includes('person');
      const has_vehicle =
        summary_entity_ids?.static?.includes('vehicle') ||
        summary_entity_ids?.motion?.includes('vehicle');

      // UPDATE - dealerSiteEvents24h
      state.dealerSiteEvents24h[v.event_id] = {
        ...v,
        time_to_create,
        meta,
        summary_entity_ids,
        summary_entity_names,
        has_person,
        has_vehicle,
        detection_summary,
      };
    });
    state.statusGetDealerSiteEvents24h = 'successful';
  },
  GET_DEALER_SITE_EVENTS_24H_FAILURE: function (state) {
    state.statusGetDealerSiteEvents24h = 'failed';
  },
  GET_DEALER_SITE_EVENTS_RECENT_REQUEST: function (state, siteId) {
    if (state.siteId !== siteId) {
      state.siteId = siteId;
      state.dealerSiteEventsRecent = {};
    }
    state.statusGetDealerSiteEventsRecent = 'requested';
  },
  GET_DEALER_SITE_EVENTS_RECENT_SUCCESS: function (state, data) {
    if (!state.dealerSiteEventsRecent) state.dealerSiteEventsRecent = {};
    data.forEach((v) => {
      state.dealerSiteEventsRecent[v.event_id] = v;
    });
    state.statusGetDealerSiteEventsRecent = 'successful';
  },
  GET_DEALER_SITE_EVENTS_RECENT_FAILURE: function (state) {
    state.statusGetDealerSiteEventsRecent = 'failed';
  },
};

export default {
  ...event,
  dealerSiteEvents: {
    state,
    getters,
    actions,
    mutations,
  },
};

// e.g.
// {
//   "device_id": "174041",
//   "site_id": 34019,
//   "type": "di",
//   "channel": "B827EB411B51-onvif-MotionAlarm:abnormal",
//   "created": "2023-03-03T13:40:08.000Z",
//   "event_id": "38954207",
//   "watch": 0,
//   "status": "active",
//   "timestamp": 1677850805,
//   "is_privacy": 0,
//   "event_local_id": "172963|f532952c-5b94-4b69-824a-41525b9958e0",
//   "timestamp": "2023-03-03T13:40:05.494Z",
//   "version": 2,
//   "videoai_id": "28722",
//   "videoai_provider": "actuate",
//   "videoai_result": "success"
// }
