import ims from '../imports';

export default {
  loadStatisticsVideoaiDealerSites: function (stat, date) {
    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // SET - params
    if (!stat) stat = 'request';
    const tztype = 'local_sum';
    const interval = 'day';
    const time = ims.tool.getDateKeyWithoutUTCOffset(date);
    const limit = 100;

    // LOAD
    return ims.store.dispatch('getStatisticsVideoaiDealerSites', {
      dealerId,
      stat,
      tztype,
      interval,
      time,
      limit,
    });
  },
  loadStatisticsVideoaiPartnerSites: function (stat, date) {
    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // SET - params
    if (!stat) stat = 'request';
    const tztype = 'local_sum';
    const interval = 'day';
    const time = ims.tool.getDateKeyWithoutUTCOffset(date);
    const limit = 100;

    // LOAD
    return ims.store.dispatch('getStatisticsVideoaiPartnerSites', {
      partnerId: dealerId,
      stat,
      tztype,
      interval,
      time,
      limit,
    });
  },
  loadStatisticsVideoaiDealerSiteDevices: function () {
    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // GET & CHECK - siteId - best effort
    let siteId = ims.store.getters.dealerSite?.site_id;
    if (!siteId) siteId = ims.store.getters.dealerAccounts?.site_id;
    if (!siteId) return;

    // SET - params
    const stat = 'request';
    const tztype = 'utc';
    const interval = 'day';
    const time = new Date().toISOString();
    const limit = 100;

    // LOAD
    return ims.store.dispatch('getStatisticsVideoaiDealerSiteDevices', {
      dealerId,
      siteId,
      stat,
      tztype,
      interval,
      time,
      limit,
    });
  },
  loadStatisticsVideoaiPartnerSiteDevices: function () {
    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // GET & CHECK - siteId - best effort
    let siteId = ims.store.getters.dealerSite?.site_id;
    if (!siteId) siteId = ims.store.getters.dealerAccounts?.site_id;
    if (!siteId) return;

    // SET - params
    const stat = 'request';
    const tztype = 'utc';
    const interval = 'day';
    const time = new Date().toISOString();
    const limit = 100;

    // LOAD
    return ims.store.dispatch('getStatisticsVideoaiPartnerSiteDevices', {
      partnerId: dealerId,
      siteId,
      stat,
      tztype,
      interval,
      time,
      limit,
    });
  },
};
