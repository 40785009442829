import ims from '../../../../../../../../../imports';

export default {
  controlDealerSiteDeviceRelay: function ({
    dealerId,
    siteId,
    deviceId,
    relayChannel,
    request,
  }) {
    // CASE 1 - latch
    const data = {
      ignores_assigned_camera: !!request?.ignores_assigned_camera,
      status: request?.status || '', // "open", "close", ""
      mode: 'latch', // "switch", "toggle", "momentary"
      timeout: 20, // proxy timeout
      with_snapshot: false, // enable snapshot upload
    };
    // CASE 2 - relay
    if (request?.mode === 'momentary') {
      data.status = 'open';
      data.mode = 'momentary';
      data.delay_times = request?.delay_times || 1; // max value for the second (min: 1, max: 180)
    }
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'put',
      url: `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/relays/${relayChannel}/control`,
      data,
      timeout: 1000 * 20,
    });
  },
};
