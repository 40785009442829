import dealer from './dealer';
import ip from './ip';
import ims from '../../imports';

// STATE - default
let initialState = {
  userMe: null,
  statusGetUserMe: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  userMe: function (state) {
    return state.userMe ?? {};
  },
  statusGetUserMe: function (state) {
    return state.statusGetUserMe;
  },
};

// ACTIONS
const actions = {
  getUserMe: async function ({ commit, getters }) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetUserMe === 'requested') return;
    commit('GET_USER_ME_REQUEST');
    console.debug('GET_USER_ME_REQUEST');
    try {
      const res = await ims.models.getUserMe();
      commit('GET_USER_ME_SUCCESS', res.data);
      console.debug('GET_USER_ME_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_USER_ME_FAILURE');
      console.debug('GET_USER_ME_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_USER_ME_REQUEST: function (state) {
    state.statusGetUserMe = 'requested';
  },
  GET_USER_ME_SUCCESS: function (state, data) {
    state.userMe = data;
    state.statusGetUserMe = 'successful';
  },
  GET_USER_ME_FAILURE: function (state) {
    state.statusGetUserMe = 'failed';
  },
};

export default {
  ...dealer,
  ...ip,
  userMe: {
    state,
    getters,
    actions,
    mutations,
  },
};
