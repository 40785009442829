import ims from '../../../../../../../imports';

// STATE - default
let initialState = {
  dealerSiteEventsRecords: null,
  statusGetDealerSiteEventRecords: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSiteEventsRecords: function (state) {
    return state.dealerSiteEventsRecords ?? {};
  },
  dealerSiteEventRecords: function (state, getters) {
    return function (eventId) {
      return getters.dealerSiteEventsRecords[eventId];
    };
  },
  dealerSiteEventRecordsOnlyIfValid: function (state, getters) {
    return function (eventId) {
      const records = getters.dealerSiteEventRecords(eventId);
      if (!ims._.isArray(records)) return null;
      if (records.length < 9) return null;
      for (let i = 0; i < records.length; i++) {
        const record = records[i];
        const url = record.url;
        if (record.completed === 1) continue;
        if (String(url).includes('http') && String(url).includes('?')) continue;
        return null;
      }
      return getters.dealerSiteEventRecords(eventId);
    };
  },
  statusGetDealerSiteEventRecords: function (state) {
    return state.statusGetDealerSiteEventRecords;
  },
};

// ACTIONS
const actions = {
  getDealerSiteEventRecords: async function (
    { commit, getters },
    { dealerId, siteId, eventId }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.dealerSiteEventRecordsOnlyIfValid(eventId))
      return getters.dealerSiteEventRecordsOnlyIfValid(eventId);
    if (getters.statusGetDealerSiteEventRecords === 'requested') return;
    commit('GET_DEALER_SITE_EVENT_RECORDS_REQUEST');
    console.debug('GET_DEALER_SITE_EVENT_RECORDS_REQUEST: ', eventId);
    try {
      const res = await ims.models.getDealerSiteEventRecords({
        dealerId,
        siteId,
        eventId,
      });
      commit('GET_DEALER_SITE_EVENT_RECORDS_SUCCESS', {
        eventId,
        data: res.data,
      });
      console.debug('GET_DEALER_SITE_EVENT_RECORDS_SUCCESS: ', eventId);
      return res.data;
    } catch (err) {
      commit('GET_DEALER_SITE_EVENT_RECORDS_FAILURE');
      console.debug('GET_DEALER_SITE_EVENT_RECORDS_FAILURE: ', eventId, err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITE_EVENT_RECORDS_REQUEST: function (state) {
    state.statusGetDealerSiteEventRecords = 'requested';
  },
  GET_DEALER_SITE_EVENT_RECORDS_SUCCESS: function (state, { eventId, data }) {
    if (!state.dealerSiteEventsRecords) state.dealerSiteEventsRecords = {};
    state.dealerSiteEventsRecords[eventId] = data;
    state.statusGetDealerSiteEventRecords = 'successful';
  },
  GET_DEALER_SITE_EVENT_RECORDS_FAILURE: function (state) {
    state.statusGetDealerSiteEventRecords = 'failed';
  },
};

export default {
  dealerSiteEventRecords: {
    state,
    getters,
    actions,
    mutations,
  },
};
