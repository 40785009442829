<template lang="pug">
routes
jobs
subscribers
</template>

<style>
</style>

<script>
import routes from "../3routes/1index.vue";
import jobs from "../6jobs/index.vue";
import subscribers from "../7subscribers/index.vue";

export default {
  components: {
    routes,
    jobs,
    subscribers,
  },
  mounted: function () {
    console.debug("✅ 2loaders.vue.vue loaded");
  },
};
</script>
