import ims from '../../../../../../imports';
import annotation from './annotation';
import records from './records';

export default {
  ...annotation,
  ...records,
  getDealerSiteEvent: function ({ dealerId, siteId, eventId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/events/${eventId}`,
    });
  },
};
