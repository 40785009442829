export default {
  imagePlaceHolder: function ({ rgb = 'rgb(0,0,0)', text = '' }) {
    return `data:image/svg+xml,
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9'>
      <rect x='0' y='0' width='16' height='9' style='fill:${rgb}' />
      <text x='0.05' y='0.45' fill='rgba(255,255,255,0.5)' font-size='0.5' style='font-family: sans-serif'>${text}</text>
    </svg>`;
  },
  imagePlaceHolderPerson: function ({ background = 'white', foreground = 'black' }) {
    return `data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48" style="background-color: ${background}; fill: ${foreground};">
      <path d="m278 1016 116-586-101 43v133h-61V433l192-81q14-6 29.5-7.5T484 346q17 3 29.5 11t20.5 20l42 66q31 48 77.5 75.5T753 546v60q-70-2-123.5-30.5T533 488l-38 152 92 83v293h-60V776l-108-98-79 338h-62Zm262-714q-30 0-51.5-21.5T467 229q0-30 21.5-51.5T540 156q30 0 51.5 21.5T613 229q0 30-21.5 51.5T540 302Z"/>
    </svg>`;
  },
  imagePlaceHolderVehicle: function ({ background = 'white', foreground = 'black' }) {
    return `data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48" style="background-color: ${background}; fill: ${foreground};">
      <path d="M200 852v54q0 12.75-8.625 21.375T170 936h-20q-12.75 0-21.375-8.625T120 906V582l85-256q5-14 16.5-22t26.5-8h464q15 0 26.5 8t16.5 22l85 256v324q0 12.75-8.625 21.375T810 936h-21q-13 0-21-8.625T760 906v-54H200Zm3-330h554l-55-166H258l-55 166Zm-23 60v210-210Zm105.765 160Q309 742 324.5 726.25T340 688q0-23.333-15.75-39.667Q308.5 632 286 632q-23.333 0-39.667 16.265Q230 664.529 230 687.765 230 711 246.265 726.5q16.264 15.5 39.5 15.5ZM675 742q23.333 0 39.667-15.75Q731 710.5 731 688q0-23.333-16.265-39.667Q698.471 632 675.235 632 652 632 636.5 648.265q-15.5 16.264-15.5 39.5Q621 711 636.75 726.5T675 742Zm-495 50h600V582H180v210Z"/>
    </svg>`;
  },
};
