import ims from '../../../../../../../imports';

export default {
  pingDealerSiteDevice: function ({ dealerId, siteId, deviceId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/ping`,
      params: ims.tool.removeEmptyKey({
        timeout: 5,
        version: 4,
      }),
    });
  },
};
