import ims from '../../../../imports';
import actions from './actions';
import audios from './audios';
import cameras from './cameras';
import contacts from './contacts';
import devices from './devices';
import event_statistics from './event_statistics';
import events from './events';
import liveview from './liveview';
import psaps from './psaps';
import removed_devices from './removed_devices';
import samantha from './samantha';
import ttss from './ttss';
import verifications from './verifications';

export default {
  ...actions,
  ...audios,
  ...cameras,
  ...contacts,
  ...devices,
  ...event_statistics,
  ...events,
  ...liveview,
  ...psaps,
  ...removed_devices,
  ...samantha,
  ...ttss,
  ...verifications,
  getDealerSite: function ({ dealerId, siteId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}`,
    });
  },
};
