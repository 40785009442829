import ims from '../../../../../imports';
import device from './device';

export default {
  ...device,
  getDealerSiteDevices: function ({ dealerId, siteId, fields }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/devices`,
      params: ims.tool.removeEmptyKey({
        fields,
      }),
    });
  },
};
