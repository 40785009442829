import ims from '../../../../../../imports';

export default {
  playDealerSiteAudioDevicesAudio: function ({
    dealerId,
    siteId,
    mode,
    ttsId,
    text,
    base64Data,
  }) {
    const data = {};
    switch (mode) {
      case 2: // custom text
        data.mode = mode;
        data.text = text;
        break;
      case 3: // realtime audio input
        data.mode = mode;
        data.base64_data = base64Data;
        break;
      case 1:
      default:
        data.mode = 1;
        data.tts_id = ttsId;
        break;
    }
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'post',
      url: `/dealers/${dealerId}/sites/${siteId}/audios/all/output`,
      data,
    });
  },
};
