import config from './config';
import delay from './delay';
import download from './download';
import http from './http';
import image from './image';
import json from './json';
import jwt from './jwt';
import mp4 from './mp4';
import object from './object';
import query from './query';
import string from './string';
import time from './time';

export default {
  ...config,
  ...delay,
  ...download,
  ...http,
  ...image,
  ...json,
  ...jwt,
  ...mp4,
  ...object,
  ...query,
  ...string,
  ...time,
};
