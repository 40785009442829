export default {
  isString: function (str) {
    if (typeof str === 'string' || str instanceof String) return true;
    return false;
  },
  camelToUpperCase: function (str) {
    if (!this.isString(str)) return;
    return (
      str
        // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    );
  },
};
