import axios from 'axios';
const timeout = 1000 * 30;

export default {
  urlToObjectURL: async function (url) {
    const res = await this.httpDownloadImage(url);
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(res.data);
  },
  httpDownloadImage: function (url) {
    const responseType = 'blob';
    return axios({ method: 'get', url, timeout, responseType });
  },
  httpDownloadImageArrayBuffer: function (url) {
    const responseType = 'arraybuffer';
    return axios({ method: 'get', url, timeout, responseType });
  },
  httpGet: function (url, withCredentials = false) {
    return axios({ method: 'get', url, timeout, withCredentials });
  },
  httpPut: function (url, data) {
    return axios({ method: 'put', url, data, timeout });
  },
  httpPost: function (url, data) {
    return axios({ method: 'post', url, data, timeout });
  },
};
