// STATE - default
let initialState = {
  uxMonitoringReady: false,
  uxMonitoringRightDrawerOpen: true,
  uxMonitoringSoundOn: false,
  uxMonitoringLayoutZoom: 1,
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  uxMonitoringReady: function (state) {
    return state.uxMonitoringReady;
  },
  uxMonitoringRightDrawerOpen: function (state) {
    return state.uxMonitoringRightDrawerOpen;
  },
  uxMonitoringSoundOn: function (state) {
    return state.uxMonitoringSoundOn;
  },
  uxMonitoringLayoutZoom: function (state) {
    return state.uxMonitoringLayoutZoom;
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  START_UX_MONITORING: function (state) {
    state.uxMonitoringReady = true;
  },
  STOP_UX_MONITORING: function (state) {
    state.uxMonitoringReady = false;
  },
  OPEN_UX_MONITORING_RIGHT_DRAWER: function (state) {
    state.uxMonitoringRightDrawerOpen = true;
  },
  CLOSE_UX_MONITORING_RIGHT_DRAWER: function (state) {
    state.uxMonitoringRightDrawerOpen = false;
  },
  TOGGLE_UX_MONITORING_RIGHT_DRAWER: function (state) {
    state.uxMonitoringRightDrawerOpen = !state.uxMonitoringRightDrawerOpen;
  },
  TOGGLE_UX_MONITORING_SOUND: function (state) {
    state.uxMonitoringSoundOn = !state.uxMonitoringSoundOn;
    window.dispatchEvent(
      new CustomEvent('c-monitoring-sound', {
        detail: { status: state.uxMonitoringSoundOn },
      })
    );
  },
  ZOOM_OUT_UX_MONITORING_LAYOUT: function (state) {
    state.uxMonitoringLayoutZoom = state.uxMonitoringLayoutZoom - 1;
  },
  ZOOM_IN_UX_MONITORING_LAYOUT: function (state) {
    state.uxMonitoringLayoutZoom = state.uxMonitoringLayoutZoom + 1;
  },
};

export default {
  uxMonitoring: {
    state,
    getters,
    mutations,
  },
};
