export default {
  parseMP4: async function (arrayBuffer) {
    // REF - https://gpac.github.io/mp4box.js/test/filereader.html
    // INIT - func
    const findIndexOfText = function (uint8Array, text) {
      const textUint8Array = new TextEncoder().encode(text);
      let index = -1;
      for (let i = 0; i < uint8Array.length; i++) {
        if (uint8Array[i + 0] !== textUint8Array[0]) continue;
        if (uint8Array[i + 1] !== textUint8Array[1]) continue;
        if (uint8Array[i + 2] !== textUint8Array[2]) continue;
        if (uint8Array[i + 3] !== textUint8Array[3]) continue;
        index = i;
        break;
      }
      return index;
    };
    // INIT - vars
    const view = new DataView(arrayBuffer);
    const buf = new Uint8Array(arrayBuffer);

    // UPDATE - nb_samples
    const stsz = findIndexOfText(buf, 'stsz');
    const nb_samples = view.getUint32(stsz + 12);

    // UPDATE - duration
    const mvhd = findIndexOfText(buf, 'mvhd');
    const movie_timescale = view.getUint32(mvhd + 17);
    const movie_duration = view.getUint32(mvhd + 21);
    const duration = movie_duration / movie_timescale;

    // UPDATE - fps
    const fps = nb_samples / duration;

    // UPDATE - width, height
    const tkhd = findIndexOfText(buf, 'tkhd');
    const width = view.getUint32(tkhd + 20 * 4) / 65536;
    const height = view.getUint32(tkhd + 21 * 4) / 65536;

    return {
      nb_samples,
      duration,
      fps,
      width,
      height,
    };
  },
  parseMP4FromFile: async function (file) {
    const ab = await file.arrayBuffer();
    return this.parseMP4(ab);
  },
};
