<template lang="pug">
.relative-position.bg-grey-1(style="width: 100%; height: 80px")
  .relative-position.bg-grey-9.rounded-borders.no-scroll(
    style="width: calc(100% - 40px); height: 50px; top: 15px; left: 20px"
  )
    //- play/pause wrapper
    .absolute.flex.flex-center(
      style="width: 50px; height: 100%; top: 0; left: 0"
    )
      q-btn(
        @click="togglePlay",
        :icon="play ? 'pause' : 'play_arrow'",
        dense,
        flat,
        round,
        color="white",
        size="md"
      )
    //- tracks wrapper
    .absolute.bg-black.no-scroll.cursor-pointer(
      @click="onClickTracks",
      @mousemove="onMouseMove",
      @mousedown="onMouseDown",
      ref="tracks_wrapper",
      style="width: calc(100% - 50px); height: 100%; top: 0; left: 50px"
    )
      //- markers
      slot(name="markers")

      //- slider thumb
      .absolute(
        :style="{ left: thumb.left, width: thumb.width }",
        style="height: 50px; top: 0; background-color: rgba(255, 255, 255, 0.5)"
      )
        .absolute.bg-pink-12(
          :style="{ left: thumb.innerLeft, width: thumb.innerWidth }",
          style="height: 50px; top: 0"
        )
</template>

<style>
</style>

<script>
export default {
  props: {
    video: {
      type: Object,
      default: undefined,
    },
    videoInfo: {
      type: Object,
      default: undefined,
    },
    play: {
      type: Boolean,
      default: true,
    },
    timeupdated: {
      type: Object,
      default: undefined,
    },
    currentFrame: {
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
      duration: 0,
      currentTime: 0,
      totalFrames: 0,
      //
      isMouseDown: false,
      prevPlayStateBeforeMouseDown: true,
      //
      thumb: {
        left: "",
        width: "",
        innerLeft: "",
        innerWidth: "",
      },
    };
  },
  watch: {
    timeupdated: function () {
      this.onCurrentTimeChange();
    },
    isMouseDown: function () {
      if (this.isMouseDown) {
        this.prevPlayStateBeforeMouseDown = this.play;
        this.pauseVideo();
        return;
      }
      // PLAY again if it was playing
      if (this.prevPlayStateBeforeMouseDown) {
        this.playVideo();
      }
    },
    duration: function () {
      this.updateThumbPosition();
    },
    currentTime: function () {
      this.updateThumbPosition();
    },
  },
  mounted: function () {
    window.addEventListener("mouseup", this.onMouseUp);
  },
  beforeUnmount: function () {
    window.removeEventListener("mouseup", this.onMouseUp);
  },
  methods: {
    updateThumbPosition: function () {
      const rect = this.$refs?.tracks_wrapper?.getBoundingClientRect();
      if (!rect) return;
      const width = rect.width / this.duration;
      const innerWidth = 2;
      this.thumb.width = `${width}px`;
      this.thumb.left = `calc(${(this.currentTime / this.duration) * 100}% - ${
        width / 2
      }px)`;
      this.thumb.innerWidth = `${innerWidth}px`;
      this.thumb.innerLeft = `${width / 2 - innerWidth / 2}px`;
    },
    //
    onMouseDown: function () {
      this.isMouseDown = true;
    },
    onMouseUp: function () {
      this.isMouseDown = false;
    },
    onMouseMove: function (e) {
      if (!this.isMouseDown) return;
      this.onClickTracks(e);
    },
    onCurrentTimeChange: function () {
      if (!this.video) return;
      if (!this.videoInfo) return;
      this.totalFrames = this.videoInfo.nb_samples;
      this.duration = this.video.duration;
      this.currentTime = this.video.currentTime;
    },
    //
    togglePlay: function () {
      switch (this.play) {
        case false:
          this.playVideo();
          break;
        case true:
          this.pauseVideo();
          break;
      }
    },
    onClickTracks: function (e) {
      const rect = this.$refs?.tracks_wrapper?.getBoundingClientRect();
      if (!rect) return;
      const x = e.clientX - rect.left ?? 0;
      const targetTime = this.duration * (x / rect.width);
      this.setCurrentTimeVideo(targetTime);
    },
    //
    playVideo: function () {
      this.video?.play();
    },
    pauseVideo: function () {
      this.video?.pause();
    },
    setCurrentTimeVideo: function (targetTime) {
      if (!this.video) return;
      const targetVideo = this.video;
      targetVideo.currentTime = targetTime;
    },
  },
};
</script>
