import psaps from './psaps';
import records from './records';
import verifications from './verifications';
import watch from './watch';

export default {
  ...psaps,
  ...records,
  ...verifications,
  ...watch,
};
