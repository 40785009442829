import ims from '../imports';

export default {
  loadSiteEventAnnotation: function (siteId, eventId) {
    // CHECK - siteId, eventId
    if (!siteId) return;
    if (!eventId) return;

    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // LOAD
    return ims.store.dispatch('getDealerSiteEventAnnotation', {
      dealerId,
      siteId,
      eventId,
    });
  },
};
