import ims from '../../../../../imports';

// STATE - default
let initialState = {
  dealerSiteContacts: null,
  statusGetDealerSiteContacts: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSiteContacts: function (state) {
    return state.dealerSiteContacts ?? {};
  },
  statusGetDealerSiteContacts: function (state) {
    return state.statusGetDealerSiteContacts;
  },
};

// ACTIONS
const actions = {
  getDealerSiteContacts: async function (
    { commit, getters },
    { dealerId, siteId }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSiteContacts === 'requested') return;
    commit('GET_DEALER_SITE_CONTACTS_REQUEST');
    console.debug('GET_DEALER_SITE_CONTACTS_REQUEST');
    try {
      const res = await ims.models.getDealerSiteContacts({
        dealerId,
        siteId,
      });
      commit('GET_DEALER_SITE_CONTACTS_SUCCESS', res.data);
      console.debug('GET_DEALER_SITE_CONTACTS_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_DEALER_SITE_CONTACTS_FAILURE');
      console.debug('GET_DEALER_SITE_CONTACTS_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITE_CONTACTS_REQUEST: function (state) {
    state.statusGetDealerSiteContacts = 'requested';
  },
  GET_DEALER_SITE_CONTACTS_SUCCESS: function (state, data) {
    state.dealerSiteContacts = data;
    state.statusGetDealerSiteContacts = 'successful';
  },
  GET_DEALER_SITE_CONTACTS_FAILURE: function (state) {
    state.statusGetDealerSiteContacts = 'failed';
  },
};

export default {
  dealerSiteContacts: {
    state,
    getters,
    actions,
    mutations,
  },
};
