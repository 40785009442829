import ims from '../../../../../../../../../imports';

// STATE - default
let initialState = {
  dealerSiteDeviceEventRecords: null,
  statusGetDealerSiteDeviceEventRecords: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSiteDeviceEventRecords: function (state) {
    return state.dealerSiteDeviceEventRecords ?? {};
  },
  statusGetDealerSiteDeviceEventRecords: function (state) {
    return state.statusGetDealerSiteDeviceEventRecords;
  },
};

// ACTIONS
const actions = {
  getDealerSiteDeviceEventRecords: async function (
    { commit, getters },
    { dealerId, siteId, deviceId, eventId }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSiteDeviceEventRecords === 'requested') return;
    commit('GET_DEALER_SITE_DEVICE_EVENT_RECORDS_REQUEST');
    console.debug('GET_DEALER_SITE_DEVICE_EVENT_RECORDS_REQUEST: ', eventId);
    try {
      const res = await ims.models.getDealerSiteDeviceEventRecords({
        dealerId,
        siteId,
        deviceId,
        eventId,
      });
      commit('GET_DEALER_SITE_DEVICE_EVENT_RECORDS_SUCCESS', res.data);
      console.debug('GET_DEALER_SITE_DEVICE_EVENT_RECORDS_SUCCESS: ', eventId);
      return res.data;
    } catch (err) {
      commit('GET_DEALER_SITE_DEVICE_EVENT_RECORDS_FAILURE');
      console.debug(
        'GET_DEALER_SITE_DEVICE_EVENT_RECORDS_FAILURE: ',
        eventId,
        err
      );
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITE_DEVICE_EVENT_RECORDS_REQUEST: function (state) {
    state.statusGetDealerSiteDeviceEventRecords = 'requested';
  },
  GET_DEALER_SITE_DEVICE_EVENT_RECORDS_SUCCESS: function (state, data) {
    state.dealerSiteDeviceEventRecords = data;
    state.statusGetDealerSiteDeviceEventRecords = 'successful';
  },
  GET_DEALER_SITE_DEVICE_EVENT_RECORDS_FAILURE: function (state) {
    state.statusGetDealerSiteDeviceEventRecords = 'failed';
  },
};

export default {
  dealerSiteDeviceEventRecords: {
    state,
    getters,
    actions,
    mutations,
  },
};
