<template lang="pug">
router-view
</template>

<style>
</style>

<script>
export default {
  mounted: function () {
    console.debug("✅ 3routes.1index.vue loaded");
  },
};
</script>
