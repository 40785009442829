import ims from '../../../../../../../imports';

export default {
  createDealerSiteDeviceWatch: function ({
    dealerId,
    siteId,
    deviceId,
    watchType,
    eventId,
  }) {
    // watchType
    // 0: video event clip viewed
    // 1: live video viewed
    // 2: playback video viewed
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'post',
      url: `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/watch`,
      data: ims.tool.removeEmptyKey({
        type: watchType,
        event_id: eventId,
      }),
    });
  },
};
