import ims from '../../imports';

export default {
  createDealerShortUrl: function ({ dealerId, url }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'post',
      url: `/dealers/${dealerId}/shortenurl`,
      data: ims.tool.removeEmptyKey({
        type: 1,
        url,
      }),
    });
  },
};
