import ims from '../../../../../imports';

export default {
  getDealerSiteEventStatistics: function ({
    dealerId,
    siteId,
    stime,
    etime,
    eventType,
  }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/event_statistics`,
      params: ims.tool.removeEmptyKey({
        stime,
        etime,
        type: eventType,
      }),
    });
  },
};
