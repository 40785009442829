import ims from '../../../../../imports';
import psap from './psap';

export default {
  ...psap,
  getDealerSitePsaps: function ({ dealerId, siteId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/psaps`,
    });
  },
};
