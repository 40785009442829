import ims from '../../../imports';

// STATE - default
let initialState = {
  dealerTTSs: null,
  statusGetDealerTTSs: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerTTSs: function (state) {
    return state.dealerTTSs ?? {};
  },
  statusGetDealerTTSs: function (state) {
    return state.statusGetDealerTTSs;
  },
};

// ACTIONS
const actions = {
  getDealerTTSs: async function ({ commit, getters }, { dealerId }) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerTTSs === 'requested') return;
    commit('GET_DEALER_TTSS_REQUEST');
    console.debug('GET_DEALER_TTSS_REQUEST');
    try {
      const res = await ims.models.getDealerTTSs({ dealerId });
      commit('GET_DEALER_TTSS_SUCCESS', res.data);
      console.debug('GET_DEALER_TTSS_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_DEALER_TTSS_FAILURE');
      console.debug('GET_DEALER_TTSS_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_TTSS_REQUEST: function (state) {
    state.statusGetDealerTTSs = 'requested';
  },
  GET_DEALER_TTSS_SUCCESS: function (state, data) {
    state.dealerTTSs = data;
    state.statusGetDealerTTSs = 'successful';
  },
  GET_DEALER_TTSS_FAILURE: function (state) {
    state.statusGetDealerTTSs = 'failed';
  },
};

export default {
  dealerTTSs: {
    state,
    getters,
    actions,
    mutations,
  },
};
