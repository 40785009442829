import ims from '../../../imports';

// STATE - default
let initialState = {
  userMeDealer: null,
  statusGetUserMeDealer: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  userMeDealer: function (state) {
    return state.userMeDealer ?? {};
  },
  statusGetUserMeDealer: function (state) {
    return state.statusGetUserMeDealer;
  },
};

// ACTIONS
const actions = {
  getUserMeDealer: async function ({ commit, getters }, { tsSessionDealerId }) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetUserMeDealer === 'requested') return;
    commit('GET_USER_ME_DEALER_REQUEST');
    console.debug('GET_USER_ME_DEALER_REQUEST');
    try {
      const res = await ims.models.getUserMeDealer({ tsSessionDealerId });
      commit('GET_USER_ME_DEALER_SUCCESS', res.data?.[0]);
      console.debug('GET_USER_ME_DEALER_SUCCESS');
      return res.data?.[0];
    } catch (err) {
      commit('GET_USER_ME_DEALER_FAILURE');
      console.debug('GET_USER_ME_DEALER_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_USER_ME_DEALER_REQUEST: function (state) {
    state.statusGetUserMeDealer = 'requested';
  },
  GET_USER_ME_DEALER_SUCCESS: function (state, data) {
    state.userMeDealer = data;
    state.statusGetUserMeDealer = 'successful';
  },
  GET_USER_ME_DEALER_FAILURE: function (state) {
    state.statusGetUserMeDealer = 'failed';
  },
};

export default {
  userMeDealer: {
    state,
    getters,
    actions,
    mutations,
  },
};
