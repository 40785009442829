import ims from '../../../imports';

export default {
  getDealerTTSs: function ({ dealerId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/ttss`,
    });
  },
};
