import ims from '../../../../../../../imports';

export default {
  getDealerSiteEventAnnotation: function ({ dealerId, siteId, eventId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/events/${eventId}/annotation`,
    });
  },
};
