import ims from '../../../imports';

// STATE - default
let initialState = {
  userMeIp: null,
  statusGetUserMeIp: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  userMeIp: function (state) {
    return state.userMeIp ?? {};
  },
  statusGetUserMeIp: function (state) {
    return state.statusGetUserMeIp;
  },
};

// ACTIONS
const actions = {
  getUserMeIp: async function ({ commit, getters }) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetUserMeIp === 'requested') return;
    commit('GET_USER_ME_IP_REQUEST');
    console.debug('GET_USER_ME_IP_REQUEST');
    try {
      const res = await ims.models.getUserMeIp();
      commit('GET_USER_ME_IP_SUCCESS', res.data);
      console.debug('GET_USER_ME_IP_SUCCESS');
      return res.data;
    } catch (err) {
      commit('GET_USER_ME_IP_FAILURE');
      console.debug('GET_USER_ME_IP_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_USER_ME_IP_REQUEST: function (state) {
    state.statusGetUserMeIp = 'requested';
  },
  GET_USER_ME_IP_SUCCESS: function (state, data) {
    state.userMeIp = data;
    state.statusGetUserMeIp = 'successful';
  },
  GET_USER_ME_IP_FAILURE: function (state) {
    state.statusGetUserMeIp = 'failed';
  },
};

export default {
  userMeIp: {
    state,
    getters,
    actions,
    mutations,
  },
};
