import ims from '../../../../../../../imports';

export default {
  getDealerSiteDeviceSnapshot: function ({ dealerId, siteId, deviceId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}/snapshot`,
      withCredentials: true,
    });
  },
};
