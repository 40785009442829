<template lang="pug">
div(style="display: block; position: relative; padding-top: 56.25%")
  img(
    v-if="src",
    :src="src",
    ref="c_snapshot_video_101_img",
    style="position: absolute; width: 100%; height: 100%; top: 0; left: 0"
  )
  .absolute(
    v-if="loading",
    style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
  )
    q-spinner(size="3em")
</template>

<style>
</style>

<script>
import ims from "../../imports";
export default {
  components: {},
  props: {
    siteId: {
      type: String,
      default: "",
    },
    eventId: {
      type: String,
      default: "",
    },
    play: {
      type: Boolean,
      default: true,
    },
    currentFrame: {
      type: Number,
      default: 0,
    },
  },
  emits: ["current-frame", "total-frames"],
  computed: {
    event: function () {
      return this.$store.getters.dealerSiteEvent24h(this.eventId);
    },
  },
  data: function () {
    return {
      fps: 3,
      urls: [],
      totalFrames: 0,
      src: "",
      loading: false,
      frameIndex: -1,
      updatingCurrentFrame$i: null,
    };
  },
  watch: {
    siteId: function () {
      this.init();
    },
    eventId: function () {
      this.init();
    },
    currentFrame: function () {
      this.frameIndex = this.currentFrame;
    },
    frameIndex: function () {
      this.$emit("current-frame", this.frameIndex);
      this.updateSrc();
    },
    totalFrames: function () {
      this.$emit("total-frames", this.totalFrames);
    },
  },
  mounted: function () {
    this.init();
  },
  beforeUnmount: function () {
    this.stopUpdatingCurrentFrame();
  },
  methods: {
    init: function () {
      this.urls = [];
      this.src = "";
      this.totalFrames = 0;
      this.frameIndex = 0;
      this.loadData();
      this.startUpdatingCurrentFrame();
    },
    startUpdatingCurrentFrame: function () {
      this.stopUpdatingCurrentFrame();
      this.updatingCurrentFrame$i = setInterval(() => {
        this.updateCurrentFrame();
      }, 1000 / this.fps);
    },
    stopUpdatingCurrentFrame: function () {
      if (!this.updatingCurrentFrame$i) return;
      clearInterval(this.updatingCurrentFrame$i);
      this.updatingCurrentFrame$i = null;
    },
    updateCurrentFrame: function () {
      if (!this.play) return;
      this.frameIndex++;
      if (this.frameIndex > this.urls.length - 1) {
        this.frameIndex = 0;
      }
      if (this.frameIndex < 0) {
        this.frameIndex = this.urls.length - 1;
      }
    },
    //
    updateSrc: function () {
      // CASE 0 - in privacy mode
      if (this.event?.is_privacy > 0) {
        this.src = ims.tool.imagePlaceHolder({ text: "PRIVACY MODE" });
        this.loading = false;
        return;
      }

      // GET - objectURL
      const src = this.getSrc(this.frameIndex);

      // CASE 1 - Still loading
      if (src === "loading") {
        this.src = ims.tool.imagePlaceHolder({});
        this.loading = true;
        return;
      }

      // CASE 2 - Good to go
      this.src = src;
      this.loading = false;
    },
    getSrc: function (index) {
      // CASE 1 - no url
      if (index < 0) return "";
      const url = this.urls[index] || "";
      if (!url) return url;

      // CASE 2 - has url
      // DOWNLOAD - image
      const objectURL = ims.helper.getURLObjectURLSync(url);
      return objectURL;
    },
    //
    download: function () {
      this.$q
        .dialog({
          title: "Download images",
          message: "Do you want to download all images of this event?",
          cancel: {
            flat: true,
            color: "black",
          },
        })
        .onOk(() => {
          this.urls.forEach((v, i) => {
            const objectURL = ims.helper.getURLObjectURLSync(v);
            if (!objectURL) return;
            if (objectURL === "loading") return;
            ims.tool.downloadURL(objectURL, `${this.eventId}-${i}.jpg`);
          });
        });
    },
    //
    loadData: async function () {
      // CHECK
      if (!this.siteId) return;
      if (!this.eventId) return;

      // GET - event records
      const records = await ims.helper.loadSiteEventRecords(
        this.siteId,
        this.eventId
      );

      // UPDATE - image urls
      records?.forEach((v) => {
        if (!v.completed) return;
        if (!v.has_download_permission) return;
        if (v.format !== "jpg") return;
        if (!v.url) return;
        this.urls.push(v.url);

        // CASE 1 - in privacy mode
        if (this.event?.is_privacy > 0) return;

        // CASE 2 - NOT in privacy mode
        // LOAD - snapshots - beforehand for better UX
        ims.helper.loadURLObjectURL(v.url);
      });

      // UPDATE - totalFrames
      this.totalFrames = this.urls.length;
    },
  },
};
</script>
