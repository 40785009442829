import ims from '../imports';

export default {
  loadEvent: function ({ siteId, eventId }) {
    // CHECK
    if (!eventId) return;

    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // GET & CHECK - siteId - best effort
    if (!siteId) siteId = ims.store.getters.dealerSite?.site_id;
    if (!siteId) siteId = ims.store.getters.dealerAccounts?.site_id;
    if (!siteId) siteId = ims.tool.getQuery('site');
    if (!siteId) return;

    // LOAD
    return ims.store.dispatch('getDealerSiteEvent', {
      dealerId,
      siteId,
      eventId,
    });
  },
  loadSiteEvents24h: function ({ siteId, date, getOlder }) {
    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // GET & CHECK - timezone - best effort
    let timezone = ims.store.getters.dealerSites?.[siteId]?.timezone;
    if (!timezone) timezone = ims.store.getters.dealerSite?.timezone;
    if (!timezone) timezone = 'UTC';

    // INIT - vars
    const filter = [];
    ['di'].forEach((v) => filter.push(`device_event.type,${v},eq`));
    filter.push(`device_event.status,active,eq`);
    const params = {
      limit: 20,
      dir: 'desc',
      sort: 'event_id',
      filter,
      init: 'yes',
    };
    const stime = ims.tool.getDateTimestampLocal(date, timezone);

    // LOAD - data
    return ims.store.dispatch('getDealerSiteEvents24h', {
      dealerId,
      siteId,
      stime,
      params,
      getOlder,
    });
  },
  loadEventsRecent: function () {
    // GET & CHECK - dealerId - best effort
    const dealerId = ims.store.getters.userMeDealer?.dealer_id;
    if (!dealerId) return;

    // GET & CHECK - siteId - best effort
    let siteId = ims.store.getters.dealerSite?.site_id;
    if (!siteId) siteId = ims.store.getters.dealerAccounts?.site_id;
    if (!siteId) return;

    // INIT - vars
    const secondsBefore = 60 * 10; // minutes
    const lastEventId = '';
    const eventTypes = ['di'];
    const eventStatus = '';

    // LOAD - data
    return ims.store.dispatch('getDealerSiteEventsRecent', {
      dealerId,
      siteId,
      secondsBefore,
      lastEventId,
      eventTypes,
      eventStatus,
    });
  },
};
