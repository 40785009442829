import ims from '../../../../imports';
import cameras from './cameras';
import contacts from './contacts';
import devices from './devices';
import events from './events';
import removed_devices from './removed_devices';
import ttss from './ttss';

// STATE - default
let initialState = {
  dealerSite: null,
  statusGetDealerSite: '',
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSite: function (state) {
    return state.dealerSite ?? {};
  },
  statusGetDealerSite: function (state) {
    return state.statusGetDealerSite;
  },
};

// ACTIONS
const actions = {
  getDealerSite: async function ({ commit, getters }, { dealerId, siteId }) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSite === 'requested') return;
    commit('GET_DEALER_SITE_REQUEST');
    console.debug('GET_DEALER_SITE_REQUEST');
    try {
      const res = await ims.models.getDealerSite({ dealerId, siteId });
      commit('GET_DEALER_SITE_SUCCESS', res.data?.[0]);
      console.debug('GET_DEALER_SITE_SUCCESS');
      return res.data?.[0];
    } catch (err) {
      commit('GET_DEALER_SITE_FAILURE');
      console.debug('GET_DEALER_SITE_FAILURE', err);
      throw err;
    }
  },
  getDealerAccount: async function (
    { commit, getters },
    { dealerId, customerAccountNumber, receiverLinePrefix, accountReferenceId }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSite === 'requested') return;
    commit('GET_DEALER_SITE_REQUEST');
    console.debug('GET_DEALER_SITE_REQUEST');
    try {
      const res = await ims.models.getDealerAccounts({
        dealerId,
        customerAccountNumber,
        receiverLinePrefix,
        accountReferenceId,
      });
      commit('GET_DEALER_SITE_SUCCESS', res.data?.[0]);
      console.debug('GET_DEALER_SITE_SUCCESS');
      return res.data?.[0];
    } catch (err) {
      commit('GET_DEALER_SITE_FAILURE');
      console.debug('GET_DEALER_SITE_FAILURE', err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITE_REQUEST: function (state) {
    state.statusGetDealerSite = 'requested';
  },
  GET_DEALER_SITE_SUCCESS: function (state, data) {
    state.dealerSite = data;
    state.statusGetDealerSite = 'successful';
  },
  GET_DEALER_SITE_FAILURE: function (state) {
    state.statusGetDealerSite = 'failed';
  },
};

export default {
  ...cameras,
  ...contacts,
  ...devices,
  ...events,
  ...removed_devices,
  ...ttss,
  dealerSite: {
    state,
    getters,
    actions,
    mutations,
  },
};
