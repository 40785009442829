import moment from 'moment';
import exportFile from 'quasar/src/utils/export-file.js';;
import tool from '../0tool';
import config from '../../1config';
import auth0_auth from '../../2loaders/auth0_auth';
import cloud_time from '../../2loaders/cloud_time';
import helper from '../../4services/2helper';

export default {
  moment,
  exportFile,

  tool,
  config,
  auth0_auth,
  cloud_time,
  helper,
};
