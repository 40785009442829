import ims from '../../../../../../../../../imports';

export default {
  getDealerSiteEventRecordClassifications: function ({
    dealerId,
    siteId,
    eventId,
    recordId,
  }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/events/${eventId}/records/${recordId}/classifications`,
    });
  },
};
