console.debug('✅ 1config loaded');
import system from './system.js';
const env = system.updateEnvironmentalVariablesGlobally();

export default {
  env,

  logs: {
    level: system.logLevel[env],
  },

  api: system.api[env],
  command_center_url: system.command_center_url[env],
  auth0_auth: system.auth0_auth[env],
};
