import ims from '../../../../../../imports';
import ping from './ping';
import relays from './relays';
import snapshot from './snapshot';
import events from './events';
import watch from './watch';

export default {
  ...ping,
  ...relays,
  ...snapshot,
  ...events,
  ...watch,
  getDealerSiteDevice: function ({ dealerId, siteId, deviceId }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/dealers/${dealerId}/sites/${siteId}/devices/${deviceId}`,
    });
  },
};
