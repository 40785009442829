import axios from 'axios';
import config from '../1config';

// INIT - vars
// 0.5 hour... if time changes like 0.5 hour... reload page...
// it is important... for everything...
// 1. event, live time
// 2. access token expiration... it is critical..
const maxTimeDiff = 1000 * 60 * 30; // minutes
let startClock$i = null;
//
const timeObj = {
  newDate: function () {
    if (this.now) {
      return this.now;
    } else {
      return new Date();
    }
  },
  now: null,
  timeOffset: null,
};

// INIT - funcs
const updateTimeOffset = function (dateString, latency) {
  try {
    if (!dateString) {
      throw new Error(
        'updateDate: dateString: ' + dateString + ': latency: ' + latency
      );
    }
    const myNow = new Date();
    const cloudNow = new Date(dateString);
    cloudNow.setTime(cloudNow.getTime() + latency);
    timeObj.timeOffset = myNow.getTime() - cloudNow.getTime();
    console.info(
      '✅ 2loaders.chekt_time: time.timeOffset:',
      timeObj.timeOffset
    );
  } catch (err) {
    console.info(
      '✅ 2loaders.chekt_time: getting time from internet failed!: err:',
      err
    );
    timeObj.timeOffset = 0;
  }
};

const updateDate = function () {
  const myNow = new Date();
  try {
    // CASE 1 - NOT ready to update
    if (timeObj.timeOffset === null) return;
    const cloudNow = new Date(myNow.getTime() - timeObj.timeOffset);
    // CASE 2 - UPDATE - for the first time
    if (!timeObj.now) {
      return (timeObj.now = cloudNow);
    }
    // CASE 3 - RELOAD - everything! - too much time gap
    if (Math.abs(cloudNow.getTime() - timeObj.now.getTime()) > maxTimeDiff) {
      return location.reload();
    }
    // CASE 4 - GOOD to update
    timeObj.now = cloudNow;
  } catch (err) {
    console.info('✅ 2loaders.chekt_time: updateDate failed!: err:', err);
    timeObj.now = myNow;
  }
};

const startClock = function (dateString, latency) {
  console.info(
    `✅ 2loaders.chekt_time: startClock: ${dateString}: latency ${latency}`
  );

  // just in case!
  if (startClock$i) {
    clearInterval(startClock$i);
    startClock$i = null;
  }
  // INIT
  const tickInterval = 1000 * 1; // every 1 second
  updateTimeOffset(dateString, latency);
  updateDate();
  // START - ticking
  startClock$i = setInterval(() => {
    updateDate();
  }, tickInterval);
};

const getCloudTime = function () {
  const stime = new Date().getTime();
  axios({
    baseURL: config.api.prefix['v1.1'],
    timeout: 1000 * 60,
    method: 'get',
    url: `/users/find`,
    params: { email: 'ping' },
    withCredentials: true,
  }).then(
    (res) => {
      const etime = new Date().getTime();
      const latency = etime - stime;
      const dateString = res.headers.get('date');
      startClock(dateString, latency / 2); // Client --> Server --> Client... so only need half latency
    },
    (err) => {
      const etime = new Date().getTime();
      const latency = etime - stime;
      const dateString = err?.response?.headers?.get('date');
      startClock(dateString, latency / 2);
    }
  );
};

// ACTION
getCloudTime();

console.debug('✅ 2loaders.chekt_time loaded');

export default timeObj;
