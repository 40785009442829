<template lang="pug">
q-bar.bg-grey-1.text-black
  q-icon(name="schedule")
  div {{ eventTime }}

  q-space

  //- zoom
  q-btn(
    @click="toggleZoom",
    :color="zoom ? 'amber' : ''",
    dense,
    unelevated,
    text-color="black"
  )
    | &nbsp;&nbsp;Z&nbsp;&nbsp;
    q-tooltip.bg-amber.text-black.shadow-4 Zoom

  //- show annotations
  q-btn(
    @click="toggleAnnotations",
    :color="showAnnotations ? 'pink-11' : ''",
    dense,
    unelevated,
    text-color="black"
  )
    | &nbsp;&nbsp;A&nbsp;&nbsp;
    q-tooltip.bg-pink-11.text-black.shadow-4 Show annotations

  //- show detection areas
  q-btn(
    @click="toggleDetectionAreas",
    :color="showDetectionAreas ? 'green-13' : ''",
    dense,
    unelevated,
    text-color="black"
  )
    | &nbsp;&nbsp;D&nbsp;&nbsp;
    q-tooltip.bg-green-13.text-black.shadow-4 Show detection areas

  //- download
  q-btn.q-pa-xs(
    @click="download",
    dense,
    unelevated,
    text-color="black",
    icon="download",
    size="10px"
  )
    q-tooltip.bg-blue-12.text-black.shadow-4 Download media

  //- show event info
  q-btn.q-pa-xs(dense, unelevated, text-color="black", icon="info_outline")
    q-tooltip.bg-blue-12.text-black.shadow-4 View event info

q-bar.bg-grey-1.text-black
  q-icon(name="videocam")
  div {{ `#${device?.zone_number}__ ${device?.name || "no_name"}` }}

  q-space

  //- media types (snapshot or video)
  q-btn-group(unelevated)
    q-btn(
      dense,
      unelevated,
      text-color="white",
      icon="image",
      :color="media === 'snapshot' ? 'blue-14' : 'grey-4'",
      @click="$emit('media', 'snapshot')"
    )
      q-tooltip.bg-blue-12.text-black.shadow-4 View snapshots
    q-btn(
      dense,
      unelevated,
      text-color="white",
      icon="movie",
      :color="media === 'mp4' ? 'blue-14' : 'grey-4'",
      @click="$emit('media', 'mp4')"
    )
      q-tooltip.bg-blue-12.text-black.shadow-4 View video
</template>

<style>
</style>

<script>
import ims from "../imports";
export default {
  props: {
    eventId: {
      type: String,
      default: "",
    },
    media: {
      type: String,
      default: "",
    },
  },
  emits: [
    "zoom",
    "show-annotations",
    "show-detection-areas",
    "toggle-play",
    "download",
    "media",
  ],
  computed: {
    dealerSiteDevices: function () {
      return this.$store.getters.dealerSiteDevices;
    },
    dealerSites: function () {
      return this.$store.getters.dealerSites;
    },
    dealerSite: function () {
      return this.$store.getters.dealerSite;
    },
    site: function () {
      return this.dealerSites[this.event?.site_id] || this.dealerSite;
    },
    device: function () {
      return this.dealerSiteDevices[this.event?.device_id];
    },
    event: function () {
      return this.$store.getters.dealerSiteEvent24h(this.eventId);
    },
  },
  data: function () {
    return {
      zoom: false,
      showAnnotations: true,
      showDetectionAreas: true,
      eventTime: "",
    };
  },
  watch: {
    site: function () {
      this.updateEventTime();
    },
    device: function () {
      this.updateEventTime();
    },
    event: function () {
      this.updateEventTime();
    },
    zoom: function () {
      this.$emit("zoom", this.zoom);
    },
    showAnnotations: function () {
      this.$emit("show-annotations", this.showAnnotations);
    },
    showDetectionAreas: function () {
      this.$emit("show-detection-areas", this.showDetectionAreas);
    },
    media: function () {
      this.$emit("media", this.media);
    },
  },
  mounted: function () {
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
    this.emitsAll();
    this.updateEventTime();
  },
  beforeUnmount: function () {
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
  },
  methods: {
    updateEventTime: function () {
      this.eventTime =
        ims.tool.getLocaleDateTimeString(
          this.event?.timestamp * 1000,
          this.site?.timezone
        ) || "";
    },
    //
    emitsAll: function () {
      this.$emit("zoom", this.zoom);
      this.$emit("show-annotations", this.showAnnotations);
      this.$emit("show-detection-areas", this.showDetectionAreas);
    },
    onKeyDown: function (e) {
      if (e.repeat) return;
      switch (e.keyCode) {
        // Z: Zoom
        case 90:
          e.preventDefault();
          this.zoom = true;
          break;
        // A: Annotations
        case 65:
          e.preventDefault();
          // this.showAnnotations = true;
          break;
        // D: Detection Areas
        case 68:
          e.preventDefault();
          this.showDetectionAreas = true;
          break;
      }
    },
    onKeyUp: function (e) {
      if (e.repeat) return;
      switch (e.keyCode) {
        // Z: Zoom
        case 90:
          e.preventDefault();
          this.zoom = false;
          break;
        // A: Annotations
        case 65:
          e.preventDefault();
          // this.showAnnotations = false;
          this.toggleAnnotations();
          break;
        // D: Detection Areas
        case 68:
          e.preventDefault();
          this.showDetectionAreas = false;
          break;
        // M: Media
        case 77:
          e.preventDefault();
          if (this.media === "mp4") {
            this.$emit("media", "snapshot");
          } else {
            this.$emit("media", "mp4");
          }
          break;
        // Space: Play/Pause
        // P: Play/Pause
        case 32:
        case 80:
          e.preventDefault();
          this.togglePlay();
          break;
      }
    },
    toggleZoom: function () {
      this.zoom = !this.zoom;
    },
    toggleAnnotations: function () {
      this.showAnnotations = !this.showAnnotations;
    },
    toggleDetectionAreas: function () {
      this.showDetectionAreas = !this.showDetectionAreas;
    },
    togglePlay: function () {
      this.$emit("toggle-play", true);
    },
    download: function () {
      this.$emit("download", true);
    },
  },
};
</script>
