<template lang="pug">
div(style="display: block; position: relative; padding-top: 56.25%")
  video(
    :src="src",
    @play="play = true",
    @pause="play = false",
    @timeupdate="timeupdated = $event",
    autoplay,
    muted,
    loop,
    style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; object-fit: fill",
    onloadstart="this.playbackRate = 2.0;",
    ref="c_mp4_video_101_video"
  )

  .absolute(
    v-if="loading",
    style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
  )
    q-spinner(size="3em")
</template>

<style>
</style>

<script>
import ims from "../../imports";
export default {
  props: {
    siteId: {
      type: String,
      default: "",
    },
    eventId: {
      type: String,
      default: "",
    },
  },
  emits: ["current-frame", "video", "video-info", "timeupdated", "play"],
  computed: {
    event: function () {
      return this.$store.getters.dealerSiteEvent24h(this.eventId);
    },
  },
  data: function () {
    return {
      url: "",
      src: "",
      loading: false,
      //
      fps: 6,
      currentFrame: -1,
      updatingCurrentFrame$i: null,
      //
      video: undefined,
      videoInfo: undefined,
      play: true,
      timeupdated: undefined,
    };
  },
  watch: {
    siteId: function () {
      this.init();
    },
    eventId: function () {
      this.init();
    },
    fps: function () {
      this.startUpdatingCurrentFrame();
    },
    src: function () {
      this.updateVideo();
      this.updateVideoInfo();
    },
    video: function () {
      this.$emit("video", this.video);
    },
    videoInfo: function () {
      this.$emit("video-info", this.videoInfo);
    },
    timeupdated: function () {
      this.$emit("timeupdated", this.timeupdated);
    },
    play: function () {
      this.$emit("play", this.play);
    },
  },
  mounted: function () {
    this.init();
  },
  beforeUnmount: function () {
    this.stopUpdatingCurrentFrame();
  },
  methods: {
    init: function () {
      this.url = "";
      this.src = "";
      this.updateVideo();
      this.loadData();
      this.startUpdatingCurrentFrame();
    },
    //
    startUpdatingCurrentFrame: function () {
      this.stopUpdatingCurrentFrame();
      this.updatingCurrentFrame$i = setInterval(() => {
        this.updateCurrentFrame();
      }, 1000 / this.fps);
    },
    stopUpdatingCurrentFrame: function () {
      if (!this.updatingCurrentFrame$i) return;
      clearInterval(this.updatingCurrentFrame$i);
      this.updatingCurrentFrame$i = null;
    },
    updateCurrentFrame: function () {
      if (!(this.video instanceof Object)) return;
      this.currentFrame = Math.floor(this.video?.currentTime * this.fps);
      this.$emit("current-frame", this.currentFrame);
    },
    //
    updateVideo: function () {
      if (this.video) return;
      if (!this.$refs?.c_mp4_video_101_video) return;
      this.video = this.$refs.c_mp4_video_101_video;
    },
    updateVideoInfo: async function () {
      if (!this.src) return;
      const res = await ims.tool.httpDownloadImageArrayBuffer(this.src);
      const info = await ims.tool.parseMP4(res.data);
      this.fps = info.fps;
      this.videoInfo = info;
    },
    //
    download: function () {
      if (!this.url) return;
      this.$q
        .dialog({
          title: "Download video",
          message: "Do you want to download the video of this event?",
          cancel: {
            flat: true,
            color: "black",
          },
        })
        .onOk(() => {
          if (!this.src) return;
          ims.tool.downloadURL(this.src, `${this.eventId}.mp4`);
        });
    },
    togglePlay: function () {
      if (this.play) {
        this.video?.pause();
        return;
      }
      this.video?.play();
    },
    //
    loadData: async function () {
      // CHECK
      if (!this.siteId) return;
      if (!this.eventId) return;

      // GET - event records
      const records = await ims.helper.loadSiteEventRecords(
        this.siteId,
        this.eventId
      );

      // UPDATE - video url
      records?.forEach((v) => {
        if (!v.completed) return;
        if (!v.has_download_permission) return;
        if (v.format !== "mp4") return;
        this.url = v.url;
        return;
      });
      if (!this.url) return;

      // CASE 1 - in privacy mode
      if (this.event?.is_privacy > 0) return;

      // CASE 2 - NOT in privacy mode
      // LOAD - mp4 video - beforehand for better UX
      this.src = await ims.helper.loadURLObjectURL(this.url);
    },
  },
};
</script>
