<template lang="pug">
//- loadEvents
</template>

<style>
</style>

<script>
// import loadEvents from "./loadEvents.vue";

export default {
  components: {
    // loadEvents,
  },
};
</script>
