import ims from '../../../imports';

export default {
  createUserMeMetadata: function ({ metadata }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'post',
      url: `/users/me/metadata`,
      data: ims.tool.removeEmptyKey({
        metadata,
      }),
    });
  },
  updateUserMeMetadata: function ({ metadata }) {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'put',
      url: `/users/me/metadata`,
      data: ims.tool.removeEmptyKey({
        metadata,
      }),
    });
  },
  getUserMeMetadata: function () {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'get',
      url: `/users/me/metadata`,
    });
  },
  deleteUserMeMetadata: function () {
    return ims.axios({
      ...ims.getDefaultAxiosOptions(),
      method: 'delete',
      url: `/users/me/metadata`,
    });
  },
};
