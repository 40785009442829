import ims from '../../../../../../../imports';

// STATE - default
let initialState = {
  siteId: '',
  dealerSiteEventsAnnotation: null,
  statusGetDealerSiteEventsAnnotation: null,
};

// STATE - INIT
const state = JSON.parse(JSON.stringify(initialState));

// GETTERS
const getters = {
  dealerSiteEventsAnnotation: function (state) {
    return state.dealerSiteEventsAnnotation ?? {};
  },
  dealerSiteEventAnnotation: function (state, getters) {
    return function (eventId) {
      return getters.dealerSiteEventsAnnotation[eventId];
    };
  },
  statusGetDealerSiteEventsAnnotation: function (state) {
    return state.statusGetDealerSiteEventsAnnotation ?? {};
  },
  statusGetDealerSiteEventAnnotation: function (state, getters) {
    return function (eventId) {
      return getters.statusGetDealerSiteEventsAnnotation[eventId];
    };
  },
};

// ACTIONS
const actions = {
  getDealerSiteEventAnnotation: async function (
    { commit, getters },
    { dealerId, siteId, eventId }
  ) {
    if (!ims.auth0_auth.isAuthenticated) return;
    if (getters.statusGetDealerSiteEventAnnotation(eventId) === 'requested')
      return;
    commit('GET_DEALER_SITE_EVENT_ANNOTATION_REQUEST', { siteId, eventId });
    console.debug('GET_DEALER_SITE_EVENT_ANNOTATION_REQUEST: ', eventId);
    try {
      const res = await ims.models.getDealerSiteEventAnnotation({
        dealerId,
        siteId,
        eventId,
      });
      commit('GET_DEALER_SITE_EVENT_ANNOTATION_SUCCESS', {
        data: res.data[0],
        eventId,
      });
      console.debug('GET_DEALER_SITE_EVENT_ANNOTATION_SUCCESS: ', eventId);
      return res.data[0];
    } catch (err) {
      commit('GET_DEALER_SITE_EVENT_ANNOTATION_FAILURE', { eventId });
      console.debug('GET_DEALER_SITE_EVENT_ANNOTATION_FAILURE: ', eventId, err);
      throw err;
    }
  },
};

// MUTATIONS
const mutations = {
  RESET_STATE: function (state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  GET_DEALER_SITE_EVENT_ANNOTATION_REQUEST: function (
    state,
    { siteId, eventId }
  ) {
    if (state.siteId !== siteId) {
      state.statusGetDealerSiteEventsAnnotation = {};
      state.dealerSiteEventsAnnotation = {};
      state.siteId = siteId;
    }
    state.statusGetDealerSiteEventsAnnotation[eventId] = 'requested';
  },
  GET_DEALER_SITE_EVENT_ANNOTATION_SUCCESS: function (
    state,
    { data, eventId }
  ) {
    state.dealerSiteEventsAnnotation[eventId] = {
      ...data,
      area_filters: ims.tool.parseJSON(data.area_filters),
      path_filters: ims.tool.parseJSON(data.path_filters),
    };
    state.statusGetDealerSiteEventsAnnotation[eventId] = 'successful';
  },
  GET_DEALER_SITE_EVENT_ANNOTATION_FAILURE: function (state, { eventId }) {
    state.statusGetDealerSiteEventsAnnotation[eventId] = 'failed';
  },
};

export default {
  dealerSiteEventAnnotation: {
    state,
    getters,
    actions,
    mutations,
  },
};
